import type { Municipality } from '@/types'
import LoadingBox from '@/components/loading-box'
import ErrorBox from '@/components/error-box'
import { useMunicipalities } from '@/hooks/api/use-municipality'

interface MunicipalitiesLoaderProps {
    children: (data: Array<Municipality> ) => React.ReactNode
}

const MunicipalitiesLoader: React.FC<MunicipalitiesLoaderProps> = ({
    children,
}) => {
    const { status, data, error } = useMunicipalities(true)

    if (status === 'pending') {
        return <LoadingBox />
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={'Nepovedlo se načíst obce'}
        />
    }


    if (data.length > 0) {
        return <>{children(data)}</>
    }

    return null
}

export default MunicipalitiesLoader
