import { useForm, type SubmitHandler } from "react-hook-form"

import useFormLocalStorage from "@/hooks/use-form-local-storage"
import { APP_VERSION } from "@/constants/general"
import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import RHFTextfield from "@/components/form-fields/rhf-textfield"
import type { Contact, ContactFormInput } from "@/types"
import { useCreateContact, useUpdateContact } from "@/hooks/api/use-contact"
import RHFTextarea from "@/components/form-fields/rhf-textarea"

const ContactForm = ({
    contactDivisionId,
    contact,
    closeCallback,
} : {
    contactDivisionId?: number,
    contact?: Contact,
    closeCallback?: () => void,
}) => {

    const formDataId = `contactForm-${contact?.id}-v${APP_VERSION}`

    const initialValues = {
        name: contact?.name  ? contact.name : '',
        email: contact?.email ? contact.email : '',
        phone_number: contact?.phone_number ? contact.phone_number : '',
        description: contact?.description ? contact.description : '',
    }
    
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const [isLoadedFromLocalStorage, handleFormReset] = useFormLocalStorage({
        control,
        formDataId,
        reset,
        initialValues
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
    } = useFormStates(handleFormReset)

    const mutationCreate = useCreateContact(
        contactDivisionId,
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback: closeCallback,
        }
    )

    const mutationUpdate = useUpdateContact(
        contact?.id,
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback: closeCallback,
        },
    )

    const onSubmit: SubmitHandler<ContactFormInput> = data => {
        setRequestInProgress(true)
        if (contact?.id) {
            mutationUpdate.mutate(data)
        } else {
            mutationCreate.mutate(data)
        }
    }

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            heading={contact ? 'Upravit kontakt' : 'Vytvořit nový kontakt'}
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={isLoadedFromLocalStorage}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
        >
            <RHFTextfield
                name="name"
                control={control}
                label="Celé jméno/název"
                required
            />
            <RHFTextarea
                name="description"
                control={control}
                label="Popis (např. agenda...)"
                size="small"
            />
            <RHFTextfield
                name="email"
                control={control}
                label="Email"
                type="email"
            />
            <RHFTextfield
                name="phone_number"
                control={control}
                label="Telefon"
                type="tel"
            />
        </Form>
    )
}

export default ContactForm
