import Box from '@mui/material/Box'

import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"
import CommunicationThreadAccordion from '@/components/communication/thread-accordion'
import { useCommunications } from '@/hooks/api/use-communication'
import { getUserId } from '@/helpers'

export function MessageList() {
    
    const userId = getUserId()
    
    const { status, data, error } = useCommunications({initializedById: userId})

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={'Nepovedlo se načíst seznam komunikace'}
        />
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
            {data.map((communicationThread, index) => (
                <CommunicationThreadAccordion
                    key={index}
                    communicationThread={communicationThread}
                />
            ))}
        </Box>
    )
}
