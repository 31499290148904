import Box from '@mui/material/Box'
import 'leaflet/dist/leaflet.css'
import { AttributionControl, MapContainer, TileLayer } from 'react-leaflet'
import CustomHomeMarker from './custom-home-marker'

export default function CollectionPointDetailMap({
    latitude,
    longitude,
}:{
    latitude: number,
    longitude: number,
}) {

    return (
        <Box sx={{
            width: '100%',
            height: '200px',
            '& .leaflet-container': {
                width: '100%',
                height: '200px',
            },
        }}>
            <MapContainer center={[latitude, longitude]} zoom={15} scrollWheelZoom={true} attributionControl={false}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <AttributionControl position="bottomright" prefix={false} />
                <CustomHomeMarker position={[latitude, longitude]} />
            </MapContainer>
        </Box>
    )
}
