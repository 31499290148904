import AppBar from '@mui/material/AppBar'
import type { AppBarProps } from '@mui/material/AppBar'
import { styled } from '@mui/material/styles'

const StyledAppBar = styled(AppBar)<AppBarProps>(({ theme }) => ({
    backgroundColor: theme.palette.customLightGreen.main,
    color: theme.palette.primary.main,
    boxShadow: 'none',
}))

export default StyledAppBar
