import { useState } from "react"

const useFormStates = (
    handleFormReset: () => void,
) => {

    const [warnings, setWarnings] = useState<Array<string> | null>(null)
    const [error, setError] = useState<string | null>(null)
    const [isChangedOnServer, setIsChangedOnServer] = useState<boolean>(false)
    const [requestInProgress, setRequestInProgress] = useState<boolean>(false)

    const resetWithVersion = () => {
        handleFormReset()
        setIsChangedOnServer(false)
        setWarnings(null)
    }

    return {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
        isChangedOnServer,
        setIsChangedOnServer,
        warnings,
        setWarnings,
        error,
        setError,
    }

}

export default useFormStates
