import { useAtom } from 'jotai'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Helmet } from 'react-helmet-async'

import CustomPaper from '@/components/custom-paper'
import DisposalTrendWrapper from '@/components/disposal-trend/wrapper'
import { selectedCollectionPointIdAtom } from '@/state'

function DisposalTrendView() {

    const [collectionPointId] = useAtom(selectedCollectionPointIdAtom)

    const title = 'Trend výsypu svozového místa'

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <CustomPaper>
                <Typography variant="h2" component="h1" gutterBottom>
                    {title}
                </Typography>
                {collectionPointId ?
                    <DisposalTrendWrapper />
                    :
                    <Box>
                        Vyberte prosím konkrétní svozové místo výše.
                    </Box>
                }
            </CustomPaper>
        </>
    )
}

export default DisposalTrendView
