import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import { Link } from 'react-router-dom'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import {
    CustomTabs,
    CustomTab,
    CustomTabPanelSmall,
} from '@/components/custom-tabs'
import useTabs from '@/hooks/use-tabs'
import CollectedWasteStatList from './list'
import { END_DATE_KEY, ROUTE_SEGMENTS, START_DATE_KEY, YEARS_FROM_START_TO_THIS } from '@/constants/general'

function CollectedWasteStatsTabs() {
    
    const { tab, handleTabChange, a11yProps } = useTabs()

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <CustomTabs
                    value={tab}
                    onChange={handleTabChange}
                    aria-label="waste fee tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {YEARS_FROM_START_TO_THIS.map((year, index) => (
                        <CustomTab key={year} label={year} {...a11yProps(index)} />
                    ))}
                </CustomTabs>
            </Box>
            {YEARS_FROM_START_TO_THIS.map((year, index) => (
                <CustomTabPanelSmall key={year} value={tab} index={index}>
                    <Box>
                        <Alert
                            sx={(theme) => ({
                                flexGrow: 1,
                                margin: theme.spacing(2, 0),
                                '& .MuiAlert-message': {
                                    flexGrow: 1,
                                },
                            })}
                            icon={<InfoOutlinedIcon/>}
                        >
                            <Box sx={{
                                display: 'flex',
                                flexGrow: 1,
                                flexDirection: {
                                    xs: 'column',
                                    lg: 'row',
                                },
                                gap: 2,
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}>
                                <Box>Podrobnější statistiky a grafy pro rok {year} naleznete v sekci 'Trend separace v obci'.</Box>
                                <Box sx={{
                                    pr: {
                                        xs: 4,
                                        lg: 0
                                    }
                                }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        component={Link}
                                        to={`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipality}/${ROUTE_SEGMENTS.municipalitySortingTrend}?${START_DATE_KEY}=${year}-01-01&${END_DATE_KEY}=${year}-12-31`}
                                    >
                                        Přejít do sekce
                                    </Button>
                                </Box>
                            </Box>
                        </Alert>
                    </Box>
                    <CollectedWasteStatList year={year} />
                </CustomTabPanelSmall>
            ))}
        </>
    )
}

export default CollectedWasteStatsTabs
