import {
    useState,
    type SyntheticEvent,
} from 'react'
import {
    useNavigate,
    useLocation,
} from 'react-router-dom'

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        value: index,
    }
}

const useTabs = (paramKey = 'tab') => {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const tabFromURL = params.get(paramKey)

    const [tab, setTab] = useState<number>(tabFromURL ? Number.parseInt(tabFromURL) : 0)

    const navigate = useNavigate()

    const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
        setTab(newValue)
        const searchParams = new URLSearchParams(location.search)
        searchParams.set(paramKey, newValue.toString())
        navigate('?' + searchParams.toString(), { replace: true })
    }

    return {
        tab,
        handleTabChange,
        a11yProps,
    }
}

export default useTabs
