import { useState } from "react"
import dayjs, { type Dayjs } from "dayjs"

interface Options {
    callback?: () => void
    initialStartDate?: Dayjs
    initialEndDate?: Dayjs
}

const useDateInterval = (options?: Options) => {

    const {
        callback,
        initialStartDate,
        initialEndDate,
    } = options || {}
    
    const currentDate = dayjs()
    const lastCalendarYearStartDate = currentDate.subtract(1, 'year').startOf('year')
    const lastCalendarYearEndDate = currentDate.subtract(1, 'year').endOf('year')

    const last12monthsStartDate = currentDate.subtract(1, 'year').startOf('month')
    const last12monthsEndDate = currentDate.subtract(1, 'month').endOf('month')

    const [startDate, setStartDate] = useState<Dayjs>(initialStartDate ?? lastCalendarYearStartDate)
    const [endDate, setEndDate] = useState<Dayjs>(initialEndDate ?? lastCalendarYearEndDate)
    const [startDateValue, setStartDateValue] = useState<Dayjs>(initialStartDate ?? lastCalendarYearStartDate)
    const [endDateValue, setEndDateValue] = useState<Dayjs>(initialEndDate ?? lastCalendarYearEndDate)

    const handleSetDates = () => {
        setStartDate(startDateValue)
        setEndDate(endDateValue)
        if (callback) callback()
    }

    const handleSetLast12Months = () => {
        setStartDateValue(last12monthsStartDate)
        setStartDate(last12monthsStartDate)
        setEndDateValue(last12monthsEndDate)
        setEndDate(last12monthsEndDate)
        if (callback) callback()
    }

    const handleSetLastCalendarYear = () => {
        setStartDateValue(lastCalendarYearStartDate)
        setStartDate(lastCalendarYearStartDate)
        setEndDateValue(lastCalendarYearEndDate)
        setEndDate(lastCalendarYearEndDate)
        if (callback) callback()
    }

    return {
        handleSetDates,
        handleSetLast12Months,
        handleSetLastCalendarYear,
        startDate,
        endDate,
        startDateValue,
        setStartDateValue,
        endDateValue,
        setEndDateValue,
    }

}

export default useDateInterval
