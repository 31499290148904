import { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useAtom } from 'jotai'

import { Endpoints, UserRole, type WasteFee } from '@/types'
import RichText from '@/components/rich-text'
import WasteFeeActions from '@/components/waste-fee/actions'
import DocumentsList from '@/components/documents-list'
import { selectedMunicipalityIdAtom } from '@/state'
import WasteFeeForm from '@/components/forms/waste-fee'
import PermissionsGate from '@/components/permissions-gate'

function WasteFeeYearDetails({
    wasteFee,
}: {
    wasteFee: WasteFee,
}) {

    const [edit, setEdit] = useState<boolean>(false)

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    return (
        <Box position="relative">
            <Typography variant="h5" component="h3" mb={1}>
                Detaily poplatků - {wasteFee.year}
            </Typography>
            <Box pt={2}>
                {edit ?
                    <WasteFeeForm
                        wasteFee={wasteFee}
                        closeCallback={() => setEdit(false)}
                    />
                    :
                    <>
                        <RichText content={wasteFee.description} />
                        <PermissionsGate
                            userRoles={[
                                UserRole.admin,
                                UserRole.municipalityOfficial,
                            ]}
                        >
                            <Box sx={{
                                position: 'absolute',
                                top: -8,
                                right: -8,
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                borderRadius: '1rem',
                            }}>
                                <WasteFeeActions
                                    wasteFee={wasteFee}
                                    setEdit={setEdit}
                                />
                            </Box>
                        </PermissionsGate>
                    </>
                }
                <Box pt={4} />
                <DocumentsList
                    documents={wasteFee.documents}
                    endpoint={Endpoints.wasteFee}
                    entityId={wasteFee.id}
                    invalidateQueryKey={['wasteFees', municipalityId]}
                />
            </Box>
        </Box>
    )
}

export default WasteFeeYearDetails
