import { useState } from 'react'
import { useAtom } from 'jotai'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import { useParams } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import { NumericFormat } from 'react-number-format'

import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"
import { CollectedWasteRecordType, type MonthYear } from '@/types'
import { useCollectedWasteStats } from '@/hooks/api/use-collected-waste-stat'
import { selectedMunicipalityIdAtom } from '@/state'
import CollectedWasteStatForm from '@/components/forms/collected-waste-stat'
import { useMunicipalityStats } from '@/hooks/api/use-municipality'
import { calculateMonthlyAndTotalMunicipalityStats } from '@/helpers'

function SingleCollectedWasteStat() {

    const { monthYear } = useParams()

    const [editCollectionYard, setEditCollectionYard] = useState<boolean>(false)
    const [editOther, setEditOther] = useState<boolean>(false)

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)
    
    const { status, data, error } = useCollectedWasteStats(
        municipalityId,
        monthYear as MonthYear,
        monthYear as MonthYear,
    )

    const {
        status: collectionStatus,
        data: collectionData,
        error: collectionError,
    } = useMunicipalityStats(
        municipalityId,
        monthYear as MonthYear,
        monthYear as MonthYear,
    )

    if (status === 'pending' || collectionStatus === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error || collectionError) {
        return <ErrorBox
            error={error || collectionError}
            message={`Nepovedlo se načíst údaje o produkci odpadu v období ${monthYear}`}
        />
    }

    const { totalData: totalCollectionData } = calculateMonthlyAndTotalMunicipalityStats(collectionData)

    const collectionStat = totalCollectionData
    const collectionYardStat = data.find(entry => entry.record_type === CollectedWasteRecordType.collectionYard)
    const otherStat = data.find(entry => entry.record_type === CollectedWasteRecordType.other)

    const rows = [
        {
            name: 'SKO',
            weightCollection: collectionStat.mixed_weight,
            weightCollectionYard: collectionYardStat?.mixed_weight,
            weightOther: otherStat?.mixed_weight,
        },
        {
            name: 'Bioodpad',
            weightCollection: collectionStat.bio_weight,
            weightCollectionYard: collectionYardStat?.bio_weight,
            weightOther: otherStat?.bio_weight,
        },
        {
            name: 'Plast',
            weightCollection: collectionStat.plastic_weight,
            weightCollectionYard: collectionYardStat?.plastic_weight,
            weightOther: otherStat?.plastic_weight,
        },
        {
            name: 'Papír',
            weightCollection: collectionStat.paper_weight,
            weightCollectionYard: collectionYardStat?.paper_weight,
            weightOther: otherStat?.paper_weight,
        },
        {
            name: 'Směsné sklo',
            weightCollection: collectionStat.glass_weight,
            weightCollectionYard: collectionYardStat?.glass_mixed_weight,
            weightOther: otherStat?.glass_mixed_weight,
        },
        {
            name: 'Čiré sklo',
            weightCollection: null,
            weightCollectionYard: collectionYardStat?.glass_clear_weight,
            weightOther: otherStat?.glass_clear_weight,
        },
        {
            name: 'Nápojové kartony (samostatné)',
            weightCollection: null,
            weightCollectionYard: collectionYardStat?.liquid_paperboard_single_weight,
            weightOther: otherStat?.liquid_paperboard_single_weight,
        },
        {
            name: 'Nápojové kartony (směs)',
            weightCollection: null,
            weightCollectionYard: collectionYardStat?.liquid_paperboard_combined_weight,
            weightOther: otherStat?.liquid_paperboard_combined_weight,
        },
        {
            name: 'Kov (samostatné)',
            weightCollection: null,
            weightCollectionYard: collectionYardStat?.metal_single_weight,
            weightOther: otherStat?.metal_single_weight,
        },
        {
            name: 'Kov (směs)',
            weightCollection: null,
            weightCollectionYard: collectionYardStat?.metal_combined_weight,
            weightOther: otherStat?.metal_combined_weight,
        },
        {
            name: 'Dřevo',
            weightCollection: null,
            weightCollectionYard: collectionYardStat?.wood_weight,
            weightOther: otherStat?.wood_weight,
        },
        {
            name: 'Stavební suť',
            weightCollection: null,
            weightCollectionYard: collectionYardStat?.construction_waste_weight,
            weightOther: otherStat?.construction_waste_weight,
        },
        {
            name: 'Nebezpečný odpad',
            weightCollection: null,
            weightCollectionYard: collectionYardStat?.hazardous_waste_weight,
            weightOther: otherStat?.hazardous_waste_weight,
        },
    ]

    return (
        <>
            {editCollectionYard &&
                <Container maxWidth="md">
                    <CollectedWasteStatForm
                        collectedWasteStat={collectionYardStat}
                        closeCallback={() => setEditCollectionYard(false)}
                        yearMonth={monthYear as MonthYear}
                        recordType={CollectedWasteRecordType.collectionYard}
                    />
                </Container>
            }
            {editOther &&
                <Container maxWidth="md">
                    <CollectedWasteStatForm
                        collectedWasteStat={otherStat}
                        closeCallback={() => setEditOther(false)}
                        yearMonth={monthYear as MonthYear}
                        recordType={CollectedWasteRecordType.other}
                    />
                </Container>
            }
            {(!editCollectionYard && !editOther) &&
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowX: 'auto'
                }}>
                    <Table
                        aria-label="Měsíční statistika produkce odpadu"
                        sx={{
                            mt: 1,
                            minWidth: 600,
                        }}
                        size='small'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Typ odpadu</TableCell>
                                <TableCell>Svozy</TableCell>
                                <TableCell>Sběrné dvory</TableCell>
                                <TableCell>Ostatní</TableCell>
                                <TableCell>Celkem</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map(month => (
                                <TableRow
                                    key={month.name}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        '& td, & th': {
                                            paddingY: 1.5,
                                        }
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {month.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {month.weightCollection != null ? 
                                            <Box>
                                                <NumericFormat
                                                    displayType="text"
                                                    value={month.weightCollection}
                                                    thousandSeparator={'\xa0'}
                                                />
                                                &nbsp;kg
                                            </Box> :
                                            <Box sx={{
                                                display: 'flex',
                                                gap: 1,
                                                alignItems: 'center',
                                            }}>
                                                -
                                            </Box>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {month.weightCollectionYard != null ? 
                                            <Box>
                                                <NumericFormat
                                                    displayType="text"
                                                    value={month.weightCollectionYard}
                                                    thousandSeparator={'\xa0'}
                                                />
                                                &nbsp;kg
                                            </Box> :
                                            <Box sx={{
                                                display: 'flex',
                                                gap: 1,
                                                alignItems: 'center',
                                            }}>
                                                <ErrorOutlineRoundedIcon />
                                                Nevyplněno
                                            </Box>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {month.weightOther != null ? 
                                            <Box>
                                                <NumericFormat
                                                    displayType="text"
                                                    value={month.weightOther}
                                                    thousandSeparator={'\xa0'}
                                                />
                                                &nbsp;kg
                                            </Box> :
                                            <Box sx={{
                                                display: 'flex',
                                                gap: 1,
                                                alignItems: 'center',
                                            }}>
                                                <ErrorOutlineRoundedIcon />
                                                Nevyplněno
                                            </Box>
                                        }
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <NumericFormat
                                            displayType="text"
                                            value={(month.weightCollection ?? 0)+(month.weightCollectionYard ?? 0)+(month.weightOther ?? 0)}
                                            thousandSeparator={'\xa0'}
                                        />
                                        &nbsp;kg
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    '& td, & th': {
                                        paddingY: 1.5,
                                    }
                                }}
                            >
                                <TableCell />
                                <TableCell />
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="terciary"
                                        size="small"
                                        onClick={() => setEditCollectionYard(true)}
                                    >
                                        Upravit sběr. dvory
                                    </Button>
                                </TableCell>
                                <TableCell>
                                <Button
                                    variant="contained"
                                    color="terciary"
                                    size="small"
                                    onClick={() => setEditOther(true)}
                                >
                                    Upravit ostatní
                                </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            }
        </>
    )
}

export default SingleCollectedWasteStat
