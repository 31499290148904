import type { FC, ReactNode } from 'react'
import L from 'leaflet'
import { Marker } from 'react-leaflet'
import { type IllegalDumpPublicList, ReportStates } from '@/types'

interface CustomDumpMarkerProps {
    marker: IllegalDumpPublicList
    selectedId: number | null,
    setSelectedId: (arg1: number | null) => void,
    children?: ReactNode
}

const REPORT_STATE_MARKER_MAP = {
    [ReportStates.sent]: '/pin-reported.png',
    [ReportStates.inProgress]: '/pin-waiting.png',
    [ReportStates.resolved]: '/pin-done.png',
}

const CustomDumpMarker: FC<CustomDumpMarkerProps> = ({ marker, selectedId, setSelectedId, children }) => {

    const customIcon = L.icon({
        iconUrl: REPORT_STATE_MARKER_MAP[marker.state] ?? '/pin-reported.png',
        iconSize: selectedId === marker.id ? [49, 56] : [42, 48],
        iconAnchor: selectedId === marker.id ? [25, 40] : [22, 36],
    })

    return (
        <Marker
            position={[Number(marker.latitude), Number(marker.longitude)]}
            icon={customIcon}
            eventHandlers={{
                click: () => {
                    setSelectedId(marker.id)
                },
            }}
        >
            {children}
        </Marker>
    )
}

export default CustomDumpMarker
