import type { ReactNode } from 'react'
import Box from '@mui/material/Box'

export function DetailTable({
    children,
}: {
    children: ReactNode
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '.5rem',
            }}
        >
            {children}
        </Box>
    )
}

export function DetailTableHeader({
    children,
    sx,
}: {
    children: ReactNode
    sx?: React.ComponentProps<typeof Box>['sx']
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                padding: '.5rem .5rem 0rem 1.5rem',
                borderRadius: '10px',
                gap: '1.25rem',
                ...sx,
            }}
        >
            {children}
        </Box>
    )
}

export function DetailTableRow({
    children,
    sx,
}: {
    children: ReactNode
    sx?: React.ComponentProps<typeof Box>['sx']
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                padding: '.5rem .5rem .5rem 1.5rem',
                border: '1px solid #DEDEDE',
                borderRadius: '10px',
                //gap: '1.25rem',
                ...sx,
            }}
        >
            {children}
        </Box>
    )
}

export function DetailTableCell({
    children,
    sx,
}: {
    children: ReactNode
    sx?: React.ComponentProps<typeof Box>['sx']
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                ...sx,
            }}
        >
            {children}
        </Box>
    )
}
