import { cloneElement } from 'react'

import ErrorBox from '@/components/error-box'
import { ALLOW_ALL } from '@/constants/general'
import { UserRole } from '@/types'
import { getUserId, getUserRole } from '@/helpers'

const hasPermission = ({
    role,
    scope,
    isOwner,
    isOnUserList,
}) => {

    // if (role === UserRole.admin) return true

    if (isOwner) return true

    if (isOnUserList) return true

    if (scope === ALLOW_ALL) return true

    return false
}

export default function PermissionsGate({
    children,
    scope = null,
    errorProps = null,
    errorPage = false,
    userRoles = [],
    ownerIds = [],
}) {

    const userId = getUserId()
    
    const isOwner = (ownerIds.includes(userId))

    const role = getUserRole()

    let isOnUserList = false

    if (userRoles.length !== 0) {
        isOnUserList = userRoles.includes(role) || userRoles.includes(ALLOW_ALL)
    }

    const permissionGranted = hasPermission({
        role,
        scope,
        isOwner,
        isOnUserList,
    })

    if (!permissionGranted && errorProps)
        return cloneElement(children, { ...errorProps })

    if (!permissionGranted && errorPage) return (
        <ErrorBox
            message="K zobrazení této stránky nemáte dostatečná oprávnění."
            error="Nemáte dostatečná oprávnění."
        />
    )

    if (!permissionGranted) return <></>

    return <>{children}</>
}
