import type { FC, ReactNode } from 'react'
import L from 'leaflet'
import { Marker } from 'react-leaflet'
import { WasteTypeCodes, type MunicipalityBin } from '@/types'

interface CustomMarkerProps {
    marker: MunicipalityBin
    children: ReactNode
}

const WASTE_TYPE_CODE_MARKER_MAP = {
    [WasteTypeCodes.bio]: '/pin-bio.png',
    [WasteTypeCodes.paper]: '/pin-paper.png',
    [WasteTypeCodes.plastic]: '/pin-plastic.png',
    [WasteTypeCodes.mixed]: '/pin-mixed.png',
    [WasteTypeCodes.glass]: '/pin-glass.png',
    [WasteTypeCodes.clearGlass]: '/pin-glass.png',
}

const CustomMarker: FC<CustomMarkerProps> = ({ marker, children }) => {

    const customIcon = L.icon({
        iconUrl: WASTE_TYPE_CODE_MARKER_MAP[marker.waste_type_code] ?? '/pin-mixed.png',
        iconSize: [42, 48],
        iconAnchor: [22, 36],
    })

    return (
        <Marker position={[Number(marker.latitude), Number(marker.longitude)]} icon={customIcon}>
            {children}
        </Marker>
    )
}

export default CustomMarker
