import { useAtom } from 'jotai'
import dayjs from 'dayjs'
import 'dayjs/locale/cs'

import ErrorBox from '@/components/error-box'
import LoadingBox from '@/components/loading-box'
import { useMunicipalityStats } from '@/hooks/api/use-municipality'
import { selectedMunicipalityIdAtom } from '@/state'
import type { MonthYear } from '@/types'
import MonthlyShareChart from '@/components/municipality-sorting-trend/monthly-share-chart'
import { calculateMonthlyAndTotalMunicipalityStats } from '@/helpers'

export default function MunicipalitySortingTrendHome() {

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    const currentDate = dayjs()
    const startDate = currentDate.subtract(1, 'year')
    const endDate = currentDate.subtract(1, 'month')
    
    const { status, data, error } = useMunicipalityStats(
        municipalityId,
        startDate.format('YYYY-MM') as MonthYear,
        endDate.format('YYYY-MM') as MonthYear,
    )

    const {
        status: comparisonStatus,
        data: comparisonData,
        error: comparisonError,
    } = useMunicipalityStats(
        municipalityId,
        startDate.subtract(1, 'year').format('YYYY-MM') as MonthYear,
        endDate.subtract(1, 'year').format('YYYY-MM') as MonthYear,
    )

    if (status === 'pending' || comparisonStatus === 'pending') {
        return <LoadingBox />
    }

    if (error || comparisonError) {
        return <ErrorBox
            error={error ?? comparisonError ?? 'Neočekávaná chyba'}
            message={`Nepovedlo se načíst statistiky obce s ID ${municipalityId}`}
        />
    }

    const { monthlyData } = calculateMonthlyAndTotalMunicipalityStats(data)
    const { monthlyData: monthlyComparisonData } = calculateMonthlyAndTotalMunicipalityStats(comparisonData)

    return (
        <>
            <MonthlyShareChart data={monthlyData} comparisonData={monthlyComparisonData} noHeading />
        </>
    )
}
