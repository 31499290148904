import { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { UserRole, type CollectionYard } from '@/types'
import RichText from '@/components/rich-text'
import CollectionYardActions from '@/components/collection-yard/actions'
import CollectionYardForm from '@/components/forms/collection-yard'
import PermissionsGate from '@/components/permissions-gate'

function CollectionYardDetails({
    collectionYard,
}: {
    collectionYard: CollectionYard,
}) {

    const [edit, setEdit] = useState<boolean>(false)

    return (
        <Box position="relative">
            <Typography variant="h5" component="h3" mb={1}>
                {collectionYard.name}
            </Typography>
            <Box pt={2}>
                {edit ?
                    <CollectionYardForm
                        collectionYard={collectionYard}
                        closeCallback={() => setEdit(false)}
                    />
                    :
                    <>
                        <RichText content={collectionYard.description} />
                        <PermissionsGate
                            userRoles={[
                                UserRole.admin,
                                UserRole.municipalityOfficial,
                            ]}
                        >
                            <Box sx={{
                                position: 'absolute',
                                top: -8,
                                right: -8,
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                borderRadius: '1rem',
                            }}>
                                <CollectionYardActions
                                    collectionYard={collectionYard}
                                    setEdit={setEdit}
                                />
                            </Box>
                        </PermissionsGate>
                    </>
                }
            </Box>
        </Box>
    )
}

export default CollectionYardDetails
