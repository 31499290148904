import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Helmet } from 'react-helmet-async'

import CustomPaper from '@/components/custom-paper'
import MotivationModelTabs from '@/components/motivation-model/tabs'

function MotivationModelView() {

    const title = 'Seznam motivačních modelů'

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <CustomPaper noPadding>
                <Box p={2.5}>
                    <Typography variant="h2" component="h1">
                        {title}
                    </Typography>
                </Box>
                <MotivationModelTabs />
            </CustomPaper>
        </>
    )
}

export default MotivationModelView
