import { Controller, useForm, type SubmitHandler } from "react-hook-form"
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'

import useFormLocalStorage from "@/hooks/use-form-local-storage"
import { APP_VERSION, ROUTE_SEGMENTS, YEARS_FROM_START_TO_NEXT } from "@/constants/general"
import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import RHFTextfield from "../form-fields/rhf-textfield"
import type { MotivationModelListItem, MotivationModelNameFormInput } from "@/types"
import { useCreateMotivationModel, useUpdateMotivationModelName } from "@/hooks/api/use-motivation-model"
import { useNavigate } from "react-router-dom"

const MotivationModelNameForm = ({
    motivationModel,
    year: yearFromProps,
    closeCallback,
} : {
    motivationModel?: MotivationModelListItem,
    year?: string,
    closeCallback?: () => void,
}) => {

    const navigate = useNavigate()
    
    const formDataId = `motivationModelNameForm-${motivationModel?.id}-v${APP_VERSION}`

    const initialValues: MotivationModelNameFormInput = {
        name: motivationModel?.name != null ? motivationModel.name : '',
        year: motivationModel?.year != null ? motivationModel.year.toString() : yearFromProps ?? YEARS_FROM_START_TO_NEXT[0].toString(),
    }
    
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const [isLoadedFromLocalStorage, handleFormReset] = useFormLocalStorage({
        control,
        formDataId,
        reset,
        initialValues
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
    } = useFormStates(handleFormReset)

    const mutationCreate = useCreateMotivationModel({
        setRequestInProgress,
        formDataId,
        onSuccessCallback(motivationModel) {
            navigate(`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipalityManagement}/${ROUTE_SEGMENTS.motivationModel}/${motivationModel.id}/${ROUTE_SEGMENTS.step}/0`)
        },
    })

    const mutationUpdate = useUpdateMotivationModelName(
        motivationModel?.id,
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback: closeCallback,
        },
    )

    const onSubmit: SubmitHandler<MotivationModelNameFormInput> = data => {
        setRequestInProgress(true)
        if (motivationModel?.id) {
            mutationUpdate.mutate(data)
        } else {
            mutationCreate.mutate(data)
        }
    }

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={isLoadedFromLocalStorage}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
            buttonText={motivationModel ? "Upravit" : "Vytvořit model"}
        >
            <Typography variant="h5" component="h3" gutterBottom>
                {motivationModel ? "Upravte název a rok modelu" : "Zadejte pracovní název modelu a vyberte rok"}
            </Typography>
            <RHFTextfield
                name="name"
                control={control}
                label="Název"
                required
            />
            <FormControl fullWidth>
                <InputLabel id="year-select-label">Rok</InputLabel>
                <Controller
                    name="year"
                    control={control}
                    render={({ field }) => (
                        <Select {...field} label="Rok">
                            {YEARS_FROM_START_TO_NEXT.map(year => (
                                <MenuItem key={year} value={year}>{year}</MenuItem>
                            ))}
                        </Select>
                    )}
                />
            </FormControl>
        </Form>
    )
}

export default MotivationModelNameForm
