import React from 'react'
import { Controller, type Control, type Path, type FieldValues } from "react-hook-form"
import TextField, { type BaseTextFieldProps } from "@mui/material/TextField"

interface RHFAutocompleteProps<
    TField extends FieldValues,
> {
    control: Control<TField>,
    name: Path<TField>,
    label?: string,
    size?: BaseTextFieldProps["size"],
    required?: BaseTextFieldProps["required"],
    minRows?: number,
    maxRows?: number,
}

const RHFTextarea = <
    TField extends FieldValues,
>(
    props: RHFAutocompleteProps<TField>
) => {

    const {
        control,
        name,
        label,
        size,
        required,
        minRows = 4,
        maxRows = 10,
    } = props

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => {
                return (
                    <TextField
                        id="rhf-textarea"
                        label={label}
                        size={size}
                        required={required}
                        autoComplete="off"
                        multiline
                        minRows={minRows}
                        maxRows={maxRows}
                        {...field}
                    />
                )
            }}
        />
    )
}

export default RHFTextarea
