import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

interface CardRadioGroupProps {
    options: { label: string; value: string; description?: string }[]
    selectedValue: string
    onChange: (value: string) => void
}

const StyledCard = styled(Card)<{ selected: boolean }>(({ theme, selected }) => ({
    border: selected
        ? `1px solid ${theme.palette.primary.main}`
        : `1px solid ${theme.palette.divider}`,
    backgroundColor: selected
        ? theme.palette.customLightGreen.light
        : theme.palette.common.white,
    cursor: 'pointer',
    transition: 'border-color 0.3s, box-shadow 0.3s',
    boxShadow: 'none',
    borderRadius: 10,
    '&:hover': {
        backgroundColor: selected
        ? theme.palette.customLightGreen.light
        : theme.palette.grey[100],
    },
    maxWidth: 200,
}))

const CardRadioGroup: React.FC<CardRadioGroupProps> = ({ options, selectedValue, onChange }) => {
    return (
        <RadioGroup
            value={selectedValue}
            sx={{
                flexDirection: 'row',
                gap: 2,
                justifyContent: 'center',
            }}
        >
            {options.map(option => (
                <StyledCard
                    key={option.value}
                    selected={option.value === selectedValue}
                    onClick={() => {
                        if (selectedValue !== option.value) {
                            onChange(option.value);
                        }
                    }}
                >
                    <CardContent>
                        <FormControlLabel
                            value={option.value}
                            control={<Radio />}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                margin: 0,
                            }}
                            label={
                                <Box textAlign="center">
                                    <Typography variant="h6" component="div">
                                        {option.label}
                                    </Typography>
                                    {option.description && (
                                        <Typography variant="body2" color="textSecondary">
                                            {option.description}
                                        </Typography>
                                    )}
                                </Box>
                            }
                        />
                    </CardContent>
                </StyledCard>
            ))}
        </RadioGroup>
    )
}

export default CardRadioGroup
