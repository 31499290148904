import type { ReactNode } from 'react'
import { type ElementType, type KeyboardEvent, forwardRef } from 'react'
import TextField, { type BaseTextFieldProps } from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import { NumericFormat, type NumericFormatProps } from 'react-number-format'
import type { InputBaseComponentProps } from '@mui/material'

interface NumberTextfieldProps {
    name: string
    label?: string
    size?: BaseTextFieldProps['size']
    required?: BaseTextFieldProps['required']
    decimalScale?: number
    thousandSeparator?: boolean
    endAdornment?: ReactNode
    alignRight?: boolean
    onChange: (event: { target: { name: string; value: string } }) => void
    value: string
}

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
    decimalScale?: number
    thousandSeparator?: boolean
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange, decimalScale = 0, thousandSeparator, ...other } = props

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values, sourceInfo) => {
                    if (sourceInfo.source === 'event') {
                        onChange({
                            target: {
                                name: props.name,
                                value: values.value,
                            },
                        })
                    }
                }}
                thousandSeparator={thousandSeparator ? ' ' : false}
                valueIsNumericString
                allowedDecimalSeparators={['.', ',']}
                decimalScale={decimalScale}
            />
        )
    }
)

const NumberTextfield = (props: NumberTextfieldProps) => {
    const {
        label,
        size,
        required,
        thousandSeparator,
        decimalScale,
        endAdornment,
        alignRight,
        onChange,
        value,
    } = props

    return (
        <TextField
            id="rhf-textfield"
            label={label}
            size={size}
            required={required}
            autoComplete="off"
            InputProps={{
                inputComponent:
                    NumericFormatCustom as unknown as ElementType<InputBaseComponentProps>,
                endAdornment: endAdornment ? (
                    <InputAdornment position="end">{endAdornment}</InputAdornment>
                ) : undefined,
            }}
            inputProps={{
                thousandSeparator,
                decimalScale,
            }}
            sx={alignRight ? { input: { textAlign: 'right' } } : null}
            onChange={onChange}
            value={value}
        />
    )
}

export default NumberTextfield
