import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import { Categories } from '@/types'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const options = Object.values(Categories)

export default function CategorySelector({
    categories,
    setCategories,
    callback,
    label = 'Kategorie',
}: {
    categories,
    setCategories,
    callback?: () => void,
    label?: string,
}) {

    const handleChange = (event: SelectChangeEvent<typeof categories>) => {
        const {
            target: { value },
        } = event
        setCategories(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
        if (callback) callback()
    }

    return (
        <FormControl sx={{ minWidth: 190, flexGrow: 1 }}>
            <InputLabel id="categories-select-label" size='small'>{label}</InputLabel>
            <Select
                labelId="categories-select-label"
                id="categories-select"
                multiple
                value={categories}
                onChange={handleChange}
                input={<OutlinedInput label={label} />}
                renderValue={selected => selected.join(', ')}
                MenuProps={MenuProps}
                size='small'
            >
                {options.map(option => (
                    <MenuItem key={option} value={option}>
                        <Checkbox checked={categories.includes(option)} />
                        <ListItemText primary={option} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
