import { useAtom } from 'jotai'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/cs'
import CachedRoundedIcon from '@mui/icons-material/CachedRounded'
import Grid from '@mui/material/Unstable_Grid2'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'

import ErrorBox from '@/components/error-box'
import LoadingBox from '@/components/loading-box'
import { selectedCollectionPointIdAtom } from '@/state'
import { WasteTypeIds, type AggregatedWasteData, type CollectionPointWeighting, type ISODateString } from '@/types'
import { WASTE_COLORS } from '@/constants/general'
import TotalPieChart from '@/components/municipality-sorting-trend/total-pie-chart'
import WasteCard from '@/components/municipality-sorting-trend/waste-card'
import { useCollectionPointWeightings } from '@/hooks/api/use-collection-point'
import DisposalTrendCalendar from '@/components/disposal-trend/calendar'
import DisposalTrendMonthlyTrend from '@/components/disposal-trend/monthly-trend'
import useDateInterval from '@/hooks/use-date-interval'

export default function DisposalTrendWrapper() {

    const [collectionPointId] = useAtom(selectedCollectionPointIdAtom)

    const {
        handleSetDates,
        handleSetLast12Months,
        handleSetLastCalendarYear,
        startDate,
        endDate,
        startDateValue,
        setStartDateValue,
        endDateValue,
        setEndDateValue,
    } = useDateInterval()
    
    const { status, data, error } = useCollectionPointWeightings(
        collectionPointId,
        startDate.format('YYYY-MM-DD') as ISODateString,
        endDate.format('YYYY-MM-DD') as ISODateString,
    )

    if (status === 'pending') {
        return <LoadingBox />
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst statistiky výsypu svozového místa ID ${collectionPointId}`}
        />
    }

    const NUMBER_OF_PEOPLE = data.number_of_members ?? 1

    function aggregateData(monthlyData: Array<CollectionPointWeighting>) {
    
        const yearlyData = monthlyData.reduce((acc, entry) => {
            const wasteTypeId = Number(entry.waste_type_id)
            const correctedWeight = entry.corrected_weight ? Number(entry.corrected_weight) : 0
            
            acc.mixed_weight += (wasteTypeId === WasteTypeIds.mixed ? correctedWeight : 0)
            acc.bio_weight += (wasteTypeId === WasteTypeIds.bio ? correctedWeight : 0)
            acc.paper_weight += (wasteTypeId === WasteTypeIds.paper ? correctedWeight : 0)
            acc.plastic_weight += (wasteTypeId === WasteTypeIds.plastic ? correctedWeight : 0)
            acc.glass_weight += (wasteTypeId === WasteTypeIds.glass || wasteTypeId === WasteTypeIds.clearGlass ? correctedWeight : 0)
            acc.mixed_dump_count += (wasteTypeId === WasteTypeIds.mixed ? 1 : 0)
            acc.bio_dump_count += (wasteTypeId === WasteTypeIds.bio ? 1 : 0)
            acc.paper_dump_count += (wasteTypeId === WasteTypeIds.paper ? 1 : 0)
            acc.plastic_dump_count += (wasteTypeId === WasteTypeIds.plastic ? 1 : 0)
            acc.glass_dump_count += (wasteTypeId === WasteTypeIds.glass || wasteTypeId === WasteTypeIds.clearGlass ? 1 : 0)
            return acc
        }, {
            all_weight: 0,
            recycling_weight: 0,
            mixed_weight: 0,
            bio_weight: 0,
            paper_weight: 0,
            plastic_weight: 0,
            glass_weight: 0,
            all_bin_dump_count: 0,
            recycling_dump_count: 0,
            mixed_dump_count: 0,
            bio_dump_count: 0,
            paper_dump_count: 0,
            plastic_dump_count: 0,
            glass_dump_count: 0,
        })
        
        yearlyData.recycling_dump_count =
            yearlyData.bio_dump_count +
            yearlyData.paper_dump_count +
            yearlyData.plastic_dump_count +
            yearlyData.glass_dump_count

        yearlyData.all_bin_dump_count =
            yearlyData.mixed_dump_count + 
            yearlyData.recycling_dump_count

        yearlyData.recycling_weight =
            yearlyData.bio_weight +
            yearlyData.paper_weight +
            yearlyData.plastic_weight +
            yearlyData.glass_weight

        yearlyData.all_weight =
            yearlyData.mixed_weight + 
            yearlyData.recycling_weight
    
        return yearlyData
    }

    const yearlyData = aggregateData(data.bin_weightings)

    const totalWasteData: AggregatedWasteData = {
        name: 'Celkem',
        tons: yearlyData.all_weight/1000,
        perPerson: yearlyData.all_weight/NUMBER_OF_PEOPLE,
        percentage: null,
        percentageRecycling: null,
        dumpsCount: yearlyData.all_bin_dump_count,
        color: {
            light: '#D5D5D5',
            main: '#B3B3B3',
            dark: '#9B9B9B',
        }
    }

    const mixedWasteData: AggregatedWasteData = {
        name: 'Směsný komunální',
        tons: yearlyData.mixed_weight/1000,
        perPerson: yearlyData.mixed_weight/NUMBER_OF_PEOPLE,
        percentage: yearlyData.mixed_weight/(yearlyData.all_weight/100),
        dumpsCount: yearlyData.mixed_dump_count,
        percentageRecycling: null,
        color: WASTE_COLORS.municipal,
    }

    const recyclingWasteData: AggregatedWasteData = {
        name: 'Tříděný',
        tons: (yearlyData.recycling_weight/1000),
        perPerson: (yearlyData.recycling_weight/NUMBER_OF_PEOPLE),
        percentage: (yearlyData.recycling_weight/(yearlyData.all_weight/100)),
        dumpsCount: yearlyData.recycling_dump_count,
        color: WASTE_COLORS.recycling,
    }

    const recyclingIndividualData: Array<AggregatedWasteData> = [
        {
            name: 'Bio',
            tons: yearlyData.bio_weight/1000,
            perPerson: yearlyData.bio_weight/NUMBER_OF_PEOPLE,
            percentage: yearlyData.bio_weight/(yearlyData.all_weight/100),
            dumpsCount: yearlyData.bio_dump_count,
            percentageRecycling: yearlyData.bio_weight/(yearlyData.recycling_weight/100),
            color: WASTE_COLORS.bio,
        },
        {
            name: 'Plast',
            tons: yearlyData.plastic_weight/1000,
            perPerson: yearlyData.plastic_weight/NUMBER_OF_PEOPLE,
            percentage: yearlyData.plastic_weight/(yearlyData.all_weight/100),
            dumpsCount: yearlyData.plastic_dump_count,
            percentageRecycling: yearlyData.plastic_weight/(yearlyData.recycling_weight/100),
            color: WASTE_COLORS.plastic,
        },
        {
            name: 'Papír',
            tons: yearlyData.paper_weight/1000,
            perPerson: yearlyData.paper_weight/NUMBER_OF_PEOPLE,
            percentage: yearlyData.paper_weight/(yearlyData.all_weight/100),
            dumpsCount: yearlyData.paper_dump_count,
            percentageRecycling: yearlyData.paper_weight/(yearlyData.recycling_weight/100),
            color: WASTE_COLORS.paper,
        },
        {
            name: 'Sklo',
            tons: yearlyData.glass_weight/1000,
            perPerson: yearlyData.glass_weight/NUMBER_OF_PEOPLE,
            percentage: yearlyData.glass_weight/(yearlyData.all_weight/100),
            dumpsCount: yearlyData.glass_dump_count,
            percentageRecycling: yearlyData.glass_weight/(yearlyData.recycling_weight/100),
            color: WASTE_COLORS.glass,
        },
    ]

    return (
        <>
            {/* The following container can be potentially refactored into a separate
            component together with  MunicipalitySortingTrendWrapper */}
            <Box sx={{
                display: 'flex',
                gap: 2,
                pt: 2,
                flexWrap: 'wrap',
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 2,
                    }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
                            <DatePicker
                                label="Datum od"
                                views={['year', 'month']}
                                value={startDateValue}
                                onChange={newDate => {
                                    setStartDateValue(newDate)
                                }}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
                            <DatePicker
                                label="Datum do"
                                views={['year', 'month']}
                                value={endDateValue}
                                onChange={newDate => {
                                    setEndDateValue(newDate)
                                }}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        gap: 0.5,
                        pt: 1.5,
                    }}>
                        <CalendarMonthRoundedIcon />
                        <Chip
                            label="Posledních 12 měsíců"
                            size="small"
                            onClick={handleSetLast12Months}
                            sx={{
                                backgroundColor: 'customLightGreen.main',
                                '&:hover': {
                                    backgroundColor: 'customLightGreen.dark',
                                }
                            }}
                        />
                        <Chip
                            label="Poslední kalendářní rok"
                            size="small"
                            onClick={handleSetLastCalendarYear}
                            sx={{
                                backgroundColor: 'customLightGreen.main',
                                '&:hover': {
                                    backgroundColor: 'customLightGreen.dark',
                                }
                            }}
                        />
                    </Box>
                </Box>
                <Box pt={1.2}>
                    <Button
                        variant="contained"
                        color="terciary"
                        startIcon={<CachedRoundedIcon />}
                        onClick={() => handleSetDates()}
                    >
                        Aktualizovat
                    </Button>
                </Box>
            </Box>
            <Box pt={5}>
                <Typography variant="h3" component="h3">
                    Kumulativně za celé období
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                gap: {
                    xs: 2,
                    xl: 4,
                },
                flexDirection: {
                    xs: 'column',
                    md: 'row',
                },
                pt: 4,
            }}>
                <Box sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}>
                    <Grid container spacing={2}>
                        {[mixedWasteData, recyclingWasteData].map(card => (
                            <Grid key={card.name} xs={12} sm={6} md={12} lg={6} xl={4}>
                                <WasteCard data={card} />
                            </Grid>
                        ))}
                    </Grid>
                    <Divider />
                    <Grid container spacing={2}>
                        {recyclingIndividualData.map(card => (
                            <Grid key={card.name} xs={12} sm={6} md={12} lg={6} xl={4}>
                                <WasteCard data={card} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Box display="flex" justifyContent="center">
                    <TotalPieChart
                        dataInner={[mixedWasteData, recyclingWasteData]}
                        dataOuter={[mixedWasteData, ...recyclingIndividualData]}
                    />
                </Box>
            </Box>
            <Box pt={3} pb={2}>
                <Typography variant="h3" component="h3">
                    Historie svozu po dnech
                </Typography>
            </Box>
            <DisposalTrendCalendar
                startDate={startDate}
                endDate={endDate}
                data={data.bin_weightings}
            />
            <Box pt={5} pb={1}>
                <Typography variant="h3" component="h3">
                    Měsíční trend výsypu ve srovnání s předchozím rokem
                </Typography>
            </Box>
            <DisposalTrendMonthlyTrend endDate={endDate}/>
        </>
    )
}
