import React from 'react'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'

export default function FormWarningsAlert({
    warnings,
    buttonText = 'uložit',
}: {
    warnings: Array<string>,
    buttonText?: string,
}) {

    return (
        <Alert
            severity="warning"
            sx={(theme) => ({
                flexGrow: 1,
                margin: theme.spacing(1, 0),
            })}
            action={
                <Button
                    color="warning"
                    size="small"
                    startIcon={<Icon>publish</Icon>}
                    type="submit"
                    sx={{
                        whiteSpace: 'nowrap',
                        backgroundColor: 'errorOrangeLight.main',
                        '&:hover': {
                            backgroundColor: 'errorOrangeLight.dark',
                        }
                    }}
                >
                    Přesto {buttonText.toLowerCase()}
                </Button>
            }
        >
            {warnings.join(' | ')}
        </Alert>
    )
}
