import Typography from '@mui/material/Typography'
import { Helmet } from 'react-helmet-async'

import CustomPaper from '@/components/custom-paper'
import HouseholdRefresh from '@/components/household-refresh'
import PermissionsGate from '@/components/permissions-gate'
import { UserRole } from '@/types'

function HouseholdRefreshView() {

    const title = 'Přepočítání statistik domácností/svozových míst'

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <CustomPaper>
                <PermissionsGate
                    userRoles={[ UserRole.admin ]}
                    errorPage
                >
                    <Typography variant="h2" component="h1" gutterBottom>
                        {title}
                    </Typography>
                    <HouseholdRefresh />
                </PermissionsGate>
            </CustomPaper>
        </>
    )
}

export default HouseholdRefreshView
