import axios from 'axios'
import { useAtom } from "jotai"
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'

import type {
    FormMutationOptions,
    PayLessInstruction,
    PayLessInstructionFormInput,
} from '@/types'
import { selectedMunicipalityIdAtom } from "@/state"

export function usePayLessInstructions(municipalityId) {
    return useQuery({
        queryKey: ['payLessInstructions', municipalityId],
        queryFn: async () => {
            const { data } : { data: Array<PayLessInstruction> } = await axios.get(
                '/api/pay_less_instruction/list?municipality_id=' + municipalityId,
            )
            return data
        },
    })
}

export function usePayLessInstruction(payLessInstructionId) {
    return useQuery({
        queryKey: ['payLessInstruction-' + payLessInstructionId],
        queryFn: async () => {
            const { data } : { data: PayLessInstruction } = await axios.get(
                '/api/pay_less_instruction/single?id=' + payLessInstructionId,
            )
            return data
        },
    })
}

export const useCreatePayLessInstruction = (options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: PayLessInstructionFormInput) => {            
            return axios.post(`/api/pay_less_instruction/create?municipality_id=${municipalityId}`,
                {description: data.description}
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['payLessInstructions'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useUpdatePayLessInstruction = (payLessInstructionId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: PayLessInstructionFormInput) => {
            return axios.put(`/api/pay_less_instruction/update?id=${payLessInstructionId}`,
                {description: data.description}
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['payLessInstructions'] })
            queryClient.invalidateQueries({ queryKey: ['payLessInstruction-' + payLessInstructionId] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useDeletePayLessInstruction = payLessInstructionId => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => {
            return axios.delete(`/api/pay_less_instruction/delete?id=${payLessInstructionId}`)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['payLessInstructions'] })
        },
    })
}
