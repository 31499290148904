import { useForm, type SubmitHandler } from "react-hook-form"
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import useFormLocalStorage from "@/hooks/use-form-local-storage"
import { APP_VERSION, ROUTE_SEGMENTS } from "@/constants/general"
import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import RHFNumberTextfield from "../form-fields/rhf-number-textfield"
import type { MotivationModel, MotivationModelAccountingFormInput } from "@/types"
import { useUpdateMotivationModelAccounting } from "@/hooks/api/use-motivation-model"
import { convertFromCents } from "@/helpers"
import { useNavigate } from "react-router-dom"

const MotivationModelAccountingForm = ({
    motivationModel,
    closeCallback,
} : {
    motivationModel: MotivationModel,
    closeCallback?: () => void,
}) => {

    const navigate = useNavigate()
    
    const formDataId = `motivationModelAccountingForm-${motivationModel?.id}-v${APP_VERSION}`

    const initialValues: MotivationModelAccountingFormInput = {
        nonpaying_citizens_count: motivationModel?.nonpaying_citizens_count != null
            ? motivationModel.nonpaying_citizens_count
            : '',
        collection_yard_operation: motivationModel?.collection_yard_operation_cents != null
            ? convertFromCents(motivationModel.collection_yard_operation_cents)
            : '',
        waste_management: motivationModel?.waste_management_cents != null
            ? convertFromCents(motivationModel.waste_management_cents)
            : '',
        collection_yard_income: motivationModel?.collection_yard_income_cents != null
            ? convertFromCents(motivationModel.collection_yard_income_cents)
            : '',
        other_income: motivationModel?.other_income_cents != null
            ? convertFromCents(motivationModel.other_income_cents)
            : '',
    }

    const isFilled = motivationModel?.nonpaying_citizens_count != null ||
        motivationModel?.collection_yard_operation_cents != null ||
        motivationModel?.waste_management_cents != null ||
        motivationModel?.collection_yard_income_cents != null ||
        motivationModel?.other_income_cents != null
    
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const [isLoadedFromLocalStorage, handleFormReset] = useFormLocalStorage({
        control,
        formDataId,
        reset,
        initialValues
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
    } = useFormStates(handleFormReset)

    const mutation = useUpdateMotivationModelAccounting(
        motivationModel.id,
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback(motivationModel) {
                navigate(`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipalityManagement}/${ROUTE_SEGMENTS.motivationModel}/${motivationModel.id}/${ROUTE_SEGMENTS.step}/2`)
            },
        },
    )

    const onSubmit: SubmitHandler<MotivationModelAccountingFormInput> = data => {
        setRequestInProgress(true)
        mutation.mutate(data)
    }

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={isLoadedFromLocalStorage}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
            buttonText={isFilled ? "Upravit" : "Uložit a pokračovat"}
        >
            <Typography variant="h5" component="h3" gutterBottom>
                Zadejte ostatní náklady spojené s odpadovým hospodářstvím
            </Typography>
            <RHFNumberTextfield
                name="collection_yard_operation"
                control={control}
                label="Náklady na provoz sběrných dvorů"
                endAdornment="Kč s DPH/rok"
                decimalScale={2}
                thousandSeparator
                disallowNegative
            />
            <RHFNumberTextfield
                name="waste_management"
                control={control}
                label="Ostatní náklady za odpadové hospodářství"
                endAdornment="Kč s DPH/rok"
                decimalScale={2}
                thousandSeparator
                disallowNegative
            />
            <Box/>
            <Typography variant="h5" component="h3" gutterBottom>
                Zadejte ostatní příjmy (mimo příjmů od EKOKOMU)
            </Typography>
            <RHFNumberTextfield
                name="collection_yard_income"
                control={control}
                label="Příjmy ze sběrných dvorů"
                endAdornment="Kč s DPH/rok"
                decimalScale={2}
                thousandSeparator
                disallowNegative
            />
            <RHFNumberTextfield
                name="other_income"
                control={control}
                label="Ostatní příjmy za odpadové hospodářství"
                endAdornment="Kč s DPH/rok"
                decimalScale={2}
                thousandSeparator
                disallowNegative
            />
            <Box/>
            <Typography variant="h5" component="h3" gutterBottom>
                Obyvatelé obce
            </Typography>
            <RHFNumberTextfield
                name="nonpaying_citizens_count"
                control={control}
                label="Počet obyvatel osvobozených od poplatku"
                thousandSeparator
                disallowNegative
            />
        </Form>
    )
}

export default MotivationModelAccountingForm
