import type { EditorOptions } from "@tiptap/core"
import StarterKit from "@tiptap/starter-kit"
import Image from "@tiptap/extension-image"
import Link from "@tiptap/extension-link"
import TextAlign from "@tiptap/extension-text-align"
import Subscript from "@tiptap/extension-subscript"
import Superscript from "@tiptap/extension-superscript"
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import Dropcursor from "@tiptap/extension-dropcursor"
import { LinkBubbleMenuHandler, ResizableImage, TableImproved } from "mui-tiptap"
import { useMemo } from "react"

export default function useTipTapExtensions(): EditorOptions["extensions"] {
    return useMemo(() => {
        return [
            TableImproved.configure({
                resizable: true,
              }),
            TableRow,
            TableHeader,
            TableCell,
            StarterKit,
            //Image,
            Link,
            Subscript,
            Superscript,
            TextAlign.configure({
                types: ["heading", "paragraph", "image"],
            }),
            TextStyle,
            Color.configure({
                types: ['textStyle'],
            }),
            LinkBubbleMenuHandler,
            ResizableImage.configure({
                // inline: true,
                allowBase64: true,
            }),
        ]
    }, [])
}
