import Box from '@mui/material/Box'
import { useAtom } from 'jotai'

import { useCollectionNotifications } from '@/hooks/api/use-collection-notification'
import LoadingBox from '@/components/loading-box'
import ErrorBox from '@/components/error-box'
import { selectedMunicipalityIdAtom } from "@/state"
import CollectionNotificationCard from '@/components/collection-notification/card'
import type { Pagination } from '@/types'

function CollectionNotificationListHome() {

    const pagination: Pagination = {
        limit: 3,
        offset: 0,
    }
    
    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)
    
    const { status, data, error } = useCollectionNotifications(municipalityId, pagination)

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst upozornění ke svozu pro obec ID ${municipalityId}`}
        />
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
        }}>
            {data.records_count === 0 ?
                <Box>
                    Zatím nebyla vydána žádná upozornění
                </Box>
            :
                data.collection_notification.map(notification => (
                    <CollectionNotificationCard
                        key={notification.id}
                        collectionNotification={notification}
                        editable={false}
                    />
                ))
            }
        </Box>
    )
}

export default CollectionNotificationListHome
