import axios from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'

import type {
    FormMutationOptions,
    ContactDivisionFormInput,
    ContactFormInput,
    ContactDivision,
} from '@/types'

export function useContactDivisionsMunicipality(
    municipalityId: number,
) {
    return useQuery({
        queryKey: ['contactDivisions', 'municipality', municipalityId ],
        queryFn: async () => {
            const { data } : { data: Array<ContactDivision>} = await axios.get(
                `/api/contact_division/list?municipality_id=${municipalityId}`
            )
            return data
        },
    })
}

export function useContactDivisionsCollectionCompany(
    collectionCompanyId: number,
) {
    return useQuery({
        queryKey: ['contactDivisions', 'collectionCompany',  collectionCompanyId ],
        queryFn: async () => {
            const { data } : { data: Array<ContactDivision>} = await axios.get(
                `/api/contact_division/list?collection_company_id=${collectionCompanyId}`
            )
            return data
        },
    })
}


export const useCreateContactDivision = (municipalityId, collectionCompanyId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: ContactDivisionFormInput) => {            
            return axios.post(`/api/contact_division/create?${
                municipalityId ? `municipality_id=${municipalityId}` : `collection_company_id=${collectionCompanyId}`
                }&name=${data.name}`,
                {description: data.description}
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['contactDivisions'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useUpdateContactDivision = (contactDivisionId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: ContactDivisionFormInput) => {
            return axios.put(`/api/contact_division/update?id=${contactDivisionId
                }&name=${data.name}`,
                {description: data.description}
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['contactDivisions'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useDeleteContactDivision = contactDivisionId => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => {
            return axios.delete(`/api/contact_division/delete?id=${contactDivisionId}`)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['contactDivisions'] })
        },
    })
}

export const useCreateContact = (contactDivisionId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: ContactFormInput) => {            
            return axios.post(`/api/contact/create?contact_division_id=${contactDivisionId
                }&name=${data.name
                }${ data.description ? `&description=${encodeURIComponent(data.description)}` : ''
                }${ data.email ? `&email=${encodeURIComponent(data.email)}` : ''
                }${ data.phone_number ? `&phone_number=${encodeURIComponent(data.phone_number)}` : ''}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['contactDivisions'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useUpdateContact = (contactId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: ContactFormInput) => {
            return axios.put(`/api/contact/update?id=${contactId
                }${ data.name ? `&name=${data.name}` : ''
                }${ data.description ? `&description=${encodeURIComponent(data.description)}` : ''
                }${ data.email ? `&email=${encodeURIComponent(data.email)}` : ''
                }${ data.phone_number ? `&phone_number=${encodeURIComponent(data.phone_number)}` : ''}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['contactDivisions'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useDeleteContact = contactId => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => {
            return axios.delete(`/api/contact/delete?id=${contactId}`)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['contactDivisions'] })
        },
    })
}
