import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Helmet } from 'react-helmet-async'

import CustomPaper from '@/components/custom-paper'
import HowToPayLessList from '@/components/how-to-pay-less/list'

function HowToPayLessView() {

     const title = 'Jak platit méně'

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <CustomPaper>
                <Box position="relative">
                    <Typography variant="h2" component="h1" gutterBottom>
                        {title}
                    </Typography>
                    <HowToPayLessList />
                </Box>
            </CustomPaper>
        </>
    )
}

export default HowToPayLessView
