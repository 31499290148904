import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import Typography from '@mui/material/Typography'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'

import { useActivateMotivationModel } from '@/hooks/api/use-motivation-model'

function ActivateDialog({
    motivationModelId,
    motivationModelName,
    handleClose,
}: {
    motivationModelId: string | number,
    motivationModelName: string,
    handleClose: () => void,
}) {

    const [requestInProgress, setRequestInProgress] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)

    const mutation = useActivateMotivationModel(
        motivationModelId,
        {
            setRequestInProgress,
            onSuccessCallback(data) {
                if(data) {
                    setSuccess(true)
                }
            }
        },
    )

    const handleClickActivate = () => {
        setRequestInProgress(true)
        mutation.mutate()
    }

    return (
        <Box sx={{
            minWidth: 200,
            minHeight: 100,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            {success ?
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                    px: 3,
                }}>
                    <TaskAltRoundedIcon sx={{
                        color: 'success.main',
                        fontSize: '4rem',
                    }}/>
                    <Typography variant="body1" component="div">
                        Model úspěšně aktivován.
                    </Typography>
                    <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={() => {
                            setSuccess(false)
                            handleClose()
                        }}
                    >
                        Zavřít
                    </Button>
                </Box>
                :
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Typography variant="h5" component="div" mb={1}>
                        Potvrďte prosím aktivaci modelu: {motivationModelName}
                    </Typography>
                    <Typography variant="body2" component="div" mb={1} color="customGrey.secondaryText">
                        ID modelu: {motivationModelId}
                    </Typography>
                    <Typography variant="body2" component="div" mb={2}>
                        Tato akce nastaví model jako výchozí pro výpočet hodnot pro zvolený rok.
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexGrow: 1,
                    }}>
                        <Button
                            variant="text"
                            color="terciary"
                            onClick={() => handleClose()}
                        >
                            Zrušit
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={requestInProgress ? <CircularProgress color="inherit" size={20} /> : <CheckCircleRoundedIcon />}
                            onClick={() => handleClickActivate()}
                            disabled={requestInProgress}
                        >
                            Aktivovat
                        </Button>
                    </Box>
                </Box>
            }
        </Box>
    )
}

export default ActivateDialog
