import { useState } from "react"
import type { FileMetadata } from '@/types'
import { FileChecksum } from '@rails/activestorage/src/file_checksum'

const calculateChecksum = (file: File): Promise<string> => (
    new Promise((resolve, reject) => {
        FileChecksum.create(file, (error, checksum) => {
            if (error) {
                reject(error)
                return
            }

            resolve(checksum)
        })
    })
)

const getFileMetadata = (file: File): Promise<FileMetadata> => (
    new Promise(resolve => {
        calculateChecksum(file).then(checksum => {
            resolve({
                checksum,
                filename: file.name,
                contentType: file.type,
                byteSize: file.size,
            })
        })
    })
)

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
        if ((encoded.length % 4) > 0) {
            encoded += '='.repeat(4 - (encoded.length % 4))
        }
        resolve(encoded)
    }
    reader.onerror = reject
})

const useFileUpload = () => {

    const [files, setFiles] = useState<Array<File>>([])

    const handleFileChange = event => {
        setFiles(event.target.files)
    }

    return {
        files,
        setFiles,
        handleFileChange,
        getFileMetadata,
        getBase64,
    }

}

export default useFileUpload
