import { useState } from "react"
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import PublishRoundedIcon from '@mui/icons-material/PublishRounded'

import { usePublishCollectionNotification } from "@/hooks/api/use-collection-notification"

export default function CollectionNotificationPublishAlert({
    collectionNotificationId,
}: {
    collectionNotificationId: number,
}) {

    const [error, setError] = useState<string | null>(null)
    const [requestInProgress, setRequestInProgress] = useState<boolean>(false)

    const mutation = usePublishCollectionNotification(
        collectionNotificationId,
        {
            setRequestInProgress,
            onErrorCallback(errorMessage) {
                setError(errorMessage)
            }
        },
    )

    const handlePublish = () => {
        setRequestInProgress(true)
        setError(null)
        mutation.mutate()
    }

    return (
        <Alert
            severity="warning"
            sx={{
                flexGrow: 1,
                mb: 2,
                borderRadius: {
                    xs: 3,
                    md: 20,
                },
                flexWrap: {
                    xs: 'wrap',
                    md: 'nowrap',
                }
            }}
            action={
                <Button
                    color="inherit"
                    size="small"
                    disabled={requestInProgress}
                    startIcon={requestInProgress ?
                        <CircularProgress color="inherit" size={20} />
                        :
                        <PublishRoundedIcon/>
                    }
                    onClick={() => handlePublish()}
                    sx={{
                        whiteSpace: 'nowrap',
                        backgroundColor: 'errorOrangeLight.main',
                        '&:hover': {
                            backgroundColor: 'errorOrangeLight.dark',
                        }
                    }}
                    
                >
                    Publikovat
                </Button>
            }
        >
            {error ?
                error
                :
                'Toto upozornění zatím nebylo publikováno.'
            }
        </Alert>
    )
}
