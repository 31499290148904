import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Helmet } from 'react-helmet-async'

import CustomPaper from '@/components/custom-paper'

function LargeContainerView() {

    const title = 'Svozové místo - Objednání kontejneru'

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <CustomPaper>
                <Box>
                    <Typography variant="h2" component="h1" gutterBottom>
                        {title}
                    </Typography>
                </Box>
            </CustomPaper>
        </>
    )
}

export default LargeContainerView
