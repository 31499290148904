import Box from '@mui/material/Box'

import type { CommunicationThread } from '@/types'

export default function CommunicationThreadStatus({
    communicationThread,
}: {
    communicationThread: CommunicationThread,
}) {
    
    const STATUS_TYPE = {
        created: {
            id: 1,
            label: 'Vytvořeno',
            color: 'state.inProgress',
        },
        inProgress: {
            id: 2,
            label: 'V řešení',
            color: 'state.inProgress',
        },
        closed: {
            id: 3,
            label: 'Vyřešeno',
            color: 'state.resolved',
        },
    }

    const status = communicationThread.closed_at ? STATUS_TYPE.closed :
        communicationThread.created_in_eoko_at ? STATUS_TYPE.inProgress : STATUS_TYPE.created

    return (
        <Box sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center'
        }}>
            <Box
                sx={{
                    borderRadius: '50%',
                    backgroundColor: status.color,
                    width: '14px',
                    height: '14px',
                }}
            />
            <Box>{status.label}</Box>
        </Box>
    )
}
