import type { Dispatch, SetStateAction } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import EditOffRoundedIcon from '@mui/icons-material/EditOffRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'

export default function EditModeToggle({
    editMode,
    setEditMode,
}: {
    editMode: boolean,
    setEditMode: Dispatch<SetStateAction<boolean>>,
}) {


    return (
        <Box sx={{
            position: 'absolute',
            top: -8,
            right: -8,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderRadius: '1rem',
        }}>
            <Button
                color='terciary'
                startIcon={editMode ?
                    <EditOffRoundedIcon />
                    :
                    <EditRoundedIcon/>
                }
                onClick={() => setEditMode(!editMode)}
                sx={{
                    backgroundColor: editMode ? 'errorRedLight.main' : 'editableOrange.main',
                    display: {
                        xs: 'none',
                        md: 'flex',
                    }
                }}
                
            >
                {editMode ? 'Zavřít editaci' : 'Editovat'}
            </Button>
            <Tooltip title={editMode ? 'Zavřít editaci' : 'Editovat'}>
                <IconButton
                    color='terciary'
                    onClick={() => setEditMode(!editMode)}
                    sx={{
                        backgroundColor: editMode ? 'errorRedLight.main' : 'editableOrange.main',
                        display: {
                            xs: 'flex',
                            md: 'none',
                        }
                    }}
                    
                >
                    {editMode ?
                        <EditOffRoundedIcon />
                        :
                        <EditRoundedIcon/>
                    }
                </IconButton>
            </Tooltip>
        </Box>
    )
}
