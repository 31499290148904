import { type ReactNode, useId, useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import Typography from '@mui/material/Typography'
import EmailRoundedIcon from '@mui/icons-material/EmailRounded'
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded'
import { styled } from '@mui/material/styles'

import { ContentBox } from './content-box'

function GradientForSVG({
    children,
}: {
    children: (svgFillValue) => ReactNode
}) {
    const id = useId()
    const fillValue = `url(#${id})`

    return (
        <>
            <Box
                /* Gradient mustn't be hidden with `display:none`, that would disable it */
                sx={{
                    width: 0,
                    height: 0,
                    position: 'absolute', // don't influence layout
                    pointerEvents: 'none', // not interactible
                    overflow: 'hidden', // inner svg should not influence layout, it is quite big ...
                }}
                role="presentation" // not present on screen readers
            >
                <svg>
                    <title>Gradient</title>
                    <defs>
                        <linearGradient id={id} x1="0" x2="0" y1="0" y2="1">
                            <stop offset="0%" stopColor="#C4E5BE" />
                            {/* <stop offset="50%" stop-color="black" stop-opacity="0" /> */}
                            <stop offset="100%" stopColor="#A6D79D" />
                        </linearGradient>
                    </defs>
                </svg>
            </Box>
            {children(fillValue)}
        </>
    )
}

export function PersonBox({
    fullName,
    children,
    iconSize = '2.625rem',
    icon,
}: {
    fullName: ReactNode
    children?: ReactNode
    iconSize?: string
    icon?: ReactNode
}) {
    return (
        <ContentBox
            padding={'.5rem .75rem'}
            sxContent={{
                gap: '.25rem',
                width: '100%', // stretch so that box fills full width of container
                
            }}
            sxWrapper={{
                flexGrow: 1,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '1rem',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        width: iconSize,
                        aspectRatio: 1,
                        flexShrink: 0, // force icon size passed from parameters
                    }}
                >
                    <GradientForSVG>
                        {fill =>
                            icon || (
                                <AccountCircleIcon
                                    sx={{
                                        fill,
                                        width: '100%',
                                        height: '100%', // it is radial so it is fine
                                    }}
                                />
                            )
                        }
                    </GradientForSVG>
                </Box>
                <Typography
                    component="div"
                    color="#5F5F5F"
                    fontSize={'1rem'}
                    sx={{
                        fontSize: '1rem',
                        width: '100%', // stretch so that box fills full width of container
                    }}
                >
                    {fullName}
                </Typography>
            </Box>
            {children}
        </ContentBox>
    )
}

export const PersonDetails = styled('div')(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.customGrey.cardBorder}`,
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 2rem .5rem 0',
    gap: '.35rem',
}))

export function PersonValue({
    type,
    value,
}: {
    type: 'phone' | 'email'
    value: string
}) {
    const icons: {
        [key in typeof type]: ReactNode
    } = {
        email: <EmailRoundedIcon sx={{ width: '100%', height: '100%' }} />,
        phone: <LocalPhoneRoundedIcon sx={{ width: '100%', height: '100%' }} />,
    }

    const [showContactInfo, setShowContactInfo] = useState<boolean>(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowContactInfo(true)
        }, 500)
        return () => clearTimeout(timer)
    }, [])

    return showContactInfo ?
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '.75rem',
                alignItems: 'center',
                color: 'customGrey.tableText',
                textDecoration: 'none',
            }}
            component={value ? 'a' : 'div'}
            href={value ? `${type === 'phone' ? 'tel:' : 'mailto:'}${value}` : undefined}
        >
            <Box sx={{ display: 'flex', width: '1rem', aspectRatio: 1, color: 'customGrey.selectorIcon' }}>
                {icons[type]}
            </Box>
            <Typography variant="body1" component="div" sx={{ overflowWrap: 'anywhere'}}>
                {value ?? '-'}
            </Typography>
        </Box>
        :
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '.75rem',
                alignItems: 'center',
                color: 'customGrey.tableText',
                textDecoration: 'none',
            }}
        >
            <Box sx={{ display: 'flex', width: '1rem', aspectRatio: 1, color: 'customGrey.selectorIcon' }}>
                {icons[type]}
            </Box>
            <Typography variant="body1" component="div" sx={{ overflowWrap: 'anywhere'}}>
                <Skeleton width={100} />
            </Typography>
        </Box>
}
