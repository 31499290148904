import Box from '@mui/material/Box'
import { useAtom } from 'jotai'

import {
    CustomTabs,
    CustomTab,
    CustomTabPanelSmall,
} from '@/components/custom-tabs'
import useTabs from '@/hooks/use-tabs'
import WasteFeeYearDetails from '@/components/waste-fee/year-details'
import { useWasteFees } from '@/hooks/api/use-waste-fee'
import { selectedMunicipalityIdAtom } from '@/state'
import LoadingBox from '@/components/loading-box'
import ErrorBox from '@/components/error-box'
import WasteFeeForm from '@/components/forms/waste-fee'
import { UserRole } from '@/types'
import { getUserRole } from '@/helpers'

function WasteFeeTabs() {

    const role = getUserRole()
    
    const { tab, handleTabChange, a11yProps } = useTabs()

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)
    
    const { status, data, error } = useWasteFees(municipalityId)

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst detaily poplatků za odpad pro obec s ID ${municipalityId}`}
        />
    }

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <CustomTabs
                    value={tab}
                    onChange={handleTabChange}
                    aria-label="waste fee tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {data.map((year, index) => (
                        <CustomTab key={year.id} label={year.year} {...a11yProps(index)} />
                    ))}
                    {(role === UserRole.admin || role === UserRole.municipalityOfficial) &&
                        <CustomTab key="Přidat" label="Přidat" {...a11yProps(data.length)} />
                    }
                </CustomTabs>
            </Box>
            {data.map((year, index) => (
                <CustomTabPanelSmall key={year.id} value={tab} index={index}>
                    <WasteFeeYearDetails wasteFee={year} />
                </CustomTabPanelSmall>
            ))}
            {(role === UserRole.admin || role === UserRole.municipalityOfficial) &&
                <CustomTabPanelSmall value={tab} index={data.length}>
                    <WasteFeeForm />
                </CustomTabPanelSmall>
            }
        </>
    )
}

export default WasteFeeTabs
