import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { PieChart, Pie, Cell, Tooltip } from 'recharts'

import type { AggregatedWasteData } from '@/types'
import useWindowDimensions from '@/hooks/use-window-dimensions'

export default function TotalPieChart({
    dataInner,
    dataOuter,
}: {
    dataInner: Array<AggregatedWasteData>,
    dataOuter: Array<AggregatedWasteData>,
}) {

    const { width } = useWindowDimensions()

    const RADIAN = Math.PI / 180
    const iR = 0
    let cx = 150
    let cy = 150
    let oR = 200
    let pieWidth = 300
    let pieHeight = 300
    let innerRadius = 70
    let outerRadius = 130

    if (width > 500) {
        cx = 200
        cy = 200
        oR = 250
        pieWidth = 400
        pieHeight = 400
        innerRadius = 100
        outerRadius = 170
    }
    

    const needle = (cx, cy, iR, oR, color) => {
        // Calculate the angle for the needle based on the full 360 degrees of the chart
        const ang = 360 * ( -15 / 100)
        const length = (iR + 2 * oR) / 3 // Length of the needle
        const sin = Math.sin(-RADIAN * ang) // Sin of the angle
        const cos = Math.cos(-RADIAN * ang) // Cos of the angle
        const x0 = cx // Center of the chart
        const y0 = cy // Center of the chart
        const x1 = x0 + length * cos // End point of the line
        const y1 = y0 + length * sin // End point of the line
      
        // Return the line element representing the needle
        return (
          <line
            x1={x0}
            y1={y0}
            x2={x1}
            y2={y1}
            stroke={color}
            strokeWidth={2}
            strokeDasharray="5, 5"
          />
        )
    }

    return (
        <Box minHeight={pieWidth} width={pieHeight}>
            <PieChart width={pieWidth} height={pieHeight}>
                <Pie data={dataInner} dataKey="percentage" cx="50%" cy="50%" outerRadius={innerRadius} fill="#f5f5f5" startAngle={90} endAngle={-270}>
                    {dataInner.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color.main} fillOpacity={0.6}/>
                    ))}
                </Pie>
                <Pie data={dataOuter} dataKey="percentage" cx="50%" cy="50%" innerRadius={innerRadius} outerRadius={outerRadius} fill="#d4d4d4" startAngle={90} endAngle={-270}>
                    {dataOuter.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color.main} />
                    ))}
                </Pie>
                <Tooltip formatter={value => (value as number).toFixed(2)+' %'}/>
                {needle(cx, cy, iR, oR, '#ff0000')}
            </PieChart>
            <Box display="flex" flexWrap="wrap" gap={2} px={2} justifyContent="center">
                {dataOuter.map(entry => (
                    <Box key={entry.name} display="flex" gap={1} alignItems="center">
                        <Box sx={{
                            width: 14,
                            height: 14,
                            backgroundColor: entry.color.main,
                            borderRadius: 7,
                        }}/>
                        <Typography variant="body2" component="span">
                            {entry.name}
                        </Typography>
                    </Box>
                ))}
                <Box key="target" display="flex" gap={1} alignItems="center">
                    <Box sx={{
                        width: 14,
                        height: 14,
                        backgroundColor: '#ff0000',
                        borderRadius: 7,
                    }}/>
                    <Typography variant="body2" component="span">
                        Cíl 40%
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}
