import {
    useState,
    type Dispatch,
    type MouseEvent,
    type SetStateAction,
} from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'

import type { ContactDivision } from '@/types'
import { useDeleteContactDivision } from '@/hooks/api/use-contact'

function ContactDivisionSectionActions({
    contactDivision,
    setEdit,
    setCreateContact
}: {
    contactDivision: ContactDivision,
    setEdit: Dispatch<SetStateAction<boolean>>
    setCreateContact: Dispatch<SetStateAction<boolean>>
}) {

    const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setDeleteConfirmation(false)
    }

    const mutation = useDeleteContactDivision(contactDivision.id)

    const handleDelete = () => {
        mutation.mutate()
    }

    return (
        <>
            <Tooltip title="Více možností">
                <IconButton
                    id="actions-button"
                    aria-label="actions"
                    aria-controls={open ? 'actions-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    color="terciary"
                    onClick={handleClick}
                    sx={{ backgroundColor: 'editableOrange.main' }}
                    size='small'
                >
                    <MoreVertRoundedIcon />
                </IconButton>
            </Tooltip>
            <Menu
                id="actions-menu"
                MenuListProps={{
                    'aria-labelledby': 'actions-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        style: {
                            minWidth: '15ch',
                        }
                    }
                }}
            >
                <MenuItem onClick={() => {
                    setEdit(true)
                    handleClose()
                }}>
                    <ListItemIcon>
                        <EditRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    Upravit sekci
                </MenuItem>
                <MenuItem onClick={() => {
                    setCreateContact(true)
                    handleClose()
                }}>
                    <ListItemIcon>
                        <AddRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    Přidat kontakt
                </MenuItem>
                {deleteConfirmation ?
                    <MenuItem
                        onClick={() => {
                            handleDelete()
                            handleClose()
                        }}
                        sx={{
                            color: 'error.main',
                        }}
                    >
                        <ListItemIcon>
                            <DeleteRoundedIcon fontSize="small" color="error" />
                        </ListItemIcon>
                        Potvrdit
                    </MenuItem>
                    :
                    <MenuItem onClick={() => setDeleteConfirmation(true)}>
                        <ListItemIcon>
                            <DeleteRoundedIcon fontSize="small" />
                        </ListItemIcon>
                        Smazat sekci
                    </MenuItem>
                }
            </Menu>
        </>
    )
}

export default ContactDivisionSectionActions
