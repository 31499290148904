import { useState } from 'react'
import Box from '@mui/material/Box'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import Button from '@mui/material/Button'

import ContactDivisionForm from '../forms/contact-division'

export default function ContactDivisionCreate({
    municipalityId,
    collectionCompanyId,
}: {
    municipalityId?: number,
    collectionCompanyId?: number,
}) {

    const [create, setCreate] = useState<boolean>(false)

    return (
        <Box py={2}>
            {create ?
                <ContactDivisionForm
                    municipalityId={municipalityId}
                    collectionCompanyId={collectionCompanyId}
                    closeCallback={() => setCreate(false)}
                />
                :
                <Button
                    color="terciary"
                    startIcon={<AddRoundedIcon />}
                    onClick={() => setCreate(true)}
                    sx={{ backgroundColor: 'editableOrange.main' }}
                >
                    Nová sekce
                </Button>
            }
        </Box>
    )
}
