import type { Dispatch, SetStateAction, SyntheticEvent } from "react"
import Autocomplete from "@mui/material/Autocomplete"
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import TextField from "@mui/material/TextField"

export default function MunicipalityComparisonSelector({
    comparisons,
    municipalityId,
    setMunicipalityId,
}: {
    comparisons: { id: number, name: string }[],
    municipalityId: number,
    setMunicipalityId: Dispatch<SetStateAction<number>>,
}) {

    const options = comparisons ? comparisons : []

    return (
        <Autocomplete
            value={
                municipalityId != null
                    ? options.find((option) => {
                        return municipalityId === option.id
                    }) ?? null
                    : null
            }
            getOptionLabel={(option) => {
                return option.name
            }}
            getOptionKey={(option) => {
                return option.id
            }}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option.name, inputValue)
                const parts = parse(option.name, matches)

                const text = (parts.map((part, index) => (
                    <span
                        key={index}
                        style={{
                            fontWeight: part.highlight ? 700 : 400,
                        }}
                    >
                        {part.text}
                    </span>
                )))

                return (
                    <li {...props} key={props.key}>
                        <div>
                            {text}
                        </div>
                    </li>
                );
            }}
            onChange={(_event: SyntheticEvent, newValue) => {
                setMunicipalityId(newValue ? newValue.id : null)
            }}
            id="municipality-comparison-selector"
            options={options}
            disableClearable
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Srovnání s"
                    inputProps={{
                        ...params.inputProps,
                        // disable autocomplete and autofill
                        autoComplete: "off",
                    }}
                    autoComplete="off"
                />
            )}
        />
    )
}
