import React from 'react'
import Box from '@mui/material/Box'

import { FONT_WEIGHT } from '../constants/font'

export function TypeTag({
    name,
    color,
    sx,
}: {
    name: string
    color: string
    sx?: React.ComponentProps<typeof Box>['sx']
}) {
    return (
        <Box
            sx={{
                boxSizing: 'border-box',
                backgroundColor: color,
                padding: '.25rem 1.25rem .25rem',
                alignSelf: 'flex-start',
                color: '#fff',
                borderRadius: '10px',
                fontSize: '14px',
                fontWeight: FONT_WEIGHT.medium,
                textAlign: 'center',
                ...sx,
            }}
        >
            {name}
        </Box>
    )
}
