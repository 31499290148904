import { type Dispatch, type SetStateAction, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import CachedRoundedIcon from '@mui/icons-material/CachedRounded'
import Slider from '@mui/material/Slider'

import NumberTextfield from '../form-fields/number-textfield'
import { convertFromCents, printPrice } from '@/helpers'
import type { MotivationModelScenario, ScenarioParameters } from '@/types'
import { MAX_CITIZEN_FEE_CENTS, MIN_CITIZEN_FEE_CENTS } from '@/constants/general'

export default function ScenarioAdjustment({
    scenario,
    setParameters,
    isLoading,
}: {
    scenario: MotivationModelScenario,
    setParameters: Dispatch<SetStateAction<ScenarioParameters>>
    isLoading: boolean,
}) {
    
    const baseFee = convertFromCents(scenario.citizen_fee_cents)
    const maxDiscountVolume = Math.max(...Object.values(scenario.categorized_households.mixed_volume_categories).map(item => item.discount_per_citizen))/100
    const maxDiscountWeight = Math.max(...Object.values(scenario.categorized_households.mixed_weight_categories).map(item => item.discount_per_citizen))/100
    const highestDiscount = maxDiscountVolume+maxDiscountWeight

    const [annualSurchargeValue, setAnnualSurchargeValue] = useState<string>(convertFromCents(scenario.annual_surcharge_cents).toFixed(0))
    const [citizenFeeValue, setCitizenFeeValue] = useState<string>(baseFee != null ? baseFee.toFixed(0) : convertFromCents(MAX_CITIZEN_FEE_CENTS).toString())
    const [lastChange, setLastChange] = useState<'citizenFee' | 'annualSurcharge' | null>(null)

    const handleAnnualSurchargeChange = ({
        target: {
            value,
        },
    }) => {
        setAnnualSurchargeValue(value)
        setLastChange('annualSurcharge')
    }

    const handleAnnualSurchargeSlideChange = (_e, value) => {
        setAnnualSurchargeValue(value.toString())
        setLastChange('annualSurcharge')
    }

    const handleCitizenFeeChange = ({
        target: {
            value,
        },
    }) => {
        setCitizenFeeValue(value)
        setLastChange('citizenFee')
    }

    const handleCitizenFeeSlideChange = (_e, value) => {
        setCitizenFeeValue(value.toString())
        setLastChange('citizenFee')
    }

    const handleRefetch = () => {
        setParameters((prevState) => ({
            ...prevState,
            annualSurcharge: annualSurchargeValue,
            citizenFee: citizenFeeValue,
            lastChange,
        }))
    }

    const minMunicipalityCosts = Number.parseInt(convertFromCents(scenario.min_annual_surcharge_cents).toFixed(0))
    const maxMunicipalityCosts = Number.parseInt(convertFromCents(scenario.max_annual_surcharge_cents).toFixed(0))
    
    const annualSurchargeSliderMarks = [
        {
          value: minMunicipalityCosts,
          label: printPrice(minMunicipalityCosts),
        },
        {
          value: maxMunicipalityCosts,
          label: printPrice(maxMunicipalityCosts),
        },
    ]

    const citizenFeeSliderMarks = [
        {
          value: MIN_CITIZEN_FEE_CENTS+highestDiscount,
          label: printPrice(MIN_CITIZEN_FEE_CENTS+highestDiscount),
        },
        {
          value: convertFromCents(MAX_CITIZEN_FEE_CENTS),
          label: printPrice(convertFromCents(MAX_CITIZEN_FEE_CENTS)),
        },
    ]

    const annualSurchargeOutOfBounds =
        Number.parseInt(annualSurchargeValue) > maxMunicipalityCosts ||
        Number.parseInt(annualSurchargeValue) < minMunicipalityCosts
    
    const citizenFeeOutOfBounds =
        Number.parseInt(citizenFeeValue) > convertFromCents(MAX_CITIZEN_FEE_CENTS) ||
        Number.parseInt(citizenFeeValue) < MIN_CITIZEN_FEE_CENTS+highestDiscount
    
    return (
        <>
            <Box sx={{
                display: 'flex',
                gap: 2,
                flexDirection: {
                    xs: 'column',
                    xl: 'row',
                }
            }}>
                <Box sx={{
                    opacity: lastChange === 'citizenFee' ? 0.5 : 'unset',
                    flexGrow: 1,
                }}>
                    <Typography variant="h5" component="h3" mb={1} textAlign="center">
                        Upravte doplatek
                    </Typography>
                    <Box px={6} maxWidth={500} mx="auto">
                        <Slider
                            aria-label="Úprava doplatku obce"
                            value={annualSurchargeValue != null ? Number.parseInt(annualSurchargeValue) : minMunicipalityCosts}
                            step={1000}
                            valueLabelDisplay="auto"
                            marks={annualSurchargeSliderMarks}
                            min={minMunicipalityCosts}
                            max={maxMunicipalityCosts}
                            onChange={handleAnnualSurchargeSlideChange}
                        />
                    </Box>
                    <Box pt={1.5} display="flex" alignItems="center" justifyContent="center">
                        <Box>
                            <NumberTextfield
                                label="Částka ročního doplatku (Kč)"
                                onChange={(e) => handleAnnualSurchargeChange(e)}
                                name='annualSurchargeValue'
                                value={annualSurchargeValue ?? minMunicipalityCosts.toString()}
                                thousandSeparator
                            />
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    order: {
                        xs: 2,
                        xl: 'unset'
                    }
                }}>
                    <Box>
                        <Button
                            variant="contained"
                            color="terciary"
                            startIcon={isLoading ? <CircularProgress color="inherit" size={20} /> : <CachedRoundedIcon />}
                            disabled={
                                (annualSurchargeValue === convertFromCents(scenario.annual_surcharge_cents).toFixed(0).toString() &&
                                citizenFeeValue === baseFee.toFixed(0)) ||
                                (!annualSurchargeValue && !citizenFeeValue) ||
                                annualSurchargeOutOfBounds ||
                                isLoading ||
                                citizenFeeOutOfBounds
                            }
                            onClick={() => {
                                handleRefetch()
                            }}
                        >
                            Přepočítat
                        </Button>
                    </Box>
                </Box>
                <Box sx={{
                    opacity: lastChange === 'annualSurcharge' ? 0.5 : 'unset',
                    flexGrow: 1,
                }}>
                    <Typography variant="h5" component="h3" mb={1} textAlign="center">
                        Upravte poplatek občanů
                    </Typography>
                    <Box px={6} maxWidth={500} mx="auto">
                        <Slider
                            aria-label="Úprava poplatku občanů"
                            value={Number.parseInt(citizenFeeValue)}
                            step={1}
                            valueLabelDisplay="auto"
                            marks={citizenFeeSliderMarks}
                            min={MIN_CITIZEN_FEE_CENTS+highestDiscount}
                            max={convertFromCents(MAX_CITIZEN_FEE_CENTS)}
                            onChange={handleCitizenFeeSlideChange}
                        />
                    </Box>
                    <Box pt={1.5} display="flex" gap={2} alignItems="center" justifyContent="center" flexWrap="wrap">
                        <Box>
                            <NumberTextfield
                                label="Poplatek bez slevy (Kč)"
                                onChange={(e) => handleCitizenFeeChange(e)}
                                name='citizenFeeValue'
                                value={citizenFeeValue}
                                thousandSeparator
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
            {annualSurchargeOutOfBounds &&
                <Typography variant="body2" component="div" sx={{
                    color: 'error.main',
                    textAlign: 'center',
                    pt: 2,
                }}>
                    Částka doplatku nesmí přesahovat minimum a maximum uvedené u posuvníku.
                </Typography>
            }
            {citizenFeeOutOfBounds &&
                <Typography variant="body2" component="div" sx={{
                    color: 'error.main',
                    textAlign: 'center',
                    pt: 2,
                }}>
                    Částka poplatku občana nesmí přesahovat minimum a maximum uvedené u posuvníku.
                </Typography>
            }
        </>
    )
}
