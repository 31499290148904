import { useState } from 'react'
import Icon from '@mui/material/Icon'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

export default function BottomButtons({
    handleFormReset,
    allowReset,
    disableSave = false,
    requestInProgress = false,
    isChangedOnServer,
    buttonText = 'Uložit',
    buttonIcon = 'done',
}: {
    handleFormReset: () => void,
    allowReset: boolean,
    disableSave?: boolean,
    requestInProgress?: boolean,
    isChangedOnServer?: boolean,
    buttonText?: string,
    buttonIcon?: string,
}) {

    const [resetConfirmation, setResetConfirmation] = useState<boolean>(false)

    return (
        <>
            {!resetConfirmation &&
                <Box
                    display="flex"
                    justifyContent="center"
                    mt={2}
                    flexWrap="wrap"
                >
                    <Box ml={1} mr={1}>
                        <Button
                            color="terciary"
                            startIcon={<Icon>delete</Icon>}
                            onClick={() => setResetConfirmation(true)}
                            disabled={!allowReset}
                        >
                            Zrušit změny
                        </Button>
                    </Box>
                    {!isChangedOnServer &&
                        <Box ml={1} mr={1}>
                            <Button
                                variant="contained"
                                color={isChangedOnServer ? 'error' : 'terciary'}
                                startIcon={requestInProgress ?
                                    <CircularProgress color="inherit" size={20} />
                                    :
                                    <Icon>{isChangedOnServer ? 'publish' : buttonIcon}</Icon>
                                }
                                type="submit"
                                disabled={(disableSave || requestInProgress)}
                            >
                                {buttonText}
                            </Button>
                        </Box>
                    }
                </Box>
            }
            {resetConfirmation &&
                <Box>
                    <Box mt={1} textAlign="center">
                        <Typography
                            variant="body1"
                            component="div"
                        >
                            Potvrdit vymazání formuláře:
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="center"
                        mt={1}
                    >
                        <Box ml={1} mr={1}>
                            <Button
                                color="primary"
                                startIcon={<Icon>cancel</Icon>}
                                onClick={() => setResetConfirmation(false)}
                            >
                                Zrušit
                            </Button>
                        </Box>
                        <Box ml={1} mr={1}>
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<Icon>delete</Icon>}
                                onClick={() => {
                                    handleFormReset()
                                    setResetConfirmation(false)
                                }}
                            >
                                Potvrdit
                            </Button>
                        </Box>
                    </Box>
                </Box>
            }
        </>
    )
}
