import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import { IconButton } from '@mui/material'
import dayjs from "dayjs"

import { Heading } from '@/components/heading'
import { FONT_WEIGHT } from '@/constants/font'
import { DetailTable, DetailTableCell, DetailTableHeader, DetailTableRow } from './detail-table'
import { TypeTag } from '@/components/type-tag'
import { MaterialTypes, type CollectionPointDetail } from '@/types'
import {
    WASTE_TYPE_ID_COLOR_MAP,
    WASTE_TYPE_ID_LABEL_MAP,
} from '@/constants/general'

export default function CollectionPointDetailBins({
    bins,
}: {
    bins: CollectionPointDetail["bins"]
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                gap: '1rem',
                flexDirection: 'column',
            }}
        >
            <Heading icon={<DeleteRoundedIcon />}>Nádoby</Heading>
            <Table bins={bins}/>
        </Box>
    )
}

function Table({
    bins,
}: {
    bins: CollectionPointDetail["bins"]
}) {
    type ColumnKey = 'id' | 'rfid' | 'type' | 'volume' | 'material' | 'date'

    const columnWidths: { [key in ColumnKey]: number } = {
        id: 1,
        rfid: 1,
        type: 1,
        volume: 0.5,
        material: 1,
        date: 1.5,
    }

    const columns: ColumnKey[] = ['rfid', 'type', 'volume', 'material', 'date']

    const columnNames: { [key in ColumnKey]: string } = {
        date: 'Datum posledního výsypu',
        id: 'Id',
        rfid: 'Kód',
        material: 'Materiál nádoby',
        type: 'Druh odpadu',
        volume: 'Objem',
    }

    const BIN_MATERIAL_LABEL_MAP = {
        [MaterialTypes.plastic]: 'Plast',
        [MaterialTypes.metal]: 'Plech',
        [MaterialTypes.fiberglass]: 'Sklolaminát',
    }

    const data = bins.map(bin => ({
        id: bin.id,
        rfid: bin.bin_code?.visible_code ?? '-',
        type: {
            label: WASTE_TYPE_ID_LABEL_MAP[bin.bin_type.waste_type.id].short,
            color: WASTE_TYPE_ID_COLOR_MAP[bin.bin_type.waste_type.id].main,
        },
        volume: bin.bin_type.volume+'\xa0l',
        material: BIN_MATERIAL_LABEL_MAP[bin.bin_type.material],
        date: bin.last_bin_weighting?.time_unit ? dayjs(bin.last_bin_weighting.time_unit).format('D. M. YYYY hh:mm') : '-',
    }))

    const TABULAR_VERSION = 'md'
    const EDIT_BUTTON_WIDTH = '2.5rem' // 40px
    const COLUMNAR_LAYOUT_SIZES = {
        typeWidth: '80%',
        editButtonWidth: '20%',
    }

    return (
        <DetailTable>
            <DetailTableHeader
                sx={{
                    display: {
                        xs: 'none',
                        [TABULAR_VERSION]: 'flex',
                    },
                }}
            >
                {columns.map((columnName, index) => (
                    <DetailTableCell
                        key={index}
                        sx={{
                            width: {
                                xs: undefined,
                                [TABULAR_VERSION]: `${columnWidths[columnName] * 100}%`, //  proportional size of cells
                            },
                        }}
                    >
                        <Typography
                            key={index}
                            component="div"
                            variant="h5"
                            color="customGrey.tableText"
                        >
                            {columnNames[columnName]}
                        </Typography>
                    </DetailTableCell>
                ))}

                {/* to make header aligned with cells, we need to also add edit button's width */}
                <Box
                    sx={{
                        width: EDIT_BUTTON_WIDTH,
                        // force button to take horizontal space
                        height: '1px',
                        flexShrink: 0,
                    }}
                />
            </DetailTableHeader>
            {data.map(row => (
                <DetailTableRow
                    // @todo: after columnar layout is finished/debugged consider making columnar layout a flag to Table component

                    key={row.id}
                    sx={{
                        padding: {
                            xs: '.5rem .75rem 1.5rem',
                            [TABULAR_VERSION]: '0rem 0.5rem 0rem 1.5rem',
                        },
                        flexWrap: {
                            xs: 'wrap',
                            [TABULAR_VERSION]: 'nowrap',
                        },
                        flexDirection: {
                            xs: 'row',
                            [TABULAR_VERSION]: 'row',
                        },
                        rowGap: 1,
                        columnGap: {
                            xs: 0,
                            [TABULAR_VERSION]: '1.25rem',
                        },
                    }}
                >
                    {columns.map((columnName, index) => (
                        <DetailTableCell
                            key={index}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: {
                                    xs:
                                        columnName === 'type'
                                            ? COLUMNAR_LAYOUT_SIZES.typeWidth
                                            : '50%',
                                    [TABULAR_VERSION]: `${columnWidths[columnName] * 100}%`, //  proportional size of cells
                                },
                                order: {
                                    xs: columnName === 'type' ? -2 : 0,
                                    [TABULAR_VERSION]: 0,
                                },
                            }}
                        >
                            {/* @TODO-MC: Get rid of the pseudoelement, use typography */}
                            <Box
                                sx={{
                                    '&::before': {
                                        content: `'${columnNames[columnName]}'`,
                                        fontSize: '1rem',
                                        color: 'customGrey.tableText',
                                        fontWeight: FONT_WEIGHT.medium,
                                        display: {
                                            xs: columnName === 'type' ? 'none' : 'block',
                                            [TABULAR_VERSION]: 'none',
                                        },
                                        padding: '0 0 .25rem 0',
                                        minWidth: '3.5rem',
                                        textAlign: columnName === 'type' ? 'center' : 'left',
                                    },
                                }}
                            >
                                {columnName === 'type' ?
                                    <TypeTag
                                        name={row.type.label}
                                        color={row.type.color}
                                        sx={{
                                            minWidth: '5.25rem',
                                            textAlign: {
                                                xs: 'center',
                                                [TABULAR_VERSION]: 'left',
                                            },
                                        }}
                                    />
                                : 
                                    <Box
                                        sx={{ color: 'customGrey.tableText' }}
                                    >
                                       {row[columnName]}
                                    </Box>
                                }
                            </Box>
                        </DetailTableCell>
                    ))}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'center',
                            order: {
                                xs: -1,
                                [TABULAR_VERSION]: 0,
                            },
                            width: {
                                xs: COLUMNAR_LAYOUT_SIZES.editButtonWidth,
                                [TABULAR_VERSION]: 'auto',
                            },
                        }}
                    >
                        <Box>
                            <IconButton
                                disabled
                                onClick={() => {
                                    console.log('clicked') // @todo
                                }}
                                color="terciary"
                            >
                                <MoreVertOutlinedIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </DetailTableRow>
            ))}
        </DetailTable>
    )
}
