import Typography from '@mui/material/Typography'
import { Helmet } from 'react-helmet-async'

import CustomPaper from '@/components/custom-paper'
import MunicipalitySortingTrendWrapper from '@/components/municipality-sorting-trend/wrapper'
import MunicipalitiesLoader from '@/wrappers/municipalities-loader'

function MunicipalitySortingTrendView() {

    const title = 'Trend separace v obci'

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <CustomPaper>
                <Typography variant="h2" component="h1" gutterBottom>
                    {title}
                </Typography>
                <MunicipalitiesLoader>
                    {municipalities => (
                        <MunicipalitySortingTrendWrapper municipalities={municipalities} />
                    )}
                </MunicipalitiesLoader>
            </CustomPaper>
        </>
    )
}

export default MunicipalitySortingTrendView
