import type { ReactNode } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export default function HeadingWithIcon({
    text,
    icon,
}: {
    text: string,
    icon: ReactNode,
}) {

    return (
        <Box display="flex" alignItems="center" gap={1} sx={{ color: 'terciary.main' }}>
            {icon}
            <Typography variant="h4" component="h3">
                {text}
            </Typography>
        </Box>
    )
}
