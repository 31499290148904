import { useState } from 'react'
import Box from "@mui/material/Box"
import { PersonBox, PersonDetails, PersonValue } from '@/components/person-box'
import type { Contact } from "@/types"
import ContactForm from "@/components/forms/contact"
import ContactCardActions from '@/components/contact/card-actions'

export default function ContactCard({
    contact,
    editMode,
}: {
    contact: Contact,
    editMode: boolean,
}) {

    const {
        name,
        email,
        phone_number,
        description,
    } = contact

    const [edit, setEdit] = useState<boolean>(false)

    return (
        <>
            {edit ?
                <ContactForm
                    contact={contact}
                    closeCallback={() => setEdit(false)}
                />
                :
                <Box minWidth={270} position="relative">
                    <PersonBox fullName={name}>
                        <PersonDetails>
                            {description &&
                                <Box sx={{
                                    color: 'customGrey.tableText',
                                    mb: 1,
                                }}>
                                    {description}
                                </Box>
                            }
                            {email &&
                                <PersonValue type="email" value={email} />
                            }
                            {phone_number &&
                                <PersonValue type="phone" value={phone_number} />
                            }
                        </PersonDetails>
                    </PersonBox>
                    {editMode &&
                        <Box sx={{
                            position: 'absolute',
                            top: 4,
                            right: 4,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            borderRadius: '1rem',
                        }}>
                            <ContactCardActions contact={contact} setEdit={setEdit} />
                        </Box>
                    }
                </Box>
            }
        </>
    )
}
