import axios from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'

import type {
    FormMutationOptions,
    ApplicationInstruction,
    ApplicationInstructionFormInput,
} from '@/types'

export function useApplicationInstruction(code) {
    return useQuery({
        queryKey: ['applicationInstruction', code],
        queryFn: async () => {
            const { data } : { data: ApplicationInstruction } = await axios.get(
                '/api/application_instruction/single?code=' + code,
            )
            return data
        },
    })
}

export const useCreateApplicationInstruction = (options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: ApplicationInstructionFormInput) => {            
            return axios.post(`/api/application_instruction/create?code=${data.code}`,
                {description: data.description}
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['applicationInstruction', response.data.code] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useUpdateApplicationInstruction = (code, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: ApplicationInstructionFormInput) => {
            return axios.put(`/api/application_instruction/update?code=${code}`,
                {description: data.description}
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['applicationInstruction', code] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}
