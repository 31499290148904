import { useAtom } from 'jotai'
import dayjs from 'dayjs'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import { Link } from 'react-router-dom'

import ErrorBox from '@/components/error-box'
import LoadingBox from '@/components/loading-box'
import { selectedMunicipalityIdAtom } from '@/state'
import { useCollectedWasteStats } from '@/hooks/api/use-collected-waste-stat'
import { ROUTE_SEGMENTS } from '@/constants/general'
import type { MonthYear } from '@/types'

export default function CollectionYardsNotification() {

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    const endDate = dayjs().subtract(1, 'month').format('YYYY-MM') as MonthYear
    const startDate = dayjs().subtract(1, 'year').format('YYYY-MM') as MonthYear
    
    const { status, data, error } = useCollectedWasteStats(municipalityId, startDate, endDate)

    if (status === 'pending') {
        return <LoadingBox />
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst statistiku sběrných dvorů a ostatních míst obce s ID ${municipalityId}`}
        />
    }

    if (data.length < 24) {
        return (
            <Box mt={1.5}>
                <Alert
                    severity="error"
                    sx={(theme) => ({
                        flexGrow: 1,
                        margin: theme.spacing(2, 0),
                    })}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        Ve zvoleném období {data.length === 0 ? 'nemáte vyplněnou žádnou' : 'máte jen částečně vyplněnou'} statistiku produkce odpadu ve sběrných dvorech a na ostatních místech.
                        Statistiku můžete doplnit v záložce 'Produkce odpadu' ve Správě obce nebo kliknutím na tlačítko níže.
                        <Box display="flex" justifyContent="center" pr={4} pt={2}>
                            <Button
                                variant="contained"
                                color="error"
                                size="small"
                                component={Link}
                                to={`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipalityManagement}/${ROUTE_SEGMENTS.collectedWasteStats}`}
                            >
                                Vyplnit statistiku
                            </Button>
                        </Box>
                    </Box>
                </Alert>
            </Box>
        )
    }

    return null
}
