import { useState } from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import SendRoundedIcon from '@mui/icons-material/SendRounded'

import { useSendMessageToAuthority } from '@/hooks/api/use-communication'
import Textarea from '../form-fields/textarea'
import { Typography } from '@mui/material'

function CommunicationThreadMessageSend({
    communicationId,
}: {
    communicationId: number,
}) {

    const [error, setError] = useState<string | null>(null)
    const [requestInProgress, setRequestInProgress] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')

    const handleChange = ({
        target: {
            value,
        },
    }) => {
        setMessage(value)
    }

    const mutation = useSendMessageToAuthority(
        communicationId,
        {
            setRequestInProgress,
            onErrorCallback(errorMessage) {
                setError(errorMessage)
            },
            onSuccessCallback() {
                setMessage('')
            }
        },
    )

    const handleSend = () => {
        setRequestInProgress(true)
        setError(null)
        mutation.mutate(message)
    }

    return (
        <>
            <Box pt={1.5} display="flex" gap={2} alignItems="flex-end" justifyContent="center" flexWrap="wrap">
                <Textarea
                    onChange={handleChange}
                    value={message}
                    placeholder="Napište zprávu..."
                    minRows={1}
                />
                <Box py={1}>
                    <IconButton
                        color="primary"
                        disabled={!message}
                        onClick={() => handleSend()}
                    >
                        {requestInProgress ?
                            <CircularProgress color="inherit" size={20} /> :
                            <SendRoundedIcon />
                        }
                    </IconButton>
                </Box>
            </Box>
            {error &&
                <Box sx={{
                    backgroundColor: 'errorRedLight.light',
                    p: 1.5,
                    borderRadius: 2.5,
                }}>
                    <Typography variant="body2" component="div" color="error">
                        {error}
                    </Typography>
                </Box>
            }
        </>
    )
}

export default CommunicationThreadMessageSend
