import Alert from '@mui/material/Alert'

export default function FormErrorAlert({
    error,
    setError
}: {
    error: string,
    setError?: (arg1: null) => void
}) {

    return (
        <Alert
            severity="error"
            sx={(theme) => ({
                flexGrow: 1,
                margin: theme.spacing(1, 0),
            })}
            onClose={() => setError(null)}
        >
            {error}
        </Alert>
    )
}
