import axios from 'axios'
import {
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'

import type {
    Endpoints,
    DirectUpload,
} from '@/types'

export const useDirectUpload = (
    endpoint: Endpoints,
    invalidateQueryKey?: Array<string | number>
) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (
            file: DirectUpload,
        ) => {            
            return axios.post(`/api/${endpoint}/direct_upload`,
                file
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: invalidateQueryKey })
        },
    })
}

export const useDeleteDocument = (
    entityId: number,
    documentId: number,
    endpoint: Endpoints,
    invalidateQueryKey?: Array<string | number>
) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => {            
            return axios.delete(`/api/${endpoint}/delete_document?id=${entityId
                }&document_id=${documentId}`
            )
            
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: invalidateQueryKey })
        },
    })
}
