import { useState } from 'react'
import { useAtom } from 'jotai'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import CachedRoundedIcon from '@mui/icons-material/CachedRounded'
import CircularProgress from '@mui/material/CircularProgress'

import { selectedMunicipalityIdAtom } from "@/state"
import { useRefreshHouseholdStats } from '@/hooks/api/use-municipality'
import { Typography } from '@mui/material'

function HouseholdRefresh() {

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    const [requestInProgress, setRequestInProgress] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)

    const mutation = useRefreshHouseholdStats(
        {
            setRequestInProgress,
            onSuccessCallback(data) {
                if(data) {
                    setSuccess(true)
                }
            }
        },
    )

    const handleClickRefresh = () => {
        setRequestInProgress(true)
        mutation.mutate({ municipalityId })
    }

    if (requestInProgress) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                flexGrow: 1,
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <CircularProgress />
                    <Typography variant='h6' component="div" mt={2}>
                        Přepočítávám statistiky
                    </Typography>
                    <Typography variant='body2' component="div">
                        Pro velké obce může tato operace trvat i několik minut.
                    </Typography>
                </Box>
            </Box>
        )
    }

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            flexGrow: 1,
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                {success ?
                    <Box px={3}>
                        <Alert
                            severity="success"
                            sx={(theme) => ({
                                flexGrow: 1,
                                margin: theme.spacing(1, 0),
                            })}
                            action={
                                <Button
                                    color="success"
                                    size="small"
                                    onClick={() => setSuccess(false)}
                                >
                                    Zavřít
                                </Button>
                            }
                        >
                            Statistiky úspěšně přepočítány.
                        </Alert>
                    </Box>
                    :
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={requestInProgress ? <CircularProgress color="inherit" size={20} /> : <CachedRoundedIcon />}
                        onClick={() => handleClickRefresh()}
                        disabled={requestInProgress}
                    >
                        Přepočítat statistiky
                    </Button>
                }
            </Box>
        </Box>
    )
}

export default HouseholdRefresh
