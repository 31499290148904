import type { Dispatch, SetStateAction } from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'

import type { Pagination } from '@/types'

export default function PaginationControls({
    pagination,
    setPagination,
    currentArrayLength,
    recordsCount,
    disabled,
}: {
    pagination: Pagination,
    setPagination: Dispatch<SetStateAction<Pagination>>
    currentArrayLength: number,
    recordsCount?: number,
    disabled?: boolean,
}) {

    /**
     * Fetches next page
     */
    const handleClickNextPage = () => {
        setPagination({
            limit: pagination.limit,
            offset: pagination.offset+pagination.limit,
        })
    }
    /**
     * Fetches previous page
     */
    const handleClickPreviousPage = () => {
        setPagination({
            limit: pagination.limit,
            offset: pagination.offset-pagination.limit,
        })
    }

    return (
        <Box sx={{
            marginTop: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
        }}>
            <IconButton
                disabled={pagination.offset === 0}
                onClick={() => handleClickPreviousPage()}
                size="small"
                sx={{
                    borderRadius: '2rem',
                }}
            >
                <ChevronLeftRoundedIcon />
            </IconButton>
            {`${pagination.offset+1} - ${pagination.offset+currentArrayLength}`}
            {recordsCount ? ` (${recordsCount})` : null}
            <IconButton
                disabled={(recordsCount ?
                    recordsCount <= pagination.offset+pagination.limit :
                    currentArrayLength < pagination.limit) ||
                    disabled
                }
                onClick={() => handleClickNextPage()}
                size="small"
                sx={{
                    borderRadius: '2rem',
                }}
            >
                <ChevronRightRoundedIcon />
            </IconButton>
        </Box>
    )
}
