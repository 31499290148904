import { useState } from 'react'
import { useAtom } from 'jotai'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import AddRoundedIcon from '@mui/icons-material/AddRounded'

import RichText from '@/components/rich-text'
import HowToPayLessForm from '@/components/forms/how-to-pay-less'
import { selectedMunicipalityIdAtom } from "@/state"
import { usePayLessInstructions } from '@/hooks/api/use-pay-less-instruction'
import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"
import HowToPayLessActions from '@/components/how-to-pay-less/actions'
import { Endpoints, UserRole } from '@/types'
import DocumentsList from '@/components/documents-list'
import PermissionsGate from '@/components/permissions-gate'

function HowToPayLessList() {

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    const [create, setCreate] = useState<boolean>(false)
    const [edit, setEdit] = useState<boolean>(false)

    const { status, data, error } = usePayLessInstructions(municipalityId)

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst obsah sekce -Jak platit méně- obce s ID ${municipalityId}`}
        />
    }

    if (data.length === 0) {
        return (
            <Box pt={2}>
                {create ?
                    <HowToPayLessForm
                        closeCallback={() => setCreate(false)}
                    />
                    :
                    <Box>
                        <Typography variant="body1" component="p" mb={2}>
                            Tuto sekci jste zatím nevytvořili. Přejete si ji vytvořit?
                        </Typography>
                        <Button
                            variant="contained"
                            color="terciary"
                            startIcon={<AddRoundedIcon />}
                            onClick={() => setCreate(true)}
                        >
                            Vytvořit sekci
                        </Button>
                    </Box>
                }
            </Box>
        )
    }

    const payLessInstruction = data[0]

    return (
        <Box pt={2}>
            {edit ?
                <HowToPayLessForm
                    payLessInstruction={payLessInstruction}
                    closeCallback={() => setEdit(false)}
                />
                :
                <>
                    <RichText content={payLessInstruction.description} />
                    <PermissionsGate
                        userRoles={[
                            UserRole.admin,
                            UserRole.municipalityOfficial,
                        ]}
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: -8,
                            right: -8,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            borderRadius: '1rem',
                        }}>
                            <HowToPayLessActions
                                payLessInstruction={payLessInstruction}
                                setEdit={setEdit}
                            />
                        </Box>
                    </PermissionsGate>
                </>
            }
            <Box pt={4} />
            <DocumentsList
                documents={payLessInstruction.documents}
                endpoint={Endpoints.payLessInstruction}
                entityId={payLessInstruction.id}
                invalidateQueryKey={['payLessInstructions', municipalityId]}
            />
        </Box>
    )
}

export default HowToPayLessList
