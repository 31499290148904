import { useState } from 'react'
import { useAtom } from 'jotai'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import dayjs from 'dayjs'
import { useNavigate } from "react-router-dom"
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import IconButton from '@mui/material/IconButton'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'
import DoNotDisturbAltRoundedIcon from '@mui/icons-material/DoNotDisturbAltRounded'
import Tooltip from '@mui/material/Tooltip'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import 'leaflet/dist/leaflet.css'
import { AttributionControl, MapContainer, TileLayer } from 'react-leaflet'

import { useIllegalDumps } from '@/hooks/api/use-illegal-dump'
import { selectedMunicipalityIdAtom } from "@/state"
import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"
import {
    type IllegalDumpPublicList,
    ReportStates,
    type IllegalDumpListFilters,
    type Pagination,
} from '@/types'
import PaginationControls from '@/components/pagination-controls'
import IllegalDumpsActions from './actions'
import StateLabel from '@/components/state-label'
import IllegalDumpInProgressForm from '@/components/forms/illegal-dump-in-progress'
import IllegalDumpResolvedForm from '@/components/forms/illegal-dump-resolved'
import ArchiveDialog from './archive-dialog'
import CustomDumpMarker from '@/components/illegal-dumps/custom-dump-marker'
import type { LatLngBoundsExpression } from 'leaflet'
import { DEFAULT_BOUNDS } from '@/constants/general'

function IllegalDumpList() {

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    const navigate = useNavigate()

    const handleClick = (_event: React.MouseEvent<unknown>, id: number) => {
        navigate(`${id}`) 
    }

    const handleMarkerClick = (id: number) => {
        navigate(`${id}`) 
    }

    const [selectedDumpId, setSelectedDumpId] = useState<number | null>(null)
    const [hoverDumpId, setHoverDumpId] = useState<number | null>(null)

    // Pagination
    const [pagination, setPagination] = useState<Pagination>({
        limit: 10,
        offset: 0,
    })

    const handleResetPagination = () => {
        setPagination({
            limit: 20,
            offset: 0,
        })
    }

    // Filters
    const initialFilters: IllegalDumpListFilters = {
        sort_by: 'created_at',
        sort_direction: 'DESC',
        state: '',
    }

    const [filters, setFilters] = useState<IllegalDumpListFilters>(initialFilters)

    const handleResetFilters = () => {
        setFilters(initialFilters)
    }

    const handleChange = (event: SelectChangeEvent) => {
        setFilters({
            ...filters,
            [event.target.name]: event.target.value as string,
        })
        handleResetPagination()
    }

    // Action dialogs
    const [stateInProgress, setStateInProgress] = useState<IllegalDumpPublicList | null>(null)
    const [stateResolved, setStateResolved] = useState<IllegalDumpPublicList | null>(null)
    const [archive, setArchive] = useState<IllegalDumpPublicList | null>(null)
    
    const { status, data, error } = useIllegalDumps(municipalityId, pagination, filters)

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst seznam hlášení černých skládek obce s ID ${municipalityId}`}
        />
    }

    const reportsWithCoordinates =
        data.illegal_dump ?
            data.illegal_dump?.filter(item => item.latitude && item.longitude)
            : []

    const bounds: LatLngBoundsExpression = reportsWithCoordinates.length > 0 ?
        reportsWithCoordinates.map(report => ([Number.parseFloat(report.latitude), Number.parseFloat(report.longitude)])) :
        DEFAULT_BOUNDS

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
            overflowX: 'auto'
        }}>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                px: 2.5,
                gap: 1.5,
                pb: 2,
                pt: 1,
                alignItems: 'center',
            }}>
                <FilterAltRoundedIcon />
                <Box sx={{ minWidth: 140 }}>
                    <FormControl fullWidth>
                        <InputLabel id="state-select-label" size="small">Stav</InputLabel>
                        <Select
                            labelId="state-select-label"
                            id="state-select"
                            value={filters.state}
                            name="state"
                            label="Stav"
                            onChange={handleChange}
                            size="small"
                        >
                            <MenuItem value={''}>-</MenuItem>
                            <MenuItem value={ReportStates.sent}>Nahlášeno</MenuItem>
                            <MenuItem value={ReportStates.inProgress}>V řešení</MenuItem>
                            <MenuItem value={ReportStates.resolved}>Vyřešeno</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ minWidth: 140 }}>
                    <FormControl fullWidth>
                        <InputLabel id="sort_by-select-label" size="small">Řadit dle</InputLabel>
                        <Select
                            labelId="sort_by-select-label"
                            id="sort_by-select"
                            value={filters.sort_by}
                            name="sort_by"
                            label="Řadit dle"
                            onChange={handleChange}
                            size="small"
                        >
                            <MenuItem value={'created_at'}>První nahlášení</MenuItem>
                            <MenuItem value={'reports_count'}>Počet nahlášení</MenuItem>
                            <MenuItem value={'resolved_at'}>Datum vyřešení</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ minWidth: 140 }}>
                    <FormControl fullWidth>
                        <InputLabel id="sort_direction-select-label" size="small">Směr řazení</InputLabel>
                        <Select
                            labelId="sort_direction-select-label"
                            id="sort_direction-select"
                            value={filters.sort_direction}
                            name="sort_direction"
                            label="Směr řazení"
                            onChange={handleChange}
                            size="small"
                        >
                            <MenuItem value={'DESC'}>Sestupně</MenuItem>
                            <MenuItem value={'ASC'}>Vzestupně</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Tooltip title="Resetovat filtry">
                    <IconButton
                        aria-label="Resetovat filtry"
                        onClick={() => handleResetFilters()}
                        size="small"
                    >
                        <DoNotDisturbAltRoundedIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: {
                    xs: 'column',
                    xl: 'row',
                }
            }}>
                <Box sx={{
                    flexBasis: {
                        xs: '100%',
                        xl: '65%',
                    },
                    pr: {
                        xs: 0,
                        xl: 3,
                    },
                    overflowX: 'auto',
                }}>
                    {data.records_count === 0 ?
                        <Box px={2.5}>
                            Zatím nebyly nahlášeny žádné černé skládky
                        </Box>
                    :
                        <Box>
                            <Table
                                size="small"
                                aria-label="Seznam hlášení černých skládek"
                                sx={{
                                    mt: 1,
                                    minWidth: 600,
                                }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ paddingLeft: 3 }}>Stav</TableCell>
                                        <TableCell>První nahlášení</TableCell>
                                        <TableCell>Počet nahlášení</TableCell>
                                        <TableCell>Lokalita</TableCell>
                                        <TableCell>Datum vyřešení</TableCell>
                                        <TableCell align="right"/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.illegal_dump.map(row => (
                                        <TableRow
                                            key={row.id}
                                            sx={{
                                                cursor: 'pointer',
                                                '&:last-child td, &:last-child th': { border: 0 },
                                                ...(row.id === hoverDumpId ? {
                                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                                } : null),
                                            }}
                                            onClick={(event) => handleClick(event, row.id)}
                                            onMouseEnter={() => setHoverDumpId(row.id)}
                                            onMouseLeave={() => setHoverDumpId(null)}
                                        >
                                            <TableCell component="th" scope="row" sx={{ paddingLeft: 3 }}>
                                                <StateLabel state={row.state} />
                                            </TableCell>
                                            <TableCell>
                                                {dayjs(row.created_at).format('D. M. YYYY HH:mm')}
                                            </TableCell>
                                            <TableCell>
                                                {row.illegal_dumping_reports.length}
                                            </TableCell>
                                            <TableCell>
                                                <Box>
                                                    <Box>{row.address}</Box>
                                                    <Box color="customGrey.secondaryText">{row.latitude} {row.longitude}</Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                {row.resolved_at ? dayjs(row.resolved_at).format('D. M. YYYY HH:mm') : '-'}
                                            </TableCell>
                                            <TableCell align="right">
                                                <IllegalDumpsActions
                                                    illegalDump={row}
                                                    setStateInProgress={setStateInProgress}
                                                    setStateResolved={setStateResolved}
                                                    setArchive={setArchive}
                                                    allowDetail
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <PaginationControls
                                pagination={pagination}
                                setPagination={setPagination}
                                currentArrayLength={data.illegal_dump.length}
                                recordsCount={data.records_count}
                            />
                        </Box>
                    }
                </Box>
                <Box sx={(theme) => ({
                    flexBasis: {
                        xs: '100%',
                        xl: '35%',
                    },
                    pl: {
                        xs: 2,
                        xl: 0,
                    },
                    pr: 2,
                    pt: {
                        xs: 3,
                        xl: 0,
                    },
                    borderTop: {
                        xs: `1px solid ${theme.palette.customGrey.divider}`,
                        xl: '0px',
                    },
                })}>
                    <Box sx={{
                        borderRadius: 2.5,
                        overflow: 'hidden',
                        width: '100%',
                        height: {
                            xs: '50vh',
                            xl: 'calc(100vh - 238px)',
                        },
                        '& .leaflet-container': {
                            width: '100%',
                            height: {
                                xs: '50vh',
                                xl: 'calc(100vh - 238px)',
                            },
                        },
                    }}>
                        <MapContainer
                            key={municipalityId}
                            bounds={bounds}
                            boundsOptions={{padding: [50, 50]}}
                            scrollWheelZoom={true}
                            attributionControl={false}
                        >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <AttributionControl position="bottomright" prefix={false} />
                            {reportsWithCoordinates.map((marker, index) => (
                                <CustomDumpMarker
                                    key={index}
                                    marker={marker}
                                    selectedId={hoverDumpId}
                                    setSelectedId={handleMarkerClick}
                                />
                            ))}
                        </MapContainer>
                    </Box>
                </Box>
            </Box>
            <Dialog
                key={'stateInProgress-'+stateInProgress?.id}
                open={!!stateInProgress}
                onClose={() => setStateInProgress(null)}
            >
                <DialogContent>
                    <IllegalDumpInProgressForm
                        illegalDump={stateInProgress}
                        closeCallback={() => setStateInProgress(null)}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                key={'stateResolved-'+stateResolved?.id}
                open={!!stateResolved}
                onClose={() => setStateResolved(null)}
            >
                <DialogContent>
                    <IllegalDumpResolvedForm
                        illegalDump={stateResolved}
                        closeCallback={() => setStateResolved(null)}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                key={'archive-'+archive?.id}
                open={!!archive}
                onClose={() => setArchive(null)}
            >
                <DialogContent>
                    <ArchiveDialog
                        illegalDumpId={archive?.id}
                        handleClose={() => setArchive(null)}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default IllegalDumpList
