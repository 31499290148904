import React, { type ReactNode } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FONT_WEIGHT } from '@/constants/font'

export function Heading({
    icon,
    children,
    fontSize = '18px',
    color = '#222222',
    component,
}: {
    icon?: ReactNode
    color?: '#818181' | '#222222'
    fontSize?: string
    component?: string // @todo It would be nice to have this typing ... React.ComponentType<typeof Typography>['component'] doesn't work !!!
    children: ReactNode
}) {
    return (
        <Typography
            component={component || 'div'}
            sx={{
                color: color,
                display: 'flex',
                flexDirection: 'row',
                //gap: '.5rem',
                gap: '.75rem',
                alignItems: 'center',
                fontSize: fontSize,
                fontWeight: FONT_WEIGHT.medium,
            }}
        >
            {icon && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '1.5rem',
                        '& > svg': {
                            fontSize: '1.75rem', // override default svg icon size
                        },
                        aspectRatio: 1,
                    }}
                >
                    {icon}
                </Box>
            )}
            <Box>{children}</Box>
        </Typography>
    )
}
