import type { ReactElement } from 'react'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import ReportRoundedIcon from '@mui/icons-material/ReportRounded'
import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'

import { ReportStates } from '@/types'

export default function StateLabel({
    state,
    chip = true,
}: {
    state: ReportStates,
    chip?: boolean,
}) {

    const states: {
        [key in ReportStates]: {
            icon: ReactElement,
            text: string,
            color: 'state.reported' | 'state.inProgress' | 'state.resolved',
        }
    } = {
        [ReportStates.sent]: {
            icon: <ReportRoundedIcon sx={{ fill: chip ? 'white' : 'state.reported' }} />,
            text: 'Nahlášeno',
            color: 'state.reported'
        },
        [ReportStates.inProgress]: {
            icon: <HourglassBottomRoundedIcon sx={{ fill: chip ? 'white' : 'state.inProgress' }} />,
            text: 'V řešení',
            color: 'state.inProgress'
        },
        [ReportStates.resolved]: {
            icon: <CheckCircleRoundedIcon sx={{ fill: chip ? 'white' : 'state.resolved' }}/>,
            text: 'Vyřešeno',
            color: 'state.resolved'
        },
    }

    if (chip) return (
        <Chip
            icon={states[state].icon}
            label={states[state].text}
            size='small'
            sx={{
                backgroundColor: states[state].color,
                color: 'white',
            }}
        />
    )
    
    return (
        <Box display="flex" alignItems="center" gap={1} sx={{ color: 'terciary.main' }}>
            {states[state].icon}
            <Typography variant="body2" component="span">
                {states[state].text}
            </Typography>
        </Box>
    )
}
