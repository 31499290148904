import type { KeyboardEvent } from 'react'
import { Controller, type Control, type Path, type FieldValues } from "react-hook-form"
import TextField, { type BaseTextFieldProps } from "@mui/material/TextField"

interface RHFAutocompleteProps<
    TField extends FieldValues,
> {
    control: Control<TField>,
    name: Path<TField>,
    label?: string,
    size?: BaseTextFieldProps["size"],
    required?: BaseTextFieldProps["required"],
    autoFocus?: BaseTextFieldProps["autoFocus"],
    disabled?: BaseTextFieldProps["disabled"],
    type?: 'email' | 'tel' | 'url',
}

const RHFTextfield = <
    TField extends FieldValues,
>(
    props: RHFAutocompleteProps<TField>
) => {

    const {
        control,
        name,
        label,
        size,
        required,
        autoFocus,
        type,
        disabled,
    } = props

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => {
                return (
                    <TextField
                        id="rhf-textfield"
                        label={label}
                        size={size}
                        required={required}
                        autoFocus={autoFocus}
                        disabled={disabled}
                        type={type}
                        autoComplete="off"
                        onKeyDown={
                            (e:KeyboardEvent<HTMLInputElement>) => {
                                if (!(e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                                    e.preventDefault()
                                }
                            }
                        }
                        {...field}
                    />
                )
            }}
        />
    )
}

export default RHFTextfield
