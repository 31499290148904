import React from 'react'
import {
    useRouteError,
    isRouteErrorResponse,
} from 'react-router-dom'

export default function ErrorView() {

    const error = useRouteError()
    console.error(error)

    if (!isRouteErrorResponse(error)) {
        return (
            <div id="error-page">
                <h1>Oops!</h1>
                <p>An unexpected error has occurred, there is more information in the developer console.</p>
            </div>
        )
    }

    return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p>Sorry, an error has occurred:</p>
            <p>
                {error.status &&
                    <i>{error.status} - </i>
                }
                <i>{error.statusText || error.data.message}</i>
            </p>
        </div>
    )
}
