import axios from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import { useAtom } from "jotai"

import type {
    FormMutationOptions,
    CollectionYardFormInput,
    CollectionYard,
} from '@/types'
import { selectedMunicipalityIdAtom } from "@/state"

export function useCollectionYards(
    municipalityId: number,
) {
    return useQuery({
        queryKey: ['collectionYards', municipalityId],
        queryFn: async () => {
            const { data } : { data: Array<CollectionYard>} = await axios.get(
                `/api/collection_yard/list?municipality_id=${municipalityId}`
            )
            return data
        },
    })
}

export function useCollectionYard(collectionYardId) {
    return useQuery({
        queryKey: ['collectionYard', collectionYardId],
        queryFn: async () => {
            const { data } : { data: CollectionYard } = await axios.get(
                '/api/collection_yard/single?id=' + collectionYardId,
            )
            return data
        },
    })
}

export const useCreateCollectionYard = (options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: CollectionYardFormInput) => {            
            return axios.post(`/api/collection_yard/create?municipality_id=${municipalityId
                }&name=${data.name}`,
                {description: data.description}
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['collectionYards'] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useUpdateCollectionYard = (collectionYardId, options: FormMutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        formDataId,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: CollectionYardFormInput) => {
            return axios.put(`/api/collection_yard/update?id=${collectionYardId 
                }&name=${data.name}`,
                {description: data.description}
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['collectionYards'] })
            queryClient.invalidateQueries({ queryKey: ['collectionYard-' + collectionYardId] })
            localStorage.removeItem(formDataId)
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useDeleteCollectionYard = (collectionYardId, municipalityId) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => {
            return axios.delete(`/api/collection_yard/delete?id=${collectionYardId}`)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['collectionYards', municipalityId] })
        },
    })
}

