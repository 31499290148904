import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

export default function TimeInfo({
    text,
    time,
}: {
    text: string
    time: string
}) {
    return (
        <Box sx={{
            display: 'flex',
            gap: 1,
            color: 'customGrey.secondaryText',
        }}>
            <Box sx={{
                display: 'flex',
                width: '1rem',
                height: '1.4rem',
                alignItems: 'center',
            }}>
                <AccessTimeIcon sx={{
                    fontSize: '1rem',
                }}/>
            </Box>
            <Typography
                variant="body2"
                component={'div'}
            >
                {text} {time}
            </Typography>
        </Box>
    )
}
