import dayjs, { type Dayjs } from "dayjs"
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import 'dayjs/locale/cs'

import { UserRole, WasteTypeIds, type CollectionPointWeighting } from '@/types'
import {
    WASTE_TYPE_ID_COLOR_MAP,
    WASTE_TYPE_ID_LABEL_MAP,
    WASTE_TYPE_ID_DENSITY_MAP,
} from '@/constants/general'
import PermissionsGate from "@/components/permissions-gate"

type YearMap = {
    [year: number]: {
        [month: number]: {
            day: number
            dumps: {
                wasteTypeId: number
                weight: number
                percentage: number
                percentageRounded: number
            }[]
        }[]
    }
}

const generateYearsMap = (years: number[]): YearMap => {
    const yearMap: YearMap = {}

    for (const year of years) {
        // Initialize the year if not already in the map
        if (!yearMap[year]) {
            yearMap[year] = {}
        }
    
        // Iterate over each month (1 to 12)
        for (let month = 1; month <= 12; month++) {
            // Get the number of days in the current month
            const daysInMonth = dayjs(`${year}-${month}`).daysInMonth()
    
            // Create an array of day objects for the month
            yearMap[year][month] = Array.from({ length: daysInMonth }, (_, i) => ({
                day: i + 1,
                dumps: [], // Initialize with an empty array
            }))
        }
    }

    return yearMap
}

const getYearsBetween = (startDate, endDate): Array<number> => {
    const years = []
    let currentYear = startDate.year()
  
    // Loop until the current year exceeds the end year
    while (currentYear <= endDate.year()) {
        years.push(currentYear)
        currentYear++
    }
  
    return years
}

export default function DisposalTrendCalendar({
    data,
    startDate,
    endDate,
}: {
    data: Array<CollectionPointWeighting>,
    startDate: Dayjs,
    endDate: Dayjs,
}) {

    const yearMap = generateYearsMap(getYearsBetween(startDate, endDate))

    for (const entry of data) {
        const date = dayjs(entry.time_unit)
        const year = date.year()
        const month = date.month()+1
        const day = date.date()-1

        const percentage = entry.corrected_weight && Number(entry.waste_type_id) !== WasteTypeIds.plastic ?
            (Number(entry.corrected_weight)/(Number(entry.bin_type_volume)/1000*WASTE_TYPE_ID_DENSITY_MAP[entry.waste_type_id]))*100
            : 100
        
        const percentageRounded = Math.min(Math.ceil(percentage / 25) * 25, 100)

        yearMap[year][month][day].dumps.push({
            wasteTypeId: Number(entry.waste_type_id),
            weight: entry.corrected_weight ? Math.round(Number(entry.corrected_weight)) : null,
            percentage,
            percentageRounded,
        })
    }

    return (
        <>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
            }}>
                {Object.keys(yearMap).map(year => (
                    <Box key={year} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 0.5,
                    }}>
                        <Typography variant="h3" component="h5" gutterBottom>{year}</Typography>
                        {Object.keys(yearMap[Number(year)]).map(month => (
                            <Box key={month} sx={{
                                display: 'flex',
                                gap: 0.5,
                            }}>
                                <Typography variant="body1" component="div" width={74} flexShrink={0}>
                                    {dayjs().month(Number(month)-1).locale('cs').format('MMMM')}
                                </Typography>
                                    <Box sx={{
                                        display: 'flex',
                                        gap: 0.5,
                                        flexWrap: 'wrap',
                                    }}>
                                    {yearMap[Number(year)][Number(month)].map((dayObj, index) => (
                                        <Box key={index} sx={{
                                            width: 30,
                                            height: 30,
                                            backgroundColor: dayjs(
                                                `${year}-${month}-${dayObj.day}`
                                            ).isAfter(dayjs())
                                                ? 'customGrey.boxBackgroundLight'
                                                : 'customGrey.boxBackground',
                                            borderRadius: 1,
                                            display: 'flex',
                                        }}>
                                            <PermissionsGate userRoles={[ UserRole.admin ]}>
                                                {dayObj.dumps.map((dump, index) => (
                                                    <Box key={index} sx={{
                                                        position: 'relative',
                                                        flexGrow: 1,
                                                        backgroundColor: WASTE_TYPE_ID_COLOR_MAP[dump.wasteTypeId].main,
                                                        background: `linear-gradient(0deg, ${WASTE_TYPE_ID_COLOR_MAP[dump.wasteTypeId].main} ${dump.percentage}%, ${WASTE_TYPE_ID_COLOR_MAP[dump.wasteTypeId].light} ${dump.percentage}%, ${WASTE_TYPE_ID_COLOR_MAP[dump.wasteTypeId].light} 100%)`,
                                                        borderRadius: 1,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        '&:hover': {
                                                            backgroundColor: WASTE_TYPE_ID_COLOR_MAP[dump.wasteTypeId].light,
                                                        },
                                                        '&:hover .hover-label': {
                                                            display: 'flex',
                                                        }
                                                    }}>
                                                        {dayObj.dumps.length === 1 &&
                                                            <Box sx={{
                                                                fontSize: '0.75rem',
                                                                color: 'white',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="11px" viewBox="0 -960 960 960" width="11px" fill="#ffffff">
                                                                    <title>Ikona hmotnosti</title>
                                                                    <path d="M480-680q17 0 28.5-11.5T520-720q0-17-11.5-28.5T480-760q-17 0-28.5 11.5T440-720q0 17 11.5 28.5T480-680Zm113 0h70q30 0 52 20t27 49l57 400q5 36-18.5 63.5T720-120H240q-37 0-60.5-27.5T161-211l57-400q5-29 27-49t52-20h70q-3-10-5-19.5t-2-20.5q0-50 35-85t85-35q50 0 85 35t35 85q0 11-2 20.5t-5 19.5Z"/>
                                                                </svg>
                                                                {dump.weight}
                                                            </Box>
                                                        }
                                                        <Box sx={{
                                                            position: 'absolute',
                                                            display: 'none',
                                                            backgroundColor: WASTE_TYPE_ID_COLOR_MAP[dump.wasteTypeId].light,
                                                            borderRadius: 1,
                                                            padding: 1,
                                                            zIndex: 2,
                                                            top: 32,
                                                            flexDirection: 'column',
                                                            gap: 0.5,
                                                            minWidth: 150,
                                                        }} className="hover-label">
                                                            <Typography variant="h6" component="div">{WASTE_TYPE_ID_LABEL_MAP[dump.wasteTypeId].long}</Typography>
                                                            <Typography variant="body2" component="div">Datum: {dayObj.day}.{month}.{year}</Typography>
                                                            <Typography variant="body2" component="div">Váha: {dump.weight ?? '-'} kg</Typography>
                                                            {dump.wasteTypeId !== WasteTypeIds.plastic && dump.weight &&
                                                                <Typography variant="body2" component="div">Odhadovaná zaplněnost: {dump.percentage <= 100 ? dump.percentage.toFixed(0) : 100 }%</Typography>
                                                            }
                                                            </Box>
                                                    </Box>
                                                ))}
                                            </PermissionsGate>
                                            <PermissionsGate userRoles={[
                                                UserRole.basic,
                                                UserRole.collectionCompany,
                                                UserRole.collectionPointAdmin,
                                                UserRole.eokoDataAdmin,
                                                UserRole.municipalityOfficial,
                                                UserRole.resident,
                                            ]}>
                                                {dayObj.dumps.map((dump, index) => (
                                                    <Box key={index} sx={{
                                                        position: 'relative',
                                                        flexGrow: 1,
                                                        backgroundColor: WASTE_TYPE_ID_COLOR_MAP[dump.wasteTypeId].main,
                                                        borderRadius: 1,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        '&:hover': {
                                                            backgroundColor: WASTE_TYPE_ID_COLOR_MAP[dump.wasteTypeId].light,
                                                        },
                                                        '&:hover .hover-label': {
                                                            display: 'flex',
                                                        }
                                                    }}>
                                                        {dump.wasteTypeId !== WasteTypeIds.plastic && dump.weight &&
                                                            <>
                                                                {dayObj.dumps.length === 1 ?
                                                                    <img
                                                                        src={`/bin-${dump.percentageRounded}.svg`}
                                                                        loading="lazy"
                                                                        alt="Bin 25% full"
                                                                        style={{
                                                                            // maxWidth: '100%',
                                                                            // width: '100%',
                                                                        }}
                                                                    />
                                                                    :
                                                                    <Box sx={{
                                                                        borderRadius: '2px',
                                                                        width: dayObj.dumps.length > 1 ? '50%' : '30%',
                                                                        height: '60%',
                                                                        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.8) ${dump.percentageRounded}%, rgba(255, 255, 255, 0.2) ${dump.percentageRounded}%, rgba(255, 255, 255, 0.2) 100%)`,
                                                                    }}/>
                                                                }
                                                            </>
                                                        }
                                                        <Box sx={{
                                                            position: 'absolute',
                                                            display: 'none',
                                                            backgroundColor: WASTE_TYPE_ID_COLOR_MAP[dump.wasteTypeId].light,
                                                            borderRadius: 1,
                                                            padding: 1,
                                                            zIndex: 2,
                                                            top: 32,
                                                            flexDirection: 'column',
                                                            gap: 0.5,
                                                            minWidth: 150,
                                                        }} className="hover-label">
                                                            <Typography variant="h6" component="div">{WASTE_TYPE_ID_LABEL_MAP[dump.wasteTypeId].long}</Typography>
                                                            <Typography variant="body2" component="div">Datum: {dayObj.day}.{month}.{year}</Typography>
                                                            {dump.wasteTypeId !== WasteTypeIds.plastic && dump.weight &&
                                                                <Typography variant="body2" component="div">Odhadovaná zaplněnost: {dump.percentageRounded/25}/4</Typography>
                                                            }
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </PermissionsGate>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        ))}
                    </Box>
                ))}
            </Box>
        </>
    )
}
