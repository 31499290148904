import { useState, type ChangeEvent } from 'react'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { useAtom } from 'jotai'
import 'leaflet/dist/leaflet.css'
import { AttributionControl, MapContainer, Popup, TileLayer } from 'react-leaflet'

import { selectedMunicipalityIdAtom } from '@/state'
import { useMunicipalityPublicBins } from '@/hooks/api/use-municipality'
import LoadingBox from '@/components/loading-box'
import ErrorBox from '@/components/error-box'
import CustomMarker from './custom-marker'
import { WasteTypeCodes } from '@/types'
import { DEFAULT_BOUNDS } from '@/constants/general'
import type { LatLngBoundsExpression } from 'leaflet'

export default function LargeContainerMap() {
    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    const [show, setShow] = useState({
        municipal: true,
        bio: true,
        plastic: true,
        paper: true,
        glass: true,
    })

    const handleShowChange = (event: ChangeEvent<HTMLInputElement>) => {
        setShow({
          ...show,
          [event.target.name]: event.target.checked,
        })
    }

    const { status, data, error } = useMunicipalityPublicBins(municipalityId)

    if (status === 'pending') {
        return <LoadingBox />
    }

    if (error) {
        return (
            <ErrorBox
                error={error}
                message={`Nepovedlo se načíst upozornění ke svozu pro obec ID ${municipalityId}`}
            />
        )
    }

    const markersWithCoordinates = data ? data.filter(item => item.latitude && item.longitude) : []

    const bounds: LatLngBoundsExpression = markersWithCoordinates.length > 0 ?
        markersWithCoordinates.map(report => ([Number.parseFloat(report.latitude), Number.parseFloat(report.longitude)])) :
        DEFAULT_BOUNDS

    const filteredMarkers = markersWithCoordinates.length > 0 ? markersWithCoordinates.filter(item => {
        return (
            (show.municipal && item.waste_type_code === WasteTypeCodes.mixed) ||
            (show.bio && item.waste_type_code === WasteTypeCodes.bio) ||
            (show.plastic && item.waste_type_code === WasteTypeCodes.plastic) ||
            (show.paper && item.waste_type_code === WasteTypeCodes.paper) ||
            (show.glass && (item.waste_type_code === WasteTypeCodes.glass || item.waste_type_code === WasteTypeCodes.clearGlass))
        )
    }) : []

    const wasteFilters = [
        {
            key: 'municipal',
            show: show.municipal,
            name: 'SKO',
        },
        {
            key: 'bio',
            show: show.bio,
            name: 'Bio',
        },
        {
            key: 'plastic',
            show: show.plastic,
            name: 'Plast',
        },
        {
            key: 'paper',
            show: show.paper,
            name: 'Papír',
        },
        {
            key: 'glass',
            show: show.glass,
            name: 'Sklo',
        },
    ]

    return (
        <>
            {markersWithCoordinates.length === 0 &&
                <Box mb={1}>
                    <Alert
                        severity="error"
                    >
                        Žádná svozová místa nebyla zatím označena jako veřejná.
                    </Alert>
                </Box>
            }
            <Box mb={1}>
                <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend">Zobrazit</FormLabel>
                    <FormGroup row>
                        {wasteFilters.map(wasteFilter => 
                            <FormControlLabel
                                key={wasteFilter.key}
                                control={
                                    <Checkbox checked={wasteFilter.show} onChange={handleShowChange} name={wasteFilter.key} />
                                }
                                label={wasteFilter.name}
                            />
                        )}
                    </FormGroup>
                </FormControl>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: {
                        xs: 'calc(100vh - 368px)',
                        sm: 'calc(100vh - 288px)',
                        md: 'calc(100vh - 238px)',
                    },
                    '& .leaflet-container': {
                        width: '100%',
                        height: {
                            xs: 'calc(100vh - 368px)',
                            sm: 'calc(100vh - 288px)',
                            md: 'calc(100vh - 238px)',
                        },
                    },
                }}
            >
                <MapContainer
                    key={municipalityId}
                    bounds={bounds}
                    boundsOptions={{padding: [50, 50]}}
                    scrollWheelZoom={true}
                    attributionControl={false}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <AttributionControl position="bottomright" prefix={false} />
                    {filteredMarkers.map((marker, index) => (
                        <CustomMarker key={index} marker={marker}>
                            <Popup>ID: {marker.bin_id}, objem: {marker.bin_volume} l</Popup>
                        </CustomMarker>
                    ))}
                </MapContainer>
            </Box>
        </>
    )
}
