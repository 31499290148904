import { useEffect, useState } from 'react'
import { useWatch, useFormState, type Control, type FieldValues } from 'react-hook-form'

interface UseFormLocalStorageArgs<
    T extends FieldValues,
> {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    control: Control<any>,
    formDataId: string,
    reset: (arg: T) => void,
    initialValues: T,
}

const useFormLocalStorage = <
    T extends FieldValues,
>(
    args: UseFormLocalStorageArgs<T>
) => {
    const {
        control,
        formDataId,
        reset,
        initialValues,
    } = args
    const [isLoadedFromLocalStorage, setIsLoadedFromLocalStorage] = useState<boolean>(false)
    useEffect(() => {
        const storedValue = localStorage.getItem(formDataId)
        if (storedValue) {
            reset(JSON.parse(storedValue))
            setIsLoadedFromLocalStorage(true)
        }
    }, [formDataId, reset])

    const handleFormReset = () => {
        reset(initialValues)
        setIsLoadedFromLocalStorage(false)
        localStorage.removeItem(formDataId)
    }

    const values = useWatch({ control })
    const { isDirty } = useFormState({
        control
    })

    useEffect(() => {
        if (formDataId && isDirty) {
            return localStorage.setItem(formDataId, JSON.stringify(values))
        }
    }, [values, isDirty, formDataId])

    return [isLoadedFromLocalStorage, handleFormReset] as const

}

export default useFormLocalStorage
