import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import { useAtom } from 'jotai'
import { Link } from 'react-router-dom'
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded'
import { Helmet } from 'react-helmet-async'

import { APP_VERSION, ROUTE_SEGMENTS } from '@/constants/general'
import DisposalTrendMonthlyTrend from '@/components/disposal-trend/monthly-trend'
import { selectedCollectionPointIdAtom, selectedMunicipalityIdAtom } from '@/state'
import MunicipalitySortingTrendHome from '@/components/municipality-sorting-trend/home'

function HomeView() {

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)
    const [collectionPointId] = useAtom(selectedCollectionPointIdAtom)

    const endDate = dayjs().subtract(1, 'month')

    const title = 'Domů - jaktridim.cz'

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Container maxWidth="sm">
                <Box sx={{
                    my: 2,
                    fontSize: '0.75rem',
                    color: 'primary.main',
                    textAlign: 'center',
                }}>
                    <Typography variant="h2" component="h1">Vítejte!</Typography>
                </Box>
            </Container>
            {municipalityId &&
                <Paper sx={{ p: 2, borderRadius: 2.5, mb: 3 }} elevation={0}>
                    <Box pt={1} pb={1}>
                        <Typography variant="h3" component="h3">
                            Poměr tříděného odpadu ve vaší obci za posledních 12 měsíců
                        </Typography>
                    </Box>
                    <MunicipalitySortingTrendHome />
                    <Box pt={3} pb={1} display="flex" justifyContent="center">
                        <Button
                            variant="contained"
                            color="terciary"
                            startIcon={<TrendingUpRoundedIcon />}
                            component={Link}
                            to={`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipality}/${ROUTE_SEGMENTS.municipalitySortingTrend}`}
                        >
                            Více informací
                        </Button>
                    </Box>
                </Paper>
            }
            {collectionPointId &&
                <Paper sx={{ p: 2, borderRadius: 2.5 }} elevation={0}>
                    <Box pt={1} pb={1}>
                        <Typography variant="h3" component="h3">
                            Měsíční trend výsypu Vašeho svozového místa
                        </Typography>
                    </Box>
                    <DisposalTrendMonthlyTrend endDate={endDate}/>
                    <Box pt={1} pb={1} display="flex" justifyContent="center">
                        <Button
                            variant="contained"
                            color="terciary"
                            startIcon={<TrendingUpRoundedIcon />}
                            component={Link}
                            to={`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.collectionPoint}/${ROUTE_SEGMENTS.disposalTrend}`}
                        >
                            Více informací
                        </Button>
                    </Box>
                </Paper>
            }
            <Box sx={{
                    marginTop: 3,
                    fontSize: '0.75rem',
                    color: 'primary.main',
                    textAlign: 'center',
                }}>
                <p>Verze aplikace {APP_VERSION}</p>
            </Box>
        </>
    )
}

export default HomeView
