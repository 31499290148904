import { useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import dayjs from 'dayjs'
import { useQueryClient } from '@tanstack/react-query'

import { useCalculateMotivationModel } from '@/hooks/api/use-motivation-model'
import CardRadioGroup from './card-radio-group'
import LoadingBox from '@/components/loading-box'
import ErrorBox from '@/components/error-box'
import { convertFromCents } from '@/helpers'
import {
    type MotivationModel,
    ScenarioTypes,
    type MonthYear,
    ScenarioImprovementRatios,
    type ScenarioParameters,
} from '@/types'
import { MAX_CITIZEN_FEE_CENTS, SCENARIO_LABEL_MAP } from '@/constants/general'
import ScenarioAdjustment from './scenario-adjustment'
import ScenarioResults from './scenario-results'

export default function ModelSelector({
    motivationModel,
    parameters,
    setParameters,
}: {
    motivationModel: MotivationModel,
    parameters: ScenarioParameters,
    setParameters: React.Dispatch<React.SetStateAction<ScenarioParameters>>,
}) {

    const currentYear = dayjs().year()
    const motivationModelYear = motivationModel.year

    let startDate: MonthYear
    let endDate: MonthYear

    if (motivationModelYear >= currentYear) {
        startDate = dayjs().subtract(1, 'year').format('YYYY-MM') as MonthYear
        endDate = dayjs().subtract(1, 'month').format('YYYY-MM') as MonthYear
    } else {
        startDate = dayjs(motivationModelYear+'-01-01').format('YYYY-MM') as MonthYear
        endDate = dayjs(motivationModelYear+'-12-31').format('YYYY-MM') as MonthYear
    }

    const { status, data, error, isPlaceholderData, isRefetching } =
        useCalculateMotivationModel(
            motivationModel.id,
            startDate,
            endDate,
            parameters.scenario,
            parameters.annualSurcharge !== null && parameters.lastChange === 'annualSurcharge'
                ? Number(parameters.annualSurcharge)
                : null,
            parameters.citizenFee !== null && parameters.lastChange === 'citizenFee'
                ? Number(parameters.citizenFee)
                : parameters.lastChange === null
                  ? Number(parameters.citizenFee)
                  : null
        )

    const queryClient = useQueryClient()

    /*  biome-ignore lint: useEffect deps
        We only want to run this effect when 'data' changes, it is safe to do because
        data returned by useQuery is referentially stable.
    */
    useEffect(() => {
        if (data) {
            queryClient.invalidateQueries({ queryKey: ['motivationModels'] })
            queryClient.invalidateQueries({ queryKey: ['motivationModel-' + motivationModel.id] })
        }
    }, [data])

    const handleScenarioChange = value => {
        setParameters({
            ...parameters,
            citizenFee: convertFromCents(MAX_CITIZEN_FEE_CENTS).toString(),
            scenario: value,
            lastChange: null,
        })
    }

    if (status === 'pending') {
        return <LoadingBox />
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se vypočítat scénář pro model ID ${motivationModel.id}`}
        />
    }

    const getDescription = (percentage: number) => {
        if (percentage > 0) return `Občané se zlepší v třídění o ${percentage*100}%`
        if (percentage < 0) return `Občané se zhorší v třídění o ${percentage*100}%`
        return 'Občané se nezlepší v třídění'
    }
    
    const models = [
        {
            label: SCENARIO_LABEL_MAP[ScenarioTypes.pesimistic],
            value: ScenarioTypes.pesimistic,
            description: getDescription(ScenarioImprovementRatios.pesimistic),
        },
        {
            label: SCENARIO_LABEL_MAP[ScenarioTypes.realistic],
            value: ScenarioTypes.realistic,
            description: getDescription(ScenarioImprovementRatios.realistic),
        },
        {
            label: SCENARIO_LABEL_MAP[ScenarioTypes.optimistic],
            value: ScenarioTypes.optimistic,
            description: getDescription(ScenarioImprovementRatios.optimistic),
        },
    ]
    
    return (
        <>
            <Box display="flex" flexDirection="column" alignItems="center" pb={3}>
                <Typography variant="h5" component="h3" mb={2}>
                    Zvolte scénář
                </Typography>
                <CardRadioGroup
                    options={models}
                    selectedValue={parameters.scenario}
                    onChange={handleScenarioChange}
                />
            </Box>
            <ScenarioAdjustment
                key={data.scenario+data.citizen_fee_cents+data.annual_surcharge_cents}
                scenario={data}
                setParameters={setParameters}
                isLoading={isPlaceholderData || isRefetching}
            />
            <Divider sx={{ pt: 4 }}/>
            <ScenarioResults
                scenario={data}
                isLoading={isPlaceholderData || isRefetching}
            />
        </>
    )
}
