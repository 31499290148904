import { useActiveMotivationModel } from '@/hooks/api/use-motivation-model'
import type { MotivationModel } from '@/types'

interface ActiveMotivationModelLoaderProps {
    year: number,
    children: (data: MotivationModel) => React.ReactNode
}

const ActiveMotivationModelLoader: React.FC<ActiveMotivationModelLoaderProps> = ({
    year,
    children,
}) => {
    const { data } = useActiveMotivationModel(year)

    if (data) {
        return <>{children(data)}</>
    }

    return <>{children(null)}</>
}

export default ActiveMotivationModelLoader
