import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import { Helmet } from 'react-helmet-async'

import CustomPaper from '@/components/custom-paper'
import { ROUTE_SEGMENTS } from '@/constants/general'
import SingleDumpWrapper from '@/components/illegal-dumps/single-dump-wrapper'

function IllegalDumpDetailView() {

    const title = 'Detail hlášení černé skládky'

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <CustomPaper>
                <Box pb={3} display="flex" alignItems="center" gap={1}>
                    <IconButton
                        aria-label="close"
                        size="small"
                        color="terciary"
                        component={Link}
                        to={`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipalityManagement}/${ROUTE_SEGMENTS.illegalDumps}`}
                    >
                        <Icon>arrow_back</Icon>
                    </IconButton>
                    <Typography variant="h3" component="h1">
                        {title}
                    </Typography>
                </Box>
                <SingleDumpWrapper />
            </CustomPaper>
        </>
    )
}

export default IllegalDumpDetailView
