import { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar'

import type { ContactDivision } from '@/types'
import HeadingWithIcon from '@/components/heading-with-icon'
import ContactCard from '@/components/contact/card'
import ContactDivisionSectionActions from '@/components/contact/division-section-actions'
import ContactDivisionForm from '../forms/contact-division'
import ContactForm from '@/components/forms/contact'
import RichText from '@/components/rich-text'

export default function ContactDivisionSection({
    contactDivision,
    editMode,
}: {
    contactDivision: ContactDivision,
    editMode: boolean,
}) {

    const [edit, setEdit] = useState<boolean>(false)
    const [createContact, setCreateContact] = useState<boolean>(false)

    return (
        <Box>
            {edit ?
                <ContactDivisionForm
                    contactDivision={contactDivision}
                    closeCallback={() => setEdit(false)}
                />
                :
                <Box display="flex" flexDirection="column" gap={1}>
                    
                    <Box display="flex" alignItems="center" gap={1} sx={{ color: 'terciary.main' }}>
                        <PermContactCalendarIcon/>
                        <Typography variant="h3" component="h3">
                            {contactDivision.name}
                        </Typography>
                        {editMode &&
                            <ContactDivisionSectionActions
                                contactDivision={contactDivision}
                                setEdit={setEdit}
                                setCreateContact={setCreateContact}
                            />
                        }
                    </Box>
                    {contactDivision.description &&
                        <Box pt={1.5}>
                            <RichText content={contactDivision.description} />
                        </Box>
                    }
                </Box>
            }
            {createContact &&
                <Box pt={1.5} maxWidth={500}>
                    <ContactForm
                        contactDivisionId={contactDivision.id}
                        closeCallback={() => setCreateContact(false)}
                    />
                </Box>
            }
            <Box display="flex" flexWrap="wrap" gap={2} pt={2}>
                {contactDivision.contacts.map(contact => (
                    <ContactCard
                        key={contact.id}
                        contact={contact}
                        editMode={editMode}
                    />
                ))}
            </Box>
        </Box>
    )
}
