import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"


export default function LoadingBox() {

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            flexGrow: 1,
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}>
                <CircularProgress />
            </Box>
        </Box>
    )
}
