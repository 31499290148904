import {
    type Dispatch,
    type SetStateAction,
    useState,
    type MouseEvent,
} from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'

import type { MotivationModelListItem } from '@/types'

function MotivationModelActions({
    motivationModel,
    setEdit,
    setDuplicate,
    setActivate,
    setArchive,
}: {
    motivationModel: MotivationModelListItem,
    setEdit: Dispatch<SetStateAction<MotivationModelListItem>>,
    setDuplicate: Dispatch<SetStateAction<MotivationModelListItem>>,
    setActivate: Dispatch<SetStateAction<MotivationModelListItem>>,
    setArchive: Dispatch<SetStateAction<MotivationModelListItem>>,
}) {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setDuplicate(null)
    }

    return (
        <>
            <Tooltip title="Více možností">
                <IconButton
                    id="actions-button"
                    aria-label="actions"
                    aria-controls={open ? 'actions-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    color="terciary"
                    onClick={handleClick}
                >
                    <MoreVertRoundedIcon />
                </IconButton>
            </Tooltip>
            <Menu
                id={`MM-${motivationModel.id}-actions-menu`}
                MenuListProps={{
                    'aria-labelledby': 'actions-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={(e: MouseEvent) => {
                    e?.stopPropagation()
                    handleClose()
                }}
                slotProps={{
                    paper: {
                        style: {
                            minWidth: '15ch',
                        }
                    }
                }}
            >
                <MenuItem>
                    <ListItemIcon>
                        <VisibilityRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    Detail
                </MenuItem>
                <MenuItem
                    onClick={e => {
                        e.stopPropagation()
                        setActivate(motivationModel)
                        setAnchorEl(null)
                    }}
                >
                    <ListItemIcon>
                        <CheckCircleRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    Aktivovat
                </MenuItem>
                <MenuItem
                    onClick={e => {
                        e.stopPropagation()
                        setEdit(motivationModel)
                        setAnchorEl(null)
                    }}
                    disabled={motivationModel.active}
                >
                    <ListItemIcon>
                        <EditRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    Upravit název/rok
                </MenuItem>
                <MenuItem
                    onClick={e => {
                        e.stopPropagation()
                        setDuplicate(motivationModel)
                        setAnchorEl(null)
                    }}
                >
                    <ListItemIcon>
                        <ContentCopyRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    Duplikovat
                </MenuItem>
                <MenuItem
                    onClick={e => {
                        e.stopPropagation()
                        setArchive(motivationModel)
                        setAnchorEl(null)
                    }}
                >
                    <ListItemIcon>
                        <ArchiveRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    Archivovat
                </MenuItem>
            </Menu>
            
        </>
    )
}

export default MotivationModelActions
