import { useState } from 'react'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { useDeleteDocument } from '@/hooks/api/use-direct-upload'
import type { Endpoints } from '@/types'

function DeleteFileIconButton({
    entityId,
    documentId,
    endpoint,
    invalidateQueryKey,
}: {
    entityId: number,
    documentId: number,
    endpoint: Endpoints,
    invalidateQueryKey?: Array<string | number>,
}) {

    const [confirmation, setConfirmation] = useState(false)
    const [loading, setLoading] = useState(false)

    const mutation = useDeleteDocument(
        entityId,
        documentId,
        endpoint,
        invalidateQueryKey,
    )

    const handleClickConfirmation = bool => {
        setConfirmation(bool)
    }
    const handleClickConfirmedDelete = () => {
        setLoading(true)
        mutation.mutate()
        setConfirmation(false)
        setLoading(false)
    }

    return (
        <>
            {!confirmation &&
                <IconButton
                    aria-label="Smazat dokument"
                    onClick={() => handleClickConfirmation(true)}
                    size="small"
                >
                    {loading ? <CircularProgress color="inherit" size={24} /> : <Icon>delete</Icon>}
                </IconButton>
            }
            {confirmation &&
                <Box sx={{
                    borderRadius: 18,
                    backgroundColor: 'grey.100'
                }}>
                    <Button
                        sx={{ maxHeight: 34 }}
                        startIcon={<Icon>close</Icon>}
                        onClick={() => handleClickConfirmation(false)}
                        color='terciary'
                    >
                        Zrušit
                    </Button>
                    <Button
                        sx={{ maxHeight: 34 }}
                        startIcon={<Icon>delete</Icon>}
                        onClick={handleClickConfirmedDelete}
                        color='error'
                    >
                        Odstranit
                    </Button>
                </Box>
            }
        </>
    )
}

export default DeleteFileIconButton
