import Box from '@mui/material/Box'

import LoadingBox from '@/components/loading-box'
import ErrorBox from '@/components/error-box'
import { useContactDivisionsMunicipality } from '@/hooks/api/use-contact'
import ContactDivisionSection from '@/components/contact/division-section'
import ContactDivisionCreate from '@/components/contact/division-create'

function ContactDivisionListMunicipality({
    municipalityId,
    editMode,
}: {
    municipalityId: number,
    editMode: boolean,
}) {
    
    const { status, data, error } = useContactDivisionsMunicipality(municipalityId)

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst kontakty obce s ID ${municipalityId}`}
        />
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
        }}>
            {data.length === 0 ?
                <Box>
                    Zatím nebyly vytvořeny žádné sekce kontaktů
                </Box>
            :
                <Box display="flex" flexDirection="column" gap={4}>
                    {data.map(contactDivision => (
                        <ContactDivisionSection
                            key={contactDivision.id}
                            contactDivision={contactDivision}
                            editMode={editMode}
                        />
                    ))}
                </Box>

            }
            {editMode &&
                <ContactDivisionCreate municipalityId={municipalityId} />
            }
        </Box>
    )
}

export default ContactDivisionListMunicipality
