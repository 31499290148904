
import Box from '@mui/material/Box'
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar'
import { PersonBox, PersonDetails, PersonValue } from '@/components/person-box'
import type { CollectionPointDetail } from '@/types'
import HeadingWithIcon from '@/components/heading-with-icon'

export default function CollectionPointAdmin({
    admin,
}: {
    admin: CollectionPointDetail["admin"]
}) {

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
            }}
        >
            <HeadingWithIcon
                text="Správce svozového místa"
                icon={<PermContactCalendarIcon />}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gap: '1rem',
                }}
            >
                {[admin].map(({ name, mobile, email }, index) => (
                    <PersonBox key={index} fullName={name}>
                        <PersonDetails>
                            <PersonValue type="email" value={email} />
                            <PersonValue type="phone" value={mobile} />
                        </PersonDetails>
                    </PersonBox>
                ))}
            </Box>
        </Box>
    )
}
