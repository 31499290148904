import type { ReactNode, ComponentProps} from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

import { FONT_WEIGHT } from '../constants/font'

export const ContentParagraph = styled('div')(({ theme }) => ({}))

export const ContentParagraphHeader = styled('div')(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: FONT_WEIGHT.medium,
}))

export const ContentParagraphText = styled('div')(({ theme }) => ({
    color: '#5F5F5F',
    fontSize: '1rem',
    fontWeight: FONT_WEIGHT.regular,
}))

export function ContentBox({
    children,
    noPadding = false,
    padding,
    sxWrapper,
    sxContent,
}: {
    children: ReactNode
    noPadding?: boolean
    padding?: string
    sxWrapper?: ComponentProps<typeof Box>['sx']
    sxContent?: ComponentProps<typeof Box>['sx']
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                backgroundColor: '#fff',
                border: '1px solid #DEDEDE',
                borderRadius: '10px',
                padding: noPadding ? '' : padding ? padding : '.75rem 1rem 1rem',
                ...sxWrapper,
            }}
        >
            {/* content */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '.75rem',
                    ...sxContent,
                }}
            >
                {children}
            </Box>
        </Box>
    )
}
