import { useState } from 'react'
import Box from '@mui/material/Box'
import { useLocation } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import EditRoundedIcon from '@mui/icons-material/EditRounded'

import { ROUTE_SEGMENTS } from '@/constants/general'
import PermissionsGate from '@/components/permissions-gate'
import { UserRole } from '@/types'
import { useApplicationInstruction } from '@/hooks/api/use-application-instruction'
import LoadingBox from '@/components/loading-box'
import ErrorBox from '@/components/error-box'
import RichText from '@/components/rich-text'
import ApplicationInstructionForm from '@/components/forms/application-instruction'

const ROOT = 'root'

const APPLICATION_INSTRUCTION_MAP = {
    [ROUTE_SEGMENTS.base]: {
        [ROOT]: 'app-home',
        [ROUTE_SEGMENTS.municipality]: {
            [ROOT]: 'municipality',
            [ROUTE_SEGMENTS.collectionCalendar]: 'collection-calendar',
            [ROUTE_SEGMENTS.collectingYards]: 'collecting-yards',
            [ROUTE_SEGMENTS.collectionNotification]: 'collection-notification',
            [ROUTE_SEGMENTS.wasteFee]: 'waste-fee',
            [ROUTE_SEGMENTS.howToPayLess]: 'how-to-pay-less',
            [ROUTE_SEGMENTS.largeContainerMap]: 'large-container-map',
            [ROUTE_SEGMENTS.collectionPointList]: 'collection-point-list',
            [ROUTE_SEGMENTS.reportIllegalDump]: 'report-illegal-dump',
            [ROUTE_SEGMENTS.municipalitySortingTrend]: 'municipality-sorting-trend',
            [ROUTE_SEGMENTS.contacts]: 'contacts',
        },
        [ROUTE_SEGMENTS.collectionPoint]: {
            [ROOT]: 'collection-point',
            [ROUTE_SEGMENTS.detail]: 'collection-point-detail',
            [ROUTE_SEGMENTS.disposalTrend]: 'collection-point-disposal-trend',
            [ROUTE_SEGMENTS.collectionClaim]: 'collection-point-collection-claim',
            [ROUTE_SEGMENTS.extraCollection]: 'collection-point-extra-collection',
            [ROUTE_SEGMENTS.largeContainer]: 'collection-point-large-container',
        },
        [ROUTE_SEGMENTS.municipalityManagement]: {
            [ROOT]: 'municipality-management',
            [ROUTE_SEGMENTS.motivationModel]: 'motivation-models',
            'motivation-model-step': {
                'create': 'motivation-model-step-create',
                '0': 'motivation-model-step-1',
                '1': 'motivation-model-step-2',
                '2': 'motivation-model-step-3',
                '3': 'motivation-model-step-4',
                '4': 'motivation-model-step-5',
            },
            [ROUTE_SEGMENTS.collectionPointCategories]: 'collection-point-categories',
            [ROUTE_SEGMENTS.illegalDumps]: 'illegal-dumps',
            [ROUTE_SEGMENTS.collectionNotificationManagement]: 'collection-notification-management',
            [ROUTE_SEGMENTS.collectedWasteStats]: 'collected-waste-stats',
            [ROUTE_SEGMENTS.householdRefresh]: 'household-refresh',
        },
    },
}

function Help() {

    const location = useLocation()
    const locationSlugs = location.pathname.slice(1).split('/')

    let code = 'general'

    if(locationSlugs.length === 1) {
        code = APPLICATION_INSTRUCTION_MAP[locationSlugs[0]][ROOT]
    }
    if(locationSlugs.length === 2) {
        code = APPLICATION_INSTRUCTION_MAP[locationSlugs[0]][locationSlugs[1]][ROOT]
    }
    if(locationSlugs.length > 2) {
        code = APPLICATION_INSTRUCTION_MAP[locationSlugs[0]][locationSlugs[1]][locationSlugs[2]]
    }
    if(locationSlugs.length > 3 && locationSlugs[2] === ROUTE_SEGMENTS.motivationModel) {
        code = APPLICATION_INSTRUCTION_MAP[ROUTE_SEGMENTS.base][ROUTE_SEGMENTS.municipalityManagement]['motivation-model-step'].create
        if (locationSlugs.length > 5) {
            code = APPLICATION_INSTRUCTION_MAP[ROUTE_SEGMENTS.base][ROUTE_SEGMENTS.municipalityManagement]['motivation-model-step'][locationSlugs[5]]
        }
    }

    const [create, setCreate] = useState<boolean>(false)
    const [edit, setEdit] = useState<boolean>(false)

    const { status, data, error } = useApplicationInstruction(code)

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst nápovědu k této stránce - klíč: ${code}`}
        />
    }

    if (data === null) {
        return (
            <Box p={2}>
                {create ?
                    <ApplicationInstructionForm
                        code={code}
                        closeCallback={() => setCreate(false)}
                    />
                    :
                    <Box>
                        <Typography variant="body1" component="p" mb={1}>
                            Nápověda pro tuto stránku zatím nebyla vytvořena.
                        </Typography>
                        <PermissionsGate userRoles={[ UserRole.admin ]}>
                            <Typography variant="body1" component="p" mb={2}>
                                Přejete si ji vytvořit?
                            </Typography>
                            <Button
                                variant="contained"
                                color="terciary"
                                startIcon={<AddRoundedIcon />}
                                onClick={() => setCreate(true)}
                            >
                                Vytvořit nápovědu
                            </Button>
                            <Typography variant="body2" component="p" color="customGrey.cardBorder" mt={2}>
                                ID: {code}
                            </Typography>
                        </PermissionsGate>
                    </Box>
                }
            </Box>
        )
    }

    return (
        <Box
            sx={{
                width: '100%',
                maxWidth: 360,
                px: 2,
                py: 1.5,
                maxHeight: 'calc(100vh - 6rem)',
                overflowX: 'hidden',
                '&::-webkit-scrollbar': {
                    width: '0.4em',
                },
                '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(47, 49, 145, .1)',
                },
                scrollBehavior: 'smooth',
                position: 'relative',
            }}
        >
            {edit ?
                <ApplicationInstructionForm
                    code={code}
                    applicationInstruction={data}
                    closeCallback={() => setEdit(false)}
                />
                :
                <>
                    <PermissionsGate userRoles={[UserRole.admin]}>
                        <Typography variant="body2" component="p" color="customGrey.cardBorder" mb={1}>
                            ID: {code}
                        </Typography>
                    </PermissionsGate>
                    <Typography variant="h3" component="h2" mb={2}>
                        Nápověda
                    </Typography>
                    <RichText content={data.description} />
                    <PermissionsGate userRoles={[UserRole.admin]}>
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            right: 3,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            borderRadius: '1rem',
                        }}>
                            <Tooltip title="Upravit">
                                <IconButton
                                    id="edit-button"
                                    aria-label="edit"
                                    color="terciary"
                                    onClick={() => setEdit(true)}
                                    sx={{ backgroundColor: 'editableOrange.main' }}
                                >
                                    <EditRoundedIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </PermissionsGate>
                </>
            }
        </Box>
    )
}

export default Help
