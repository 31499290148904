import axios from 'axios'
import dayjs from 'dayjs'
import {
    useQuery,
    keepPreviousData,
    useQueryClient,
    useMutation,
} from '@tanstack/react-query'
import type {
    MonthYear,
    Municipality,
    MunicipalityCitizenStats,
    MunicipalityStatsByVolume,
    Pagination,
    CollectionPointCategoryListResponse,
    MutationOptions,
    MunicipalityBin,
    CollectionPointCategoryListFilters,
} from '@/types'

export function useMunicipalities(publicList?: boolean) {
    return useQuery({
        queryKey: [
            'municipalities',
            publicList,
        ],
        queryFn: async () => {
            const { data } : { data: Array<Municipality> } = await axios.get(
                '/api/municipality/'+(publicList ? 'public_list' : 'list'),
            )
            return data
        },
        staleTime: 1000*60*5,
    })
}

export function useMunicipalityStats(
    municipalityId: number,
    startDate: MonthYear,
    endDate: MonthYear,
) {
    return useQuery({
        queryKey: ['municipalityStats-' + municipalityId, 'startDate-'+startDate, 'endDate-'+endDate],
        queryFn: async () => {
            const { data } : { data: MunicipalityStatsByVolume } = await axios.get(
                `/api/municipality/stats?municipality_id=${municipalityId
                    }&begins_date=${startDate
                    }&ends_date=${endDate}`,
            )
            return data
        },
        placeholderData: keepPreviousData,
        enabled: !!municipalityId,
    })
}

export function useMunicipalityCitizenStats(municipalityId: number) {
    return useQuery({
        queryKey: ['municipalityCitizenStats-' + municipalityId],
        queryFn: async () => {
            const { data } : { data: Array<MunicipalityCitizenStats> } = await axios.get(
                `/api/municipality/citizen_category_stats?municipality_id=${municipalityId
                    }&year=${dayjs().subtract(1, 'year').format('YYYY')}`,
            )
            return data
        },
    })
}

export function useCollectionPointCategoryList(
    municipalityId: number,
    pagination: Pagination,
    //startDate: MonthYear,
    //endDate: MonthYear,
    filters: CollectionPointCategoryListFilters,
) {
    return useQuery({
        queryKey: [
            'collectionPointCategoryList',
            municipalityId,
            `limit-${pagination.limit}`,
            `offset-${pagination.offset}`,
            //'startDate-'+startDate,
            //'endDate-'+endDate,
            'weightCats-'+filters.mixedWeightCategories.join('-'),
            'dumpCats-'+filters.mixedVolumeCategories.join('-'),
            'types-'+filters.types.join('-'),
            'number-'+filters.numberOfMembers,
        ],
        queryFn: async () => {
            const queryParams = new URLSearchParams()

            queryParams.append('municipality_id', municipalityId.toString())
            queryParams.append('limit', pagination.limit.toString())
            queryParams.append('offset', pagination.offset.toString())
            //queryParams.append('begins_date', startDate)
            //queryParams.append('ends_date', endDate)
            if (filters.mixedWeightCategories.length > 0) {
                queryParams.append('mixed_weight_category_level', `[${filters.mixedWeightCategories.join(',')}]`)
            }
            if (filters.mixedVolumeCategories.length > 0) {
                queryParams.append('mixed_volume_category_level', `[${filters.mixedVolumeCategories.join(',')}]`)
            }
            if (filters.types.length > 0) {
                queryParams.append('type', JSON.stringify(filters.types))
            }
            if (!Number.isNaN(Number.parseInt(filters.numberOfMembers))) {
                queryParams.append('number_of_members', filters.numberOfMembers)
            }
            
            const { data } : { data: CollectionPointCategoryListResponse } = await axios.get(
                `/api/municipality/category_list?${queryParams.toString()}`,
            )
            return data
        },
        placeholderData: keepPreviousData,
    })
}

export const useRefreshHouseholdCategories = (options: MutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: { municipalityId: number, motivationModelId: number }) => {
            return axios.post(`/api/municipality/refresh_households_categories?municipality_id=${data.municipalityId
                }&motivation_model_id=${data.motivationModelId}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['collectionPointCategoryList'] })
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export const useRefreshHouseholdStats = (options: MutationOptions) => {
    const queryClient = useQueryClient()

    const {
        onSuccessCallback,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (data: { municipalityId: number }) => {
            return axios.post(`/api/municipality/refresh_households_stats?municipality_id=${data.municipalityId}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['collectionPointCategoryList'] })
            if (onSuccessCallback) {
                onSuccessCallback(response.data)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export function useMunicipalityLargeBins(municipalityId: number) {
    return useQuery({
        queryKey: ['municipalityLargeBins-' + municipalityId],
        queryFn: async () => {
            const { data } : { data: Array<MunicipalityBin> } = await axios.get(
                `/api/municipality/large_bins?municipality_id=${municipalityId}`,
            )
            return data
        },
    })
}

export function useMunicipalityPublicBins(municipalityId: number) {
    return useQuery({
        queryKey: ['municipalityPublicBins-' + municipalityId],
        queryFn: async () => {
            const { data } : { data: Array<MunicipalityBin> } = await axios.get(
                `/api/municipality/public_bins?municipality_id=${municipalityId}`,
            )
            return data
        },
    })
}
