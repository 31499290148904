import axios from 'axios'
import {
    useQuery,
    keepPreviousData,
} from '@tanstack/react-query'
import type {
    EkokomStats,
} from '@/types'

export function useEkokomStatsByMunicipalitySize(
    year: number | string,
    citizens_count: number,
) {
    return useQuery({
        queryKey: ['municipalityStats', 'year-'+year, 'citizens-'+citizens_count],
        queryFn: async () => {
            const { data } : { data: Array<EkokomStats> } = await axios.get(
                `/api/ekokom_stat/by_municipality_size?year=${year
                    }&citizens_count=${citizens_count}`,
            )
            return data
        },
    })
}

export function useEkokomStatsByRegion(
    year: number | string,
    citizens_count: number,
    regionId: number,
) {
    return useQuery({
        queryKey: ['municipalityStats', 'year-'+year, 'citizens-'+citizens_count, 'region-'+regionId],
        queryFn: async () => {
            const { data } : { data: Array<EkokomStats> } = await axios.get(
                `/api/ekokom_stat/by_region?year=${year
                    }&citizens_count=${citizens_count
                    }&region_ids=[${regionId}]`,
            )
            return data
        },
        placeholderData: keepPreviousData,
    })
}
