import { useForm, type SubmitHandler } from "react-hook-form"
import { useAtom } from "jotai"
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import useFormLocalStorage from "@/hooks/use-form-local-storage"
import { APP_VERSION } from "@/constants/general"
import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import RHFTextfield from "@/components/form-fields/rhf-textfield"
import RHFTextarea from "@/components/form-fields/rhf-textarea"
import type { FileWithMetadata, IllegalDumpFormInput, IllegalDumpFormInputWithFiles } from "@/types"
import { useSendIllegalDump } from "@/hooks/api/use-illegal-dump"
import { selectedMunicipalityIdAtom } from "@/state"
import useFileUpload from '@/hooks/use-file-upload'

const IllegalDumpForm = ({
    closeCallback,
    toggleSuccess,
    position,
} : {
    closeCallback?: () => void,
    toggleSuccess: () => void,
    position: { lat: number, lng: number } | null,
}) => {

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    const formDataId = `illegalDumpForm-v${APP_VERSION}`

    const initialValues: IllegalDumpFormInput = {
        address: '',
        description: '',
        sender_email: '',
    }

    const {
        files,
        handleFileChange,
        getFileMetadata,
        getBase64,
    } = useFileUpload()
    
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const [isLoadedFromLocalStorage, handleFormReset] = useFormLocalStorage({
        control,
        formDataId,
        reset,
        initialValues
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
        error,
        setError,
    } = useFormStates(handleFormReset)

    const mutation = useSendIllegalDump(
        municipalityId,
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback() {
                toggleSuccess()
                closeCallback()
            },
            onErrorCallback(errorMessage) {
                setError(errorMessage)
            }
        },
    )

    const onSubmit: SubmitHandler<IllegalDumpFormInputWithFiles> = async data => {
        setRequestInProgress(true)
        setError(null)

        try {
            const filesWithMetadata: FileWithMetadata[]  = await Promise.all(
                Array.from(files).map(async file => {
                    const fileMetadata = await getFileMetadata(file)
                    const base64File = await getBase64(file)
                    return {
                        filename: fileMetadata.filename,
                        byte_size: fileMetadata.byteSize,
                        checksum: fileMetadata.checksum,
                        content_type: 'text/plain',
                        file_data: base64File as string,
                    }
                })
            )
    
            mutation.mutate({ ...data, files: filesWithMetadata, position, })
        } catch (error) {
            console.error(error)
        } finally {
            setRequestInProgress(false)
        }
    }

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            heading={'Doplňte prosím informace o černé skládce'}
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={isLoadedFromLocalStorage}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
            buttonText={'Nahlásit černou skládku'}
            error={error}
            setError={setError}
            disableSave={!position}
        >
            <Box>
                <Typography variant="h5" component="h5" gutterBottom>
                    Poloha *
                </Typography>
                {position ?
                    <Box>
                        <Typography variant="body2" component="p" gutterBottom>
                            Zem. šířka: {position.lat}
                        </Typography>
                        <Typography variant="body2" component="p">
                            Zem. výška: {position.lng}
                        </Typography>
                    </Box>
                    :
                    <Typography variant="body2" component="p" gutterBottom>
                        Vyberte prosím kliknutím na mapu
                    </Typography>
                }
            </Box>
            <RHFTextfield
                name="address"
                control={control}
                label="Adresa"
                size="small"
            />
            <RHFTextarea
                name="description"
                control={control}
                label="Popis"
                size="small"
                required
            />
            <RHFTextfield
                name="sender_email"
                control={control}
                label="Váš email"
                size="small"
                required
            />
            <Box mt={1} display="flex" justifyContent="center">
                <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<DescriptionRoundedIcon />}
                    component="label"
                >
                    Vybrat fotky
                    <input
                        type="file"
                        accept="image/*"
                        hidden
                        multiple
                        onChange={handleFileChange}
                    />
                </Button>
            </Box>
            {(files && files.length !== 0) &&
                <Box mt={1} display="flex" justifyContent="center" flexDirection="column" textAlign="center">
                    <Typography variant="body1" component="div">
                        {'Vybrané fotky:'}
                    </Typography>
                    <Box display="flex" flexWrap="wrap" gap={2} pt={2}>
                        {Array.from(files).map((file, index) => (
                            <Box key={index} maxWidth={122}>
                                <img src={URL.createObjectURL(file)} alt={file.name} style={{ maxWidth: '100%' }}/>
                                <Typography key={file.name} variant="body2" component="div" sx={{
                                    fontSize: '0.75rem',
                                    overflowWrap: 'anywhere',
                                }}>
                                    {file.name} ({(file.size/1024).toFixed(0)} KB)
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            }
        </Form>
    )
}

export default IllegalDumpForm
