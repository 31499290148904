import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import InfoIcon from '@mui/icons-material/Info'

import InfoBar from '@/components/info-bar'
import TimeInfo from '@/components/collection-point/detail/detail-time-info'
import type { CollectionPointDetail } from '@/types'
import { COLLECTION_POINT_TYPE_LABEL_MAP } from '@/constants/general'
import CollectionPointDetailMap from './map'

export default function DetailHeader({
    collectionPointDetail,
}: {
    collectionPointDetail: CollectionPointDetail,
}) {
    
    const address = collectionPointDetail.address

    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: {
                    xs: 'column',
                    md: 'row',
                },
                columnGap: 6,
                rowGap: 3,
                mb: 3,
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    flex: 1,
                }}>
                    <Box>
                        <Typography variant="h4" component="div" mb={0.5}>
                            {address.street} {address.street_number},
                        </Typography>
                        <Typography variant="h4" component="div" mb={0.5}>
                            {address.zip_code} {address.town}
                        </Typography>
                        <Typography
                            variant="body2"
                            component="div"
                            color="customGrey.secondaryText"
                        >
                            Typ: {COLLECTION_POINT_TYPE_LABEL_MAP[collectionPointDetail.type]}
                        </Typography>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        columnGap: 1.5,
                        rowGap: 0.5,
                    }}>
                        <TimeInfo text="Datum zavedení změn svozovou firmou:" time="TBD" />
                        <TimeInfo text="Moje kontrola dne:" time="TBD" />
                    </Box>
                </Box>
                {collectionPointDetail.address.latitude && collectionPointDetail.address.longitude &&
                    <Box sx={{
                        flex: 1,
                        borderRadius: 2.5,
                        overflow: 'hidden',
                    }}>
                        <CollectionPointDetailMap
                            latitude={Number.parseFloat(collectionPointDetail.address.latitude)}
                            longitude={Number.parseFloat(collectionPointDetail.address.longitude)}
                        />
                    </Box>
                }
            </Box>

            <InfoBar
                icon={<InfoIcon sx={{ color: 'primary.light' }} />}
                mainButton={{
                    onClick: () => alert('ok!'),
                    text: 'Vše v pořádku',
                }}
                secondButton={{
                    onClick: () => alert('ok!'),
                    text: 'Nahlásit změnu',
                }}
                text={
                    'Nedošlo ke změnám od data poslední kontroly? Zkontrolujte prosím údaje níže.'
                }
            />
        </>
    )
}
