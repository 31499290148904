import type { SyntheticEvent } from "react"
import { useAtom } from "jotai"
import InputAdornment from '@mui/material/InputAdornment'
import GiteRoundedIcon from '@mui/icons-material/GiteRounded'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Autocomplete from "@mui/material/Autocomplete"
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import TextField from "@mui/material/TextField"
import { useLocation } from 'react-router-dom'

import {
    selectedMunicipalityIdAtom,
    selectedCollectionPointIdAtom,
} from "@/state"
import { useCollectionPoints } from "@/hooks/api/use-collection-point"
import type { CollectionPointListItem } from "@/types"
import { ROUTE_SEGMENTS } from "@/constants/general"

export default function CollectionPointSelector() {

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)
    const [collectionPointId, setCollectionPointId] = useAtom(selectedCollectionPointIdAtom)

    const { data, error } = useCollectionPoints(municipalityId)

    const getOptionLabel = (option: CollectionPointListItem) => (
        `${option.address.street ? option.address.street : '-'} ${option.address.street_number ? option.address.street_number : '-'} (${option.code})`
    )

    const options = data ? data.sort((a, b) => getOptionLabel(a).localeCompare(getOptionLabel(b), 'cs', { numeric: true })) : []

    const location = useLocation()
    const locationSlugs = location.pathname.slice(1).split('/')
    const disabled = locationSlugs[2] === ROUTE_SEGMENTS.motivationModel && locationSlugs.length > 3

    return (
        <Autocomplete
            value={
                collectionPointId
                    ? options.find((option) => {
                        return collectionPointId === option.id
                    }) ?? null
                    : null
            }
            getOptionLabel={(option) => {
                return getOptionLabel(option)
            }}
            getOptionKey={(option) => {
                return option.id
            }}
            title={disabled ? 'Při vytváření a editaci motivačního modelu nelze volit svozová místa.' : null}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(getOptionLabel(option), inputValue)
                const parts = parse(getOptionLabel(option), matches)

                const text = (parts.map((part, index) => (
                    <span
                        key={index}
                        style={{
                            fontWeight: part.highlight ? 700 : 400,
                        }}
                    >
                        {part.text}
                    </span>
                )))

                return (
                    <li {...props} key={props.key}>
                        <div>
                            {text}
                        </div>
                    </li>
                );
            }}
            onChange={(_event: SyntheticEvent, newValue) => {
                setCollectionPointId(newValue ? newValue.id : null)
            }}
            disableClearable
            id="collectionPoint-selector"
            options={options}
            disabled={disabled}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder="Svozové místo"
                    size="small"
                    inputProps={{
                        ...params.inputProps,
                        // disable autocomplete and autofill
                        autoComplete: "off",
                    }}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start" sx={{ paddingLeft: 1}}>
                                {error ?
                                    <ErrorOutlineIcon sx={{ color: 'error.light' }} />
                                    :
                                    <GiteRoundedIcon sx={{ color: 'customGrey.selectorIcon' }} />
                                }
                            </InputAdornment>
                        ),
                        sx: {
                            borderRadius: 5,
                            backgroundColor: 'white',
                        }
                      }}
                    autoComplete="off"
                    sx={{
                        minWidth: 250,
                        borderRadius: 50,
                    }}
                />
            )}
            sx={{
                '& .MuiOutlinedInput-root': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: "white" 
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'primary.light'
                    },
                }
              }}
        />
    )
}
