import type { ReactNode } from 'react'
import Paper from '@mui/material/Paper'
import type { PaperProps } from '@mui/material/Paper'
import { styled } from '@mui/material/styles'

const StyledPaper = styled(Paper)<PaperProps>(({ theme }) => ({
    padding: theme.spacing(2.5),
    borderRadius: 10,
    minHeight: 'calc(100vh - 10.5rem)',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
        minHeight: 'calc(100vh - 8rem)',
    },
    [theme.breakpoints.up('md')]: {
        minHeight: 'calc(100vh - 5rem)',
    },
}))

const StyledPaperNoPadding = styled(Paper)<PaperProps>(({theme}) => ({
    borderRadius: 10,
    minHeight: 'calc(100vh - 10.5rem)',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
        minHeight: 'calc(100vh - 8rem)',
    },
    [theme.breakpoints.up('md')]: {
        minHeight: 'calc(100vh - 5rem)',
    },
}))

function CustomPaper({
    noPadding = false,
    children,
}: {
    noPadding? : boolean,
    children?: ReactNode,
}) {

    return ( noPadding ? 
        <StyledPaperNoPadding elevation={0}>
            {children}
        </StyledPaperNoPadding>
        :
        <StyledPaper elevation={0}>
            {children}
        </StyledPaper>
    )
}

export default CustomPaper
