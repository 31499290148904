import { type ChangeEvent, useState } from 'react'
import { useAtom } from 'jotai'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { AttributionControl, MapContainer, TileLayer, useMapEvents } from 'react-leaflet'
import { Helmet } from 'react-helmet-async'

import CustomPaper from '@/components/custom-paper'
import IllegalDumpForm from '@/components/forms/illegal-dump'
import CustomPlaceMarker from '@/components/illegal-dumps/custom-place-marker'
import { usePublicIllegalDumps } from '@/hooks/api/use-illegal-dump'
import { selectedMunicipalityIdAtom } from '@/state'
import { CircularProgress } from '@mui/material'
import { ReportStates } from '@/types'
import CustomDumpMarker from '@/components/illegal-dumps/custom-dump-marker'
import SingleDumpPublicWrapper from '@/components/illegal-dumps/single-dump-public-wrapper'
import { DEFAULT_BOUNDS } from '@/constants/general'
import type { LatLngBoundsExpression } from 'leaflet'

function ReportIllegalDumpView() {

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    const [success, setSuccess] = useState<boolean>(false)

    const toggleSuccess = () => {
        setSuccess(!success)
    }

    const [newReport, setNewReport] = useState<boolean>(false)
    const [position, setPosition] = useState<{ lat: number, lng: number } | null>(null)

    const [selectedDumpId, setSelectedDumpId] = useState<number | null>(null)

    const MapClickHandler = () => {
        useMapEvents({
          click: (event) => {
            const { lat, lng } = event.latlng;
            setPosition({ lat, lng })
          },
        })
        return null
    }

    const [show, setShow] = useState({
        sent: true,
        inProgress: true,
        resolved: false,
    })

    const handleShowChange = (event: ChangeEvent<HTMLInputElement>) => {
        setShow({
          ...show,
          [event.target.name]: event.target.checked,
        })
    }

    const { status, data, error } = usePublicIllegalDumps(municipalityId)

    const reportsWithCoordinates = data ? data?.filter(item => item.latitude && item.longitude) : []

    const bounds: LatLngBoundsExpression = reportsWithCoordinates.length > 0 ?
        reportsWithCoordinates.map(report => ([Number.parseFloat(report.latitude), Number.parseFloat(report.longitude)])) :
        DEFAULT_BOUNDS
    
    const filteredMarkers =
        reportsWithCoordinates.length > 0
            ? reportsWithCoordinates.filter(item => {
                  return (
                      (show.sent && item.state === ReportStates.sent) ||
                      (show.inProgress && item.state === ReportStates.inProgress) ||
                      (show.resolved && item.state === ReportStates.resolved)
                  )
              })
            : []
    
    const stateFilters = [
        {
            key: 'sent',
            show: show.sent,
            name: 'Nahlášeno',
        },
        {
            key: 'inProgress',
            show: show.inProgress,
            name: 'V řešení',
        },
        {
            key: 'resolved',
            show: show.resolved,
            name: 'Vyřešeno',
        },
    ]

    const title = 'Hlášení černé skládky'

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <CustomPaper>
                <Box sx={{
                    display: 'flex',
                    flexDirection: {
                        xs: 'column',
                        lg: 'row',
                    }
                }}>
                    <Box sx={{
                        flexBasis: {
                            xs: '100%',
                            lg: '60%',
                        },
                        pr: {
                            xs: 0,
                            lg: 3,
                        },
                    }}>
                        <Box sx={{
                            display: 'flex',
                            gap: 1.5,
                            alignItems: 'center',
                            mb: 1.5,
                        }}>
                            <Typography variant="h2" component="h1">
                                {title}
                            </Typography>
                            {status === 'pending' &&
                                <CircularProgress color="inherit" size={20} />
                            }
                            {error &&
                            <Alert
                                severity="error"
                            >
                                Nepovedlo se načíst seznam hlášení černých skládek obce s ID {municipalityId}
                            </Alert>
                            }
                        </Box>
                        <Box mb={1}>
                            <FormControl component="fieldset" variant="standard">
                                <FormLabel component="legend">Zobrazit</FormLabel>
                                <FormGroup row>
                                    {stateFilters.map(stateFilter => 
                                        <FormControlLabel
                                            key={stateFilter.key}
                                            control={
                                                <Checkbox checked={stateFilter.show} onChange={handleShowChange} name={stateFilter.key} />
                                            }
                                            label={stateFilter.name}
                                        />
                                    )}
                                </FormGroup>
                            </FormControl>
                        </Box>
                        <Box sx={{
                            width: '100%',
                            height: {
                                xs: '50vh',
                                lg: 'calc(100vh - 238px)',
                            },
                            '& .leaflet-container': {
                                width: '100%',
                                height: {
                                    xs: '50vh',
                                    lg: 'calc(100vh - 238px)',
                                },
                            },
                        }}>
                            <MapContainer
                                key={municipalityId}
                                bounds={bounds}
                                boundsOptions={{padding: [50, 50]}}
                                scrollWheelZoom={true}
                                attributionControl={false}
                            >
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <AttributionControl position="bottomright" prefix={false} />
                                {filteredMarkers.map((marker, index) => (
                                    <CustomDumpMarker
                                        key={index}
                                        marker={marker}
                                        selectedId={selectedDumpId}
                                        setSelectedId={setSelectedDumpId}
                                    />
                                ))}
                                {newReport &&
                                    <MapClickHandler />
                                }
                                {position && newReport &&
                                    <CustomPlaceMarker position={[position.lat, position.lng]} />
                                }
                            </MapContainer>
                        </Box>
                    </Box>
                    <Box sx={(theme) => ({
                        flexBasis: {
                            xs: '100%',
                            lg: '40%',
                        },
                        pl: {
                            xs: 0,
                            lg: 3,
                        },
                        pt: {
                            xs: 3,
                            lg: 0,
                        },
                        borderLeft: {
                            xs: '0px',
                            lg: `1px solid ${theme.palette.customGrey.divider}`,
                        },
                        borderTop: {
                            xs: `1px solid ${theme.palette.customGrey.divider}`,
                            lg: '0px',
                        },
                        overflowX: 'auto',
                    })}>
                        {success &&
                            <Alert
                                severity="success"
                                sx={(theme) => ({
                                    flexGrow: 1,
                                    margin: theme.spacing(1, 0, 2),
                                })}
                                action={
                                    <Button
                                        color="success"
                                        size="small"
                                        onClick={() => setSuccess(false)}
                                    >
                                        Zavřít
                                    </Button>
                                }
                            >
                                Hlášení černé skládky úspěšně odesláno.
                            </Alert>
                        }
                        {selectedDumpId ?
                            <Box sx={{
                                position: 'relative',
                                pt: 1,
                            }}>
                                <SingleDumpPublicWrapper
                                    illegalDumpIdFromProps={selectedDumpId}
                                    handleClose={() => setSelectedDumpId(null)}
                                />
                            </Box>
                            :
                            newReport ?
                                <Box mt={2}>
                                    <IllegalDumpForm
                                        toggleSuccess={toggleSuccess}
                                        position={position}
                                        closeCallback={() => setNewReport(false)}
                                    />
                                </Box>
                                :
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: 3,
                                    height: '100%',
                                    justifyContent: 'center',
                                }}>
                                    <Typography variant='h5' component="p">
                                        Vyberte hlášení na mapě
                                    </Typography>
                                    <Typography variant='body2' component="p">
                                        nebo
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="terciary"
                                        startIcon={<AddRoundedIcon />}
                                        onClick={() => {
                                            setSelectedDumpId(null)
                                            setNewReport(true)
                                        }}
                                    >
                                        Nové hlášení
                                    </Button>
                                </Box>
                        }
                    </Box>
                </Box>
            </CustomPaper>
        </>
    )
}

export default ReportIllegalDumpView
