import { useState } from 'react'
import { useAtom } from 'jotai'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/cs'
import CachedRoundedIcon from '@mui/icons-material/CachedRounded'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import Grid from '@mui/material/Unstable_Grid2'
import CircularProgress from '@mui/material/CircularProgress'
import { useLocation } from 'react-router-dom'
import dayjs from 'dayjs'

import ErrorBox from '@/components/error-box'
import LoadingBox from '@/components/loading-box'
import { useMunicipalityStats } from '@/hooks/api/use-municipality'
import { selectedMunicipalityIdAtom } from '@/state'
import type { AggregatedWasteData, MonthYear, Municipality } from '@/types'
import MonthlyTrendChart from '@/components/municipality-sorting-trend/monthly-trend-chart'
import { END_DATE_KEY, START_DATE_KEY, WASTE_COLORS } from '@/constants/general'
import TotalPieChart from '@/components/municipality-sorting-trend/total-pie-chart'
import WasteCard from '@/components/municipality-sorting-trend/waste-card'
import MonthlyShareChart from '@/components/municipality-sorting-trend/monthly-share-chart'
import CountryComparison from './country-comparison'
import RegionComparison from './region-comparison'
import { calculateMonthlyAndTotalMunicipalityStats } from '@/helpers'
import useDateInterval from '@/hooks/use-date-interval'
import MunicipalityComparisonSelector from './municipality-comparison-selector'
import MunicipalitiesLoader from '@/wrappers/municipalities-loader'

export default function MunicipalitySortingTrendWrapper({
    municipalities,
}: {
    municipalities: Array<Municipality>,
}) {

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    const [comparisonMunicipalityId, setComparisonMunicipalityId] = useState<number>(0)

    const municipality = municipalities.find(item => item.id === comparisonMunicipalityId)

    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const paramsStartDate = dayjs(params.get(START_DATE_KEY))
    const paramsEndDate = dayjs(params.get(END_DATE_KEY))

    const {
        handleSetDates,
        handleSetLast12Months,
        handleSetLastCalendarYear,
        startDate,
        endDate,
        startDateValue,
        setStartDateValue,
        endDateValue,
        setEndDateValue,
    } = useDateInterval({
        initialStartDate: paramsStartDate.isValid() ? paramsStartDate : null,
        initialEndDate: paramsEndDate.isValid() ? paramsEndDate : null,
    })
    
    const { status, data, error, isPlaceholderData } = useMunicipalityStats(
        municipalityId,
        startDate.format('YYYY-MM') as MonthYear,
        endDate.format('YYYY-MM') as MonthYear,
    )

    const {
        status: comparisonStatus,
        data: comparisonData,
        error: comparisonError,
        isPlaceholderData: comparisonPlaceholderData,
    } = useMunicipalityStats(
        comparisonMunicipalityId ? comparisonMunicipalityId : municipalityId,
        comparisonMunicipalityId
            ? (startDate.format('YYYY-MM') as MonthYear)
            : (startDate.subtract(1, 'year').format('YYYY-MM') as MonthYear),
        comparisonMunicipalityId
            ? (endDate.format('YYYY-MM') as MonthYear)
            : (endDate.subtract(1, 'year').format('YYYY-MM') as MonthYear)
    )

    if (status === 'pending' || comparisonStatus === 'pending') {
        return <LoadingBox />
    }

    if (error || comparisonError) {
        return <ErrorBox
            error={error ?? comparisonError ?? 'Neočekávaná chyba'}
            message={`Nepovedlo se načíst statistiky obce s ID ${municipalityId}`}
        />
    }

    const { monthlyData, totalData } = calculateMonthlyAndTotalMunicipalityStats(data)
    const { monthlyData: monthlyComparisonData } = calculateMonthlyAndTotalMunicipalityStats(comparisonData)

    const mixedWasteData: AggregatedWasteData = {
        name: 'Směsný komunální',
        tons: totalData.mixed_weight/1000,
        perPerson: totalData.mixed_weight/totalData.citizens_count,
        percentage: totalData.mixed_weight/(totalData.all_weight/100),
        dumpsCount: totalData.mixed_bin_dumps_count,
        percentageRecycling: null,
        color: WASTE_COLORS.municipal,
    }

    const recyclingWasteData: AggregatedWasteData = {
        name: 'Tříděný',
        tons: totalData.recycling_weight/1000,
        perPerson: totalData.recycling_weight/totalData.citizens_count,
        percentage: totalData.recycling_weight/(totalData.all_weight/100),
        dumpsCount: totalData.recycling_bin_dumps_count,
        color: WASTE_COLORS.recycling,
    }

    const recyclingIndividualData: Array<AggregatedWasteData> = [
        {
            name: 'Bio',
            tons: totalData.bio_weight/1000,
            perPerson: totalData.bio_weight/totalData.citizens_count,
            percentage: totalData.bio_weight/(totalData.all_weight/100),
            dumpsCount: totalData.bio_bin_dumps_count,
            percentageRecycling: totalData.bio_weight/(totalData.recycling_weight/100),
            color: WASTE_COLORS.bio,
        },
        {
            name: 'Plast',
            tons: totalData.plastic_weight/1000,
            perPerson: totalData.plastic_weight/totalData.citizens_count,
            percentage: totalData.plastic_weight/(totalData.all_weight/100),
            dumpsCount: totalData.plastic_bin_dumps_count,
            percentageRecycling: totalData.plastic_weight/(totalData.recycling_weight/100),
            color: WASTE_COLORS.plastic,
        },
        {
            name: 'Papír',
            tons: totalData.paper_weight/1000,
            perPerson: totalData.paper_weight/totalData.citizens_count,
            percentage: totalData.paper_weight/(totalData.all_weight/100),
            dumpsCount: totalData.paper_bin_dumps_count,
            percentageRecycling: totalData.paper_weight/(totalData.recycling_weight/100),
            color: WASTE_COLORS.paper,
        },
        {
            name: 'Sklo',
            tons: totalData.glass_weight/1000,
            perPerson: totalData.glass_weight/totalData.citizens_count,
            percentage: totalData.glass_weight/(totalData.all_weight/100),
            dumpsCount: totalData.glass_bin_dumps_count,
            percentageRecycling: totalData.glass_weight/(totalData.recycling_weight/100),
            color: WASTE_COLORS.glass,
        },
    ]

    return (
        <>
            <Alert
                severity="warning"
                sx={(theme) => ({
                    flexGrow: 1,
                    margin: theme.spacing(1, 0),
                })}
            >
                Statistiky zatím nezahrnují data ze sběrných dvorů.
            </Alert>
            <Box sx={{
                display: 'flex',
                gap: 2,
                pt: 2,
                flexWrap: 'wrap',
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 2,
                    }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
                            <DatePicker
                                label="Datum od"
                                views={['year', 'month']}
                                value={startDateValue}
                                onChange={newDate => {
                                    setStartDateValue(newDate)
                                }}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
                            <DatePicker
                                label="Datum do"
                                views={['year', 'month']}
                                value={endDateValue}
                                onChange={newDate => {
                                    setEndDateValue(newDate)
                                }}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        gap: 0.5,
                        pt: 1.5,
                    }}>
                        <CalendarMonthRoundedIcon />
                        <Chip
                            label="Posledních 12 měsíců"
                            size="small"
                            onClick={handleSetLast12Months}
                            sx={{
                                backgroundColor: 'customLightGreen.main',
                                '&:hover': {
                                    backgroundColor: 'customLightGreen.dark',
                                }
                            }}
                        />
                        <Chip
                            label="Poslední kalendářní rok"
                            size="small"
                            onClick={handleSetLastCalendarYear}
                            sx={{
                                backgroundColor: 'customLightGreen.main',
                                '&:hover': {
                                    backgroundColor: 'customLightGreen.dark',
                                }
                            }}
                        />
                    </Box>
                </Box>
                <Box flexGrow={1} minWidth={220}>
                    <MunicipalityComparisonSelector
                        municipalityId={comparisonMunicipalityId}
                        setMunicipalityId={setComparisonMunicipalityId}
                        comparisons={[{id: 0, name: 'Předchozí období'}, ...municipalities]}
                    />
                </Box>
                <Box pt={1.2}>
                    <Button
                        variant="contained"
                        color="terciary"
                        startIcon={isPlaceholderData || comparisonPlaceholderData ? <CircularProgress color="inherit" size={20} /> : <CachedRoundedIcon />}
                        onClick={() => handleSetDates()}
                    >
                        Aktualizovat
                    </Button>
                </Box>
            </Box>
            <Box pt={5}>
                <Typography variant="h3" component="h3">
                    Kumulativně za celé období
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                gap: {
                    xs: 2,
                    xl: 4,
                },
                flexDirection: {
                    xs: 'column',
                    lg: 'row',
                },
                pt: 4,
            }}>
                <Box sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}>
                    <Grid container spacing={2}>
                        {[mixedWasteData, recyclingWasteData].map(card => (
                            <Grid key={card.name} xs={12} sm={6}>
                                <WasteCard data={card} />
                            </Grid>
                        ))}
                    </Grid>
                    <Divider />
                    <Grid container spacing={2}>
                        {recyclingIndividualData.map(card => (
                            <Grid key={card.name} xs={12} sm={6} xl={4}>
                                <WasteCard data={card} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Box display="flex" justifyContent="center">
                    <TotalPieChart
                        dataInner={[mixedWasteData, recyclingWasteData]}
                        dataOuter={[mixedWasteData, ...recyclingIndividualData]}
                    />
                </Box>
            </Box>
            <MonthlyShareChart
                data={monthlyData}
                comparisonData={monthlyComparisonData}
                comparisonMunicipality={municipality?.name}
            />
            <MonthlyTrendChart
                data={monthlyData}
                comparisonData={monthlyComparisonData}
                comparisonMunicipality={municipality?.name}
            />
            <MunicipalitiesLoader>
                {municipalities => (
                    <>
                        <CountryComparison
                            startDate={startDate}
                            endDate={endDate}
                            citizens_count={totalData.citizens_count}
                            mixedWasteMunicipality={mixedWasteData}
                            recyclingWasteMunicipality={recyclingWasteData}
                            municipalityName={municipalities.find(item => item.id === municipalityId).name}
                        />
                        <RegionComparison
                            startDate={startDate}
                            endDate={endDate}
                            citizens_count={totalData.citizens_count}
                            mixedWasteMunicipality={mixedWasteData}
                            recyclingWasteMunicipality={recyclingWasteData}
                            municipalityName={municipalities.find(item => item.id === municipalityId).name}
                        />
                    </>
                )}
            </MunicipalitiesLoader>
            <Box pt={5} />
        </>
    )
}
