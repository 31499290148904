import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded'

import FileUpload from '@/components/file-upload'
import { type Endpoints, UserRole, type UploadedDocument } from '@/types'
import PermissionsGate from '@/components/permissions-gate'
import DeleteFileIconButton from '@/components/delete-file-icon-button'

export default function DocumentsList({
    documents,
    endpoint,
    entityId,
    invalidateQueryKey,
}: {
    documents: Array<UploadedDocument>,
    endpoint: Endpoints,
    entityId: number,
    invalidateQueryKey?: Array<string | number>,
}) {

    return (
        <>
            <Typography variant="h5" component="h1" mb={2}>
                Ke stažení
            </Typography>
            <Box>
                {documents.map(document => (
                    <Box display="flex" key={document.id} mb={1}>
                        <Box
                            component="a"
                            href={document.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                display: 'flex',
                                color: 'customGrey.tableText',
                                alignItems: 'center',
                            }}
                        >
                            <DescriptionRoundedIcon />
                            <Box ml={0.5} mr={0.5}>{document.filename}</Box>
                        </Box>
                        <PermissionsGate
                            userRoles={[
                                UserRole.admin,
                                UserRole.municipalityOfficial,
                            ]}
                        >
                            <DeleteFileIconButton
                                endpoint={endpoint}
                                entityId={entityId}
                                documentId={document.id}
                                invalidateQueryKey={invalidateQueryKey}
                            />
                        </PermissionsGate>
                    </Box>
                ))}
            </Box>
            <PermissionsGate
                userRoles={[
                    UserRole.admin,
                    UserRole.municipalityOfficial,
                ]}
            >
                <Box pt={2}>
                    <FileUpload
                        endpoint={endpoint}
                        entityId={entityId}
                        invalidateQueryKey={invalidateQueryKey}
                    />
                </Box>
            </PermissionsGate>
        </>
    )
}
