import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Icon from '@mui/material/Icon'
import Box from "@mui/material/Box"
import type { AxiosError } from 'axios'


export default function ErrorBox({
    error,
    message,
}: {
    error?: AxiosError | unknown,
    message?: string,
}) {

    if (error) console.error(error)

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            alignItems: 'center',
        }}>
            <Avatar sx={(theme) => ({
                width: theme.spacing(10),
                height: theme.spacing(10),
                backgroundColor: 'grey.300',
                margin: theme.spacing(3),
            })}>
                <Icon sx={{
                    fontSize: '3rem',
                    color: 'white',
                }}>
                    settings_ethernet
                </Icon>
            </Avatar>
            {message &&
                <Typography
                    variant="subtitle2"
                    component="div"
                    sx={(theme) => ({
                        color: 'grey.400',
                        marginBottom: theme.spacing(2),
                        textAlign: 'center',
                    })}
                >
                    {message}
                </Typography>
            }
            <Typography
                variant="subtitle2"
                component="div"
                sx={(theme) => ({
                    color: 'grey.400',
                    marginBottom: theme.spacing(2),
                    textAlign: 'center',
                })}
            >
                {error ?
                    'Došlo k chybě: '+error
                    :
                    'Došlo k chybě, kontaktujte prosím správce systému.'
                }
            </Typography>
        </Box>
    )
}
