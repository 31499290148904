import type { FormEventHandler, KeyboardEvent, ReactNode } from "react"
import Icon from '@mui/material/Icon'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import FormLoadedAlert from "./loaded-alert"
import FormChangedAlert from "./changed-alert"
import BottomButtons from "./bottom-buttons"
import FormWarningsAlert from "./warnings-alert"
import FormErrorAlert from "./error-alert"

export default function Form({
    onSubmit,
    heading,
    closeCallback,
    isLoadedFromLocalStorage,
    isDirty,
    isChangedOnServer,
    warnings,
    error,
    setError,
    resetWithVersion,
    children,
    disableSave = false,
    requestInProgress = false,
    buttonText,
    buttonIcon,
    lastUpdateAt,
    lastUpdater,
}: {
    onSubmit: FormEventHandler<HTMLFormElement>,
    heading?: string,
    closeCallback?: () => void,
    isLoadedFromLocalStorage: boolean,
    isDirty: boolean,
    isChangedOnServer?: boolean,
    warnings?: Array<string> | null | undefined,
    error?: string | null,
    setError?: (arg1: string | null) => void
    resetWithVersion: () => void,
    children: ReactNode,
    disableSave?: boolean,
    requestInProgress: boolean,
    buttonText?: string,
    buttonIcon?: string,
    lastUpdateAt?: string,
    lastUpdater?: {
        familyName?: string | null | undefined,
        givenName?: string | null | undefined,
    } | null,
}) {

    return (
        <form onSubmit={onSubmit} onKeyDown={(e: KeyboardEvent<HTMLFormElement>) => {
            if (!(e.key === "Enter" && (e.metaKey || e.ctrlKey))) return
            const form = (e.target as HTMLFormElement).form
            if (form) form.requestSubmit()
        }}>
            <Box sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                backgroundColor: theme.palette.customLightYellow.light,
                borderRadius: 2,
                border: '1px solid '+theme.palette.customLightYellow.main,
                padding: theme.spacing(1.5, 2),
                position: 'relative',
            })}>
                <Box>
                    {heading &&
                        <Typography variant="h5" component="h3" >
                            {heading}
                        </Typography>
                    }
                    {closeCallback &&
                        <Box sx={{
                            position: 'absolute',
                            top: 4,
                            right: 4,
                        }}>
                            <IconButton
                                aria-label="close"
                                size="small"
                                color="secondary"
                                onClick={() => closeCallback()}
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </Box>
                    }
                    {isLoadedFromLocalStorage &&
                        <FormLoadedAlert handleFormReset={() => resetWithVersion()} />
                    }
                    {isChangedOnServer &&
                        <FormChangedAlert lastUpdateAt={lastUpdateAt} lastUpdater={lastUpdater}/>
                    }
                    {warnings &&
                        <FormWarningsAlert warnings={warnings} buttonText={buttonText} />
                    }
                    {error &&
                        <FormErrorAlert error={error} setError={setError} />
                    }
                </Box>
                {children}
                <BottomButtons
                    buttonText={buttonText}
                    buttonIcon={buttonIcon}
                    disableSave={disableSave}
                    handleFormReset={() => resetWithVersion()}
                    allowReset={isDirty || isLoadedFromLocalStorage || isChangedOnServer}
                    requestInProgress={requestInProgress}
                    isChangedOnServer={isChangedOnServer}
                />
            </Box>
        </form>
    )
}
