import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded'

import { useArchiveMotivationModel } from '@/hooks/api/use-motivation-model'

function ArchiveDialog({
    motivationModelId,
    motivationModelName,
    handleClose,
}: {
    motivationModelId: string | number,
    motivationModelName: string,
    handleClose: () => void,
}) {

    const [requestInProgress, setRequestInProgress] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)

    const mutation = useArchiveMotivationModel(
        motivationModelId,
        {
            setRequestInProgress,
            onSuccessCallback(data) {
                if(data) {
                    setSuccess(true)
                }
            }
        },
    )

    const handleClickActivate = () => {
        setRequestInProgress(true)
        mutation.mutate()
    }

    return (
        <Box sx={{
            minWidth: 200,
            minHeight: 100,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            {success ?
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                    px: 3,
                }}>
                    <ArchiveRoundedIcon sx={{
                        color: 'success.main',
                        fontSize: '4rem',
                    }}/>
                    <Typography variant="body1" component="div">
                        Model úspěšně archivován.
                    </Typography>
                    <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={() => {
                            setSuccess(false)
                            handleClose()
                        }}
                    >
                        Zavřít
                    </Button>
                </Box>
                :
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Typography variant="h5" component="div" mb={1}>
                        Potvrďte prosím archivaci modelu: {motivationModelName}
                    </Typography>
                    <Typography variant="body2" component="div" mb={1} color="customGrey.secondaryText">
                        ID modelu: {motivationModelId}
                    </Typography>
                    <Typography variant="body2" component="div" mb={2}>
                        Tato akce model skryje ze všech seznamů.
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexGrow: 1,
                    }}>
                        <Button
                            variant="text"
                            color="terciary"
                            onClick={() => handleClose()}
                        >
                            Zrušit
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            startIcon={requestInProgress ? <CircularProgress color="inherit" size={20} /> : <ArchiveRoundedIcon />}
                            onClick={() => handleClickActivate()}
                            disabled={requestInProgress}
                        >
                            Archivovat
                        </Button>
                    </Box>
                </Box>
            }
        </Box>
    )
}

export default ArchiveDialog
