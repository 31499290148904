import { useForm, type SubmitHandler } from "react-hook-form"

import useFormLocalStorage from "@/hooks/use-form-local-storage"
import { APP_VERSION } from "@/constants/general"
import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import RHFTextfield from "@/components/form-fields/rhf-textfield"
import type { ContactDivision, ContactDivisionFormInput } from "@/types"
import { useCreateContactDivision, useUpdateContactDivision } from "@/hooks/api/use-contact"
import RHFRichText from "@/components/form-fields/rhf-rich-text"

const ContactDivisionForm = ({
    municipalityId,
    collectionCompanyId,
    contactDivision,
    closeCallback,
} : {
    municipalityId?: number,
    collectionCompanyId?: number,
    contactDivision?: ContactDivision,
    closeCallback?: () => void,
}) => {

    const formDataId = `contactDivisionForm-${contactDivision?.id}-v${APP_VERSION}`

    const initialValues = {
        name: contactDivision?.name ? contactDivision.name : '',
        description: contactDivision?.description ? contactDivision.description : '',
    }
    
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const [isLoadedFromLocalStorage, handleFormReset] = useFormLocalStorage({
        control,
        formDataId,
        reset,
        initialValues
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
    } = useFormStates(handleFormReset)

    const mutationCreate = useCreateContactDivision(
        municipalityId,
        collectionCompanyId,
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback: closeCallback,
        }
    )

    const mutationUpdate = useUpdateContactDivision(
        contactDivision?.id,
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback: closeCallback,
        },
    )

    const onSubmit: SubmitHandler<ContactDivisionFormInput> = data => {
        setRequestInProgress(true)
        if (contactDivision?.id) {
            mutationUpdate.mutate(data)
        } else {
            mutationCreate.mutate(data)
        }
    }

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            heading={contactDivision ? 'Upravit sekci' : 'Vytvořit novou sekci'}
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={isLoadedFromLocalStorage}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
        >
            <RHFTextfield
                name="name"
                control={control}
                label="Název sekce"
                required
            />
            <RHFRichText
                name="description"
                control={control}
                label="Popis"
            />
        </Form>
    )
}

export default ContactDivisionForm
