import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { IconButton } from '@mui/material'
import LoopIcon from '@mui/icons-material/Loop'
import EmailIcon from '@mui/icons-material/Email'
import { useAtom } from 'jotai'
import { Helmet } from 'react-helmet-async'

import { Heading } from '@/components/heading'
import CustomPaper from '@/components/custom-paper'
import { MessageList } from '@/components/collection-point/detail/detail-message-list'
import CollectionPointDetailWrapper from '@/components/collection-point/detail/wrapper'
import { selectedCollectionPointIdAtom } from '@/state'

function DetailView() {

    const [collectionPointId] = useAtom(selectedCollectionPointIdAtom)

    const title = 'Detail svozového místa'

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <CustomPaper>
                <Box sx={{
                    display: 'flex',
                    flexDirection: {
                        xs: 'column',
                        lg: 'row',
                    },
                    gap: 3,
                }}>
                    <Box sx={{
                        flexBasis: {
                            xs: '100%',
                            lg: '65%',
                        },
                    }}>
                        {collectionPointId ?
                            <CollectionPointDetailWrapper />
                            :
                            <>
                                <Typography variant="h2" component="h1" mb={2.5}>
                                    {title}
                                </Typography>
                                <Box>
                                    Vyberte prosím konkrétní svozové místo výše.
                                </Box>
                            </>
                        }
                    </Box>
                    <Box sx={(theme) => ({
                        flexBasis: {
                            xs: '100%',
                            lg: '35%',
                        },
                        pl: {
                            xs: 0,
                            lg: 3,
                        },
                        pt: {
                            xs: 3,
                            lg: 0,
                        },
                        borderLeft: {
                            xs: '0px',
                            lg: `1px solid ${theme.palette.customGrey.divider}`,
                        },
                        borderTop: {
                            xs: `1px solid ${theme.palette.customGrey.divider}`,
                            lg: '0px',
                        },
                    })}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}>
                                <Heading icon={<EmailIcon />}>Komunikace s obcí</Heading>
                                <IconButton
                                    color="terciary"
                                    onClick={() => {
                                        alert('reload !') // @todo: Connect refetch
                                    }}
                                >
                                    <LoopIcon />
                                </IconButton>
                            </Box>
                            <MessageList />
                        </Box>
                    </Box>
                </Box>
            </CustomPaper>
        </>
    )
}

export default DetailView
