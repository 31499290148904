import { useForm, type SubmitHandler } from "react-hook-form"
import Typography from '@mui/material/Typography'
import { useAtom } from "jotai"

import useFormLocalStorage from "@/hooks/use-form-local-storage"
import { APP_VERSION } from "@/constants/general"
import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import type { IllegalDumpPublicList, IllegalDumpStateChangeForm } from "@/types"
import RHFTextfield from "@/components/form-fields/rhf-textfield"
import { useInProgressIllegalDump } from "@/hooks/api/use-illegal-dump"
import { selectedMunicipalityIdAtom } from "@/state"

const IllegalDumpInProgressForm = ({
    illegalDump,
    closeCallback,
} : {
    illegalDump: IllegalDumpPublicList,
    closeCallback?: () => void,
}) => {
    
    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)
    
    const formDataId = `illegalDumpInProgressForm-${illegalDump?.id}-v${APP_VERSION}`

    const initialValues: IllegalDumpStateChangeForm = {
        description: '',
    }
    
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const [isLoadedFromLocalStorage, handleFormReset] = useFormLocalStorage({
        control,
        formDataId,
        reset,
        initialValues
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
    } = useFormStates(handleFormReset)

    const mutation = useInProgressIllegalDump(
        illegalDump.id,
        municipalityId,
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback() {
                closeCallback()
            },
        },
    )

    const onSubmit: SubmitHandler<IllegalDumpStateChangeForm> = data => {
        setRequestInProgress(true)
        mutation.mutate(data)
    }

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={isLoadedFromLocalStorage}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
            buttonText="Změnit stav"
        >
            <Typography variant="h5" component="h3" gutterBottom>
                Změna stavu na 'V řešení'
            </Typography>
            <RHFTextfield
                name="description"
                control={control}
                label="Poznámka"
            />
        </Form>
    )
}

export default IllegalDumpInProgressForm
