import { useForm, Controller, type SubmitHandler } from "react-hook-form"
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import useFormLocalStorage from "@/hooks/use-form-local-storage"
import { APP_VERSION, ROUTE_SEGMENTS } from "@/constants/general"
import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import RHFNumberTextfield from "../form-fields/rhf-number-textfield"
import FormRow from "./reusables/form-row"
import type { MotivationModel, MotivationModelCollectionFormInput } from "@/types"
import { useUpdateMotivationModelCollection, useUpsertBinDumpCosts } from "@/hooks/api/use-motivation-model"
import { convertFromCents } from "@/helpers"
import { useNavigate } from "react-router-dom"

const MotivationModelCollectionForm = ({
    motivationModel,
    closeCallback,
} : {
    motivationModel: MotivationModel,
    closeCallback?: () => void,
}) => {
    
    const navigate = useNavigate()
    
    const formDataId = `motivationModelCollectionForm-${motivationModel?.id}-v${APP_VERSION}`

    const mappedCosts = motivationModel.bin_dump_costs.map(cost => 
        convertFromCents(cost.amount_cents)
    )

    const initialValues: MotivationModelCollectionFormInput = motivationModel.bin_dump_costs.reduce((acc, item) => {
        const { volume, amount_cents, eoko_waste_type_id } = item
        const amount = convertFromCents(amount_cents).toString()
      
        if (volume === 110 && eoko_waste_type_id === null) {
          acc.small110 = amount
        } else if (volume === 120 && eoko_waste_type_id === null) {
          acc.small120 = amount
        } else if (volume === 240 && eoko_waste_type_id === null) {
          acc.small240 = amount
        } else if (volume === 1100 && eoko_waste_type_id === null) {
          acc.big1100 = amount
        } else if (volume === 1100 && (eoko_waste_type_id === 6 || eoko_waste_type_id === 7)) {
          acc.big1100Glass = amount
        } else if (volume === 1500 && (eoko_waste_type_id === 6 || eoko_waste_type_id === 7 || eoko_waste_type_id === 11)) {
          acc.big1500GlassAndMetal = amount
        }

        if (acc.small110 === acc.small120 && acc.small110 === acc.small240) {
            acc.smallAll = acc.small110
            acc.smallExtended = false
        } else {
            acc.smallExtended = true
        }
      
        return acc
      }, {
        smallAll: '',
        small110: '',
        small120: '',
        small240: '',
        big1100: '',
        big1100Glass: '',
        big1500GlassAndMetal: '',
        smallExtended: null,
        mixed_amount: motivationModel?.mixed_amount_cents != null
            ? convertFromCents(motivationModel.mixed_amount_cents)
            : '',
        environment_payment: motivationModel?.environment_payment_cents != null
            ? convertFromCents(motivationModel.environment_payment_cents)
            : '',
        bio_amount: motivationModel?.bio_amount_cents != null
            ? convertFromCents(motivationModel.bio_amount_cents)
            : '',
        paper_amount: motivationModel?.paper_amount_cents != null
            ? convertFromCents(motivationModel.paper_amount_cents)
            : '',
        plastic_amount: motivationModel?.plastic_amount_cents != null
            ? convertFromCents(motivationModel.plastic_amount_cents)
            : '',
      })
    
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
        watch,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const [isLoadedFromLocalStorage, handleFormReset] = useFormLocalStorage({
        control,
        formDataId,
        reset,
        initialValues
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
    } = useFormStates(handleFormReset)

    const mutationUpdate = useUpdateMotivationModelCollection(
        motivationModel?.id,
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback(motivationModel) {
                navigate(`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipalityManagement}/${ROUTE_SEGMENTS.motivationModel}/${motivationModel.id}/${ROUTE_SEGMENTS.step}/1`)
            },
        },
    )

    const mutationUpsert = useUpsertBinDumpCosts(
        motivationModel.id,
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback() {
                navigate(`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipalityManagement}/${ROUTE_SEGMENTS.motivationModel}/${motivationModel.id}/${ROUTE_SEGMENTS.step}/1`)
            },
        },
    )

    const onSubmit: SubmitHandler<MotivationModelCollectionFormInput> = data => {
        setRequestInProgress(true)
        mutationUpdate.mutate(data)
        mutationUpsert.mutate(data)
    }

    const smallExtended = watch('smallExtended')

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={isLoadedFromLocalStorage}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
            buttonText={mappedCosts.length > 0 ? "Upravit" : "Uložit a pokračovat"}
        >
            <Typography variant="h5" component="h3" gutterBottom>
                Zadejte ceny za odstranění odpadu
            </Typography>
            <FormRow>
                <RHFNumberTextfield
                    name="mixed_amount"
                    control={control}
                    label="SKO"
                    endAdornment="Kč s DPH/tuna"
                    decimalScale={2}
                    thousandSeparator
                    disallowNegative
                />
                <RHFNumberTextfield
                    name="plastic_amount"
                    control={control}
                    label="Plast"
                    endAdornment="Kč s DPH/tuna"
                    decimalScale={2}
                    thousandSeparator
                    disallowNegative
                />
            </FormRow>
            <FormRow>
                <RHFNumberTextfield
                    name="bio_amount"
                    control={control}
                    label="Bio"
                    endAdornment="Kč s DPH/tuna"
                    decimalScale={2}
                    thousandSeparator
                    disallowNegative
                />
                <RHFNumberTextfield
                    name="paper_amount"
                    control={control}
                    label="Papír"
                    endAdornment="Kč s DPH/tuna"
                    decimalScale={2}
                    thousandSeparator
                    disallowNegative
                />
            </FormRow>
            <Box/>
            <RHFNumberTextfield
                name="environment_payment"
                control={control}
                label="Platba do fondu životního prostředí"
                endAdornment="Kč s DPH/tuna"
                decimalScale={2}
                thousandSeparator
                disallowNegative
            />
            <Box/>
            <Typography variant="h5" component="h3" gutterBottom>
            Zadejte ceny za výklop
            </Typography>
            {smallExtended ?
                <>
                    <RHFNumberTextfield
                        name="small110"
                        control={control}
                        label="Malá nádoba 110l"
                        endAdornment="Kč s DPH/výklop"
                        decimalScale={2}
                        thousandSeparator
                        disallowNegative
                        required
                    />
                    <RHFNumberTextfield
                        name="small120"
                        control={control}
                        label="Malá nádoba 120l"
                        endAdornment="Kč s DPH/výklop"
                        decimalScale={2}
                        thousandSeparator
                        disallowNegative
                        required
                    />
                    <RHFNumberTextfield
                        name="small240"
                        control={control}
                        label="Malá nádoba 240l"
                        endAdornment="Kč s DPH/výklop"
                        decimalScale={2}
                        thousandSeparator
                        disallowNegative
                        required
                    />
                </>
            :
                <RHFNumberTextfield
                    name="smallAll"
                    control={control}
                    label="Malé nádoby (110l, 120l a 240l)"
                    endAdornment="Kč s DPH/výklop"
                    decimalScale={2}
                    thousandSeparator
                    disallowNegative
                    required
                />
            }
            <Box mx="auto" mb={1}>
                <FormControlLabel control={
                    <Controller
                        name="smallExtended"
                        control={control}
                        render={({ field }) => <Switch
                            {...field}
                            value={!!field.value}
                            checked={!!field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                        />}
                    />
                } label="Rozšířené nastavení malých nádob" />
            </Box>
            <RHFNumberTextfield
                name="big1100"
                control={control}
                label="Kontejner (1100l)"
                endAdornment="Kč s DPH/výklop"
                decimalScale={2}
                thousandSeparator
                disallowNegative
            />
            <RHFNumberTextfield
                name="big1100Glass"
                control={control}
                label="Kontejner - sklo (1100l)"
                endAdornment="Kč s DPH/výklop"
                decimalScale={2}
                thousandSeparator
                disallowNegative
            />
            <RHFNumberTextfield
                name="big1500GlassAndMetal"
                control={control}
                label="Kontejner - sklo a kov (1500l)"
                endAdornment="Kč s DPH/výklop"
                decimalScale={2}
                thousandSeparator
                disallowNegative
            />
        </Form>
    )
}

export default MotivationModelCollectionForm
