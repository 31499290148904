import axios from 'axios'
import {
    useQuery,
} from '@tanstack/react-query'
import type {
    CollectionCompany,
} from '@/types'

export function useCollectionCompanies() {
    return useQuery({
        queryKey: ['collectionCompanies'],
        queryFn: async () => {
            const { data } : { data: Array<CollectionCompany> } = await axios.get(
                '/api/collection_company/list',
            )
            return data
        },
    })
}
