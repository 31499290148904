import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'

import type { CommunicationMessage } from '@/types'

function CommunicationThreadMessage({
    message,
}: {
    message: CommunicationMessage,
}) {

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
            padding: message.recipient?.id ? '0 0 0 2rem' : '0 2rem 0 0',
        }}>
            
            <Box sx={{
                p: 1.5,
                borderRadius: 2,
                backgroundColor: message.recipient?.id ? 'messages.authority' : 'messages.user',
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: "0.875rem",
                    mb: 1,
                }}>
                    <Box>
                        {message.sender.email}
                    </Box>
                    <Box>
                        {dayjs(message.created_at).format('D. M. YYYY HH:mm')}
                    </Box>
                </Box>
                <Typography variant="body2" component="div">
                    {message.content}
                </Typography>
            </Box>
        </Box>
    )
}

export default CommunicationThreadMessage
