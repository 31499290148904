import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useTipTapExtensions from '@/hooks/use-tip-tap-extensions'
import { RichTextReadOnly } from 'mui-tiptap'

export default function RichText({ content }: { content: string | null | undefined }) {

    const extensions = useTipTapExtensions()

    return (
        <>
            {content ?
                <Box minHeight={60}>
                    <RichTextReadOnly content={content} extensions={extensions} />
                </Box>
                :
                <Typography variant="body2" component="p" minHeight={60}>
                {'-'}
                </Typography>
            }
        </>
    )
}
