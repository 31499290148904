import { useState } from 'react'
import type { MotivationModel, ScenarioParameters } from '@/types'
import { ScenarioTypes } from '@/types'
import { convertFromCents } from '@/helpers'
import { MAX_CITIZEN_FEE_CENTS } from '@/constants/general'

interface ParametersWrapperProps {
    motivationModel: MotivationModel
    children: ({parameters, setParameters}: {
        parameters: ScenarioParameters,
        setParameters: React.Dispatch<React.SetStateAction<ScenarioParameters>>,
    }) => React.ReactNode
}

const ParametersWrapper: React.FC<ParametersWrapperProps> = ({
    motivationModel,
    children,
}) => {
    // TODO: Solve edge case when mixed_weight_categories are not yet created in a better way than forcing 0
    const highestDiscount = motivationModel.mixed_weight_categories?.length > 0 ?
            (Math.max(...motivationModel.mixed_weight_categories.map(item => item.discount_amount_cents))+
            Math.max(...motivationModel.mixed_volume_categories.map(item => item.discount_amount_cents)))
            /100
            :
            0

    const [parameters, setParameters] = useState<ScenarioParameters>({
        scenario: motivationModel.selected_scenario ?? ScenarioTypes.pesimistic,
        annualSurcharge:
            motivationModel.annual_surcharge_cents != null
                ? convertFromCents(motivationModel.annual_surcharge_cents).toFixed(0)
                : null,
        citizenFee:
            motivationModel.citizen_fee_cents != null
                ? convertFromCents(motivationModel.citizen_fee_cents) < highestDiscount
                    ? highestDiscount.toFixed(0)
                    : convertFromCents(motivationModel.citizen_fee_cents).toFixed(0)
                : convertFromCents(MAX_CITIZEN_FEE_CENTS).toString(),
        lastChange: null,
    })

    return <>{children({ parameters, setParameters })}</>
}

export default ParametersWrapper
