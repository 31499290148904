import type { FC, ReactNode } from 'react'
import L from 'leaflet'
import { Marker } from 'react-leaflet'

interface CustomHomeMarkerProps {
    position:  L.LatLngExpression
    children?: ReactNode
}

const CustomHomeMarker: FC<CustomHomeMarkerProps> = ({ position, children }) => {

    const customIcon = L.icon({
        iconUrl: '/pin-place.png',
        iconSize: [42, 48],
        iconAnchor: [22, 36],
    })

    return (
        <Marker position={position} icon={customIcon}>
            {children}
        </Marker>
    )
}

export default CustomHomeMarker
