import Radio, { type RadioProps } from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Controller, type Control, type Path, type FieldValues } from "react-hook-form"

interface RHFRadioGroupProps<
    TField extends FieldValues,
> {
    control: Control<TField>,
    name: Path<TField>,
    label?: string,
    size?: RadioProps["size"],
    options: {
        value: string,
        label: string,
    }[],
}

const RHFRadioGroup = <
    TField extends FieldValues,
>(
    props: RHFRadioGroupProps<TField>
) => {

    const {
        control,
        name,
        label,
        size,
        options,
    } = props

    return (
        <Controller
            name={name}
            control={control}    
            render={({ field }) => (
                <RadioGroup
                    aria-label={label}
                    row
                    {...field}
                >
                    {options.map(option => (
                        <FormControlLabel
                            key={option.value}    
                            value={option.value}  
                            control={<Radio size={size} />}
                            label={option.label}
                        />
                    ))}
                </RadioGroup>
            )}
            
        />
    )
}

export default RHFRadioGroup
