import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Helmet } from 'react-helmet-async'

import CustomPaper from '@/components/custom-paper'
import CollectedWasteStatsTabs from '@/components/collected-waste-stats/tabs'

function CollectedWasteStatsView() {

    const title = 'Produkce odpadu'

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <CustomPaper>
                <Box mb={2.5}>
                    <Typography variant="h2" component="h1">
                        {title}
                    </Typography>
                </Box>
                <CollectedWasteStatsTabs />
            </CustomPaper>
        </>
    )
}

export default CollectedWasteStatsView
