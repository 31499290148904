import { useForm, type SubmitHandler } from "react-hook-form"

import useFormLocalStorage from "@/hooks/use-form-local-storage"
import { APP_VERSION } from "@/constants/general"
import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import RHFRichText from "../form-fields/rhf-rich-text"
import RHFTextfield from "../form-fields/rhf-textfield"
import type { CollectionNotification, CollectionNotificationFormInput } from "@/types"
import { useCreateCollectionNotification, useUpdateCollectionNotification } from "@/hooks/api/use-collection-notification"
import RHFRadioGroup from "../form-fields/rhf-radio-group"

const CollectionNotificationForm = ({
    collectionNotification,
    closeCallback,
} : {
    collectionNotification?: CollectionNotification,
    closeCallback?: () => void,
}) => {

    const formDataId = `collectionNotificationForm-${collectionNotification?.id}-v${APP_VERSION}`

    const initialValues = {
        priority: collectionNotification ? collectionNotification.priority : 'medium',
        title: collectionNotification ? collectionNotification.title : '',
        description: collectionNotification ? collectionNotification.description : '',
    }
    
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const [isLoadedFromLocalStorage, handleFormReset] = useFormLocalStorage({
        control,
        formDataId,
        reset,
        initialValues
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
    } = useFormStates(handleFormReset)

    const mutationCreate = useCreateCollectionNotification({
        setRequestInProgress,
        formDataId,
        onSuccessCallback: closeCallback,
    })

    const mutationUpdate = useUpdateCollectionNotification(
        collectionNotification?.id,
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback: closeCallback,
        },
    )

    const onSubmit: SubmitHandler<CollectionNotificationFormInput> = data => {
        setRequestInProgress(true)
        if (collectionNotification?.id) {
            mutationUpdate.mutate(data)
        } else {
            mutationCreate.mutate(data)
        }
    }

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            heading={collectionNotification ? 'Upravit upozornění' : 'Vytvořit nové upozornění'}
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={isLoadedFromLocalStorage}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
        >
            <RHFTextfield
                name="title"
                control={control}
                label="Titulek"
                required
            />
            <RHFRadioGroup
                name="priority"
                control={control}
                label="Důležitost"
                options={[
                    {
                        value: 'low',
                        label: 'Nízká',
                    },
                    {
                        value: 'medium',
                        label: 'Střední',
                    },
                    {
                        value: 'high',
                        label: 'Vysoká',
                    },
                ]}
            />
            <RHFRichText
                name="description"
                control={control}
                label="Obsah upozornění"
            />
        </Form>
    )
}

export default CollectionNotificationForm
