import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import 'leaflet/dist/leaflet.css'
import { AttributionControl, MapContainer, TileLayer } from 'react-leaflet'

import { useIllegalDump } from '@/hooks/api/use-illegal-dump'
import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"
import SingleDumpDetail from './single-dump-detail'
import CustomDumpMarker from './custom-dump-marker'

function SingleDumpWrapper() {

    const { illegalDumpId } = useParams()
    
    const { status, data, error } = useIllegalDump(Number(illegalDumpId))

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst hlášení černé skládky s ID ${illegalDumpId}`}
        />
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: {
                xs: 'column',
                lg: 'row',
            },
        }}>
            <Box sx={{
                flexBasis: {
                    xs: '100%',
                    lg: '40%',
                },
                pr: {
                    xs: 0,
                    lg: 3,
                },
            }}>
                <SingleDumpDetail illegalDump={data} />
            </Box>
            <Box sx={(theme) => ({
                flexBasis: {
                    xs: '100%',
                    lg: '60%',
                },
                pl: {
                    xs: 2,
                    lg: 0,
                },
                pr: 2,
                pt: {
                    xs: 3,
                    lg: 0,
                },
                borderTop: {
                    xs: `1px solid ${theme.palette.customGrey.divider}`,
                    lg: '0px',
                },
            })}>
                <Box sx={{
                    borderRadius: 2.5,
                    overflow: 'hidden',
                    width: '100%',
                    height: {
                        xs: '50vh',
                        xl: 'calc(100vh - 238px)',
                    },
                    '& .leaflet-container': {
                        width: '100%',
                        height: {
                            xs: '50vh',
                            xl: 'calc(100vh - 238px)',
                        },
                    },
                }}>
                    <MapContainer center={[Number.parseFloat(data.latitude), Number.parseFloat(data.longitude)]} zoom={15} scrollWheelZoom={true} attributionControl={false}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <AttributionControl position="bottomright" prefix={false} />
                        <CustomDumpMarker
                            marker={data}
                            selectedId={data.id}
                            setSelectedId={() => {}}
                        />
                    </MapContainer>
                </Box>
            </Box>
        </Box>
    )
}

export default SingleDumpWrapper
