import { useState, useEffect, type MouseEvent } from 'react'
import { useAtom } from 'jotai'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useNavigate } from "react-router-dom"
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/cs'
import CachedRoundedIcon from '@mui/icons-material/CachedRounded'
import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from '@mui/material/LinearProgress'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import HouseRoundedIcon from '@mui/icons-material/HouseRounded'
import CabinRoundedIcon from '@mui/icons-material/CabinRounded'
import RecyclingRoundedIcon from '@mui/icons-material/RecyclingRounded'
import ApartmentIcon from '@mui/icons-material/Apartment'
import BlockIcon from '@mui/icons-material/Block'
import dayjs from 'dayjs'

import { selectedCollectionPointIdAtom, selectedMunicipalityIdAtom } from "@/state"
import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"
import { useCollectionPointCategoryList, useRefreshHouseholdCategories } from '@/hooks/api/use-municipality'
import useDateInterval from '@/hooks/use-date-interval'
import { CollectionPointTypes, type Categories, type Pagination } from '@/types'
import { COLLECTION_POINT_TYPE_LABEL_MAP, ROUTE_SEGMENTS } from '@/constants/general'
import CategorySelector from './category-selector'
import PaginationControls from '@/components/pagination-controls'
import { Typography } from '@mui/material'
import MotivationModelSelector from './motivation-model-selector'
import TypeSelector from './type-selector'
import NumberTextfield from '@/components/form-fields/number-textfield'
import { convertFromCents, printPrice, roundPrice } from '@/helpers'
import CollectionPointCategoriesExport from './export'

const COLLECTION_POINT_TYPE_ICON_MAP = {
    [CollectionPointTypes.apartmentBuilding]: <ApartmentIcon/>,
    [CollectionPointTypes.holidayHome]: <CabinRoundedIcon/>,
    [CollectionPointTypes.familyHouse]: <HouseRoundedIcon/>,
    [CollectionPointTypes.publicWastepoint]: <RecyclingRoundedIcon />,
    [CollectionPointTypes.unknown]: <BlockIcon/>,
}

const CATEGORIES_COLORS_MAP = {
    '1': 'categories.first',
    '2': 'categories.second',
    '3': 'categories.third',
    '4': 'categories.fourth',
    '5': 'categories.fifth',
}

function CollectionPointCategoriesList() {

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)
    const [_collectionPointId, setCollectionPointId] = useAtom(selectedCollectionPointIdAtom)

    const [motivationModelId, setMotivationModelId] = useState<number | null>(null)
    const [requestInProgress, setRequestInProgress] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    
    const [pagination, setPagination] = useState<Pagination>({
        limit: 20,
        offset: 0,
    })

    const handleResetPagination = () => {
        setPagination({
            limit: 20,
            offset: 0,
        })
    }

    const {
        handleSetDates,
        endDate,
        endDateValue,
        setEndDateValue,
    } = useDateInterval({
        callback: handleResetPagination
    })

    const [mixedWeightCategories, setMixedWeightCategories] = useState<Categories[]>([])
    const [mixedVolumeCategories, setMixedVolumeCategories] = useState<Categories[]>([])
    const [types, setTypes] = useState<CollectionPointTypes[]>([])
    const [numberOfMembers, setNumberOfMembers] = useState<string>('')

    const handleNumberOfMembersChange = ({
        target: {
            value,
        },
    }) => {
        setNumberOfMembers(value)
    }

    const navigate = useNavigate()

    const handleClickDetail = (_event: MouseEvent<unknown>, id: number) => {
        setCollectionPointId(id)
        navigate(`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.collectionPoint}/${ROUTE_SEGMENTS.detail}`)
    }

    const handleClickTrend = (_event: MouseEvent<unknown>, id: number) => {
        setCollectionPointId(id)
        navigate(`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.collectionPoint}/${ROUTE_SEGMENTS.disposalTrend}`)
    }

    /*  biome-ignore lint: useEffect deps
        We want to run this in reaction to municipalityId change
    */
    useEffect(() => {
        handleResetPagination()
        setMotivationModelId(null)
    }, [municipalityId])

    const { status, data, error, isPlaceholderData } = useCollectionPointCategoryList(
        municipalityId,
        pagination,
        // endDate.format('YYYY-MM') as MonthYear,
        {
            mixedWeightCategories,
            mixedVolumeCategories,
            types,
            numberOfMembers,
        }
    )

    useEffect(() => {
        if (data?.motivation_model?.id) {
            setMotivationModelId(data.motivation_model.id)
        }
    }, [data?.motivation_model?.id])

    const mutation = useRefreshHouseholdCategories(
        {
            setRequestInProgress,
            onSuccessCallback(data) {
                if(data) {
                    setSuccess(true)
                }
            }
        },
    )

    const handleClickRefresh = () => {
        setRequestInProgress(true)
        mutation.mutate({ motivationModelId, municipalityId })
    }

    if (requestInProgress) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                flexGrow: 1,
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <CircularProgress />
                    <Typography variant='h6' component="div" mt={2}>
                        Přepočítávám kategorie
                    </Typography>
                    <Typography variant='body2' component="div">
                        Pro velké obce může tato operace trvat i několik minut.
                    </Typography>
                </Box>
            </Box>
        )
    }

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst svozová místa a jejich kategorie pro obec s ID ${municipalityId}`}
        />
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
            overflowX: 'auto',
            pb: 2,
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: {
                    xs: 'column',
                    xl: 'row',
                },
                flexWrap: 'wrap'
            }}>
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    py: 1,
                    flexWrap: 'wrap',
                    px: 3,
                    alignItems: 'center',
                    order: 1,
                    '@media (min-width: 1953px)': {
                        order: 2,
                    },
                }}>
                    <Typography variant="h6" component="h6">Kategorie</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
                        <DatePicker
                            label="K datu"
                            views={['year', 'month']}
                            // value={endDateValue}
                            value={dayjs().subtract(1, 'month')}
                            onChange={newDate => {
                                setEndDateValue(newDate)
                            }}
                            disabled
                            slotProps={{
                                textField: { size: 'small', sx: {
                                    maxWidth: 209,
                                }, },
                            }}
                        />
                    </LocalizationProvider>
                    <Box minWidth={220}>
                        <MotivationModelSelector
                            motivationModelId={motivationModelId}
                            setMotivationModelId={setMotivationModelId}
                            year={dayjs().subtract(1, 'month').year()}
                        />
                    </Box>
                    <Box>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={requestInProgress ? <CircularProgress color="inherit" size={20} /> : <CachedRoundedIcon />}
                            onClick={() => handleClickRefresh()}
                            disabled={requestInProgress || !motivationModelId}
                        >
                            Přepočítat
                        </Button>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    py: 1,
                    flexWrap: 'wrap',
                    px: 3,
                    alignItems: 'center',
                    order: 2,
                    '@media (min-width: 1953px)': {
                        order: 1,
                    },
                }}>
                    <Typography variant="h6" component="h6">Filtrovat</Typography>
                    <Box display="flex">
                        <CategorySelector
                            label="Kategorie hmotnost"
                            categories={mixedWeightCategories}
                            setCategories={setMixedWeightCategories}
                            callback={handleResetPagination}
                        />
                    </Box>
                    <Box display="flex">
                        <CategorySelector
                            label="Kategorie objem"
                            categories={mixedVolumeCategories}
                            setCategories={setMixedVolumeCategories}
                            callback={handleResetPagination}
                        />
                    </Box>
                    <Box display="flex" maxWidth={190}>
                        <TypeSelector
                            label="Typy"
                            types={types}
                            setTypes={setTypes}
                            callback={handleResetPagination}
                        />
                    </Box>
                    <Box display="flex">
                        <NumberTextfield
                            label="Počet členů"
                            onChange={(e) => handleNumberOfMembersChange(e)}
                            name='numberOfMembers'
                            value={numberOfMembers}
                            size='small'
                            thousandSeparator
                        />
                    </Box>
                    <CollectionPointCategoriesExport
                        filters={{
                            mixedWeightCategories,
                            mixedVolumeCategories,
                            types,
                            numberOfMembers,
                        }}
                    />
                </Box>
            </Box>
            {/* <Divider /> */}
            {success &&
                <Box px={3}>
                    <Alert
                        severity="success"
                        sx={(theme) => ({
                            flexGrow: 1,
                            margin: theme.spacing(1, 0),
                        })}
                        action={
                            <Button
                                color="success"
                                size="small"
                                onClick={() => setSuccess(false)}
                            >
                                Zavřít
                            </Button>
                        }
                    >
                        Kategorie úspěšně přepočítány.
                    </Alert>
                </Box>
            }
            <Box>
                <Table
                    aria-label="Roční statistika odpadu ve sběrných dvorech"
                    sx={{
                        mt: 1,
                        minWidth: 600,
                    }}
                    size='small'
                >
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ paddingLeft: 3 }}>Kód</TableCell>
                            <TableCell>Typ</TableCell>
                            <TableCell>Adresa</TableCell>
                            <TableCell>Správce</TableCell>
                            <TableCell>Počet členů</TableCell>
                            <TableCell>Kat. hmotnost</TableCell>
                            <TableCell>Kat. objem</TableCell>
                            <TableCell>Sleva celkem</TableCell>
                            <TableCell>Odkaz</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.households?.eoko_household.map(row => (
                            <TableRow
                                key={row.id}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                }}
                            >
                                <TableCell component="th" scope="row" sx={{ paddingLeft: 3 }}>
                                    {row.code}
                                </TableCell>
                                <TableCell sx={{color: 'customGrey.tableText'}}>
                                    <Tooltip title={COLLECTION_POINT_TYPE_LABEL_MAP[row.type]}>
                                        {COLLECTION_POINT_TYPE_ICON_MAP[row.type]}
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    {row.address?.street ?? '-'} {row.address?.street_number ?? '-'}
                                </TableCell>
                                <TableCell>
                                    {row.admin?.first_name ?? '-'} {row.admin?.last_name ?? '-'}
                                </TableCell>
                                <TableCell>
                                    {row.number_of_members ?? '-'}
                                </TableCell>
                                <TableCell>
                                    {row.ext?.mixed_weight_category?.level &&
                                        <Box sx={{
                                            backgroundColor: CATEGORIES_COLORS_MAP[row.ext.mixed_weight_category.level],
                                            width: 22,
                                            height: 22,
                                            borderRadius: 10,
                                            color: 'white',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>
                                            {row.ext.mixed_weight_category.level}
                                        </Box>
                                    }
                                </TableCell>
                                <TableCell>
                                    {row.ext?.mixed_volume_category?.level &&
                                        <Box sx={{
                                            backgroundColor: CATEGORIES_COLORS_MAP[row.ext.mixed_volume_category.level],
                                            width: 22,
                                            height: 22,
                                            borderRadius: 10,
                                            color: 'white',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>
                                            {row.ext.mixed_volume_category.level}
                                        </Box>
                                    }
                                </TableCell>
                                <TableCell>
                                    {row.total_discount_cents != null ? printPrice(roundPrice(convertFromCents(row.total_discount_cents))) : '-'}
                                </TableCell>
                                <TableCell>
                                    <Box display="flex">
                                        <Tooltip title="Detail">
                                            <IconButton
                                                onClick={(e) => handleClickDetail(e, row.id)}
                                            >
                                                <VisibilityRoundedIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Trend výsypu">
                                            <IconButton
                                                onClick={(e) => handleClickTrend(e, row.id)}
                                            >
                                                <TrendingUpRoundedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Box minHeight={4}>
                    {isPlaceholderData &&
                        <LinearProgress />
                    }
                </Box>
                <PaginationControls
                    pagination={pagination}
                    setPagination={setPagination}
                    currentArrayLength={data.households?.eoko_household.length}
                    disabled={isPlaceholderData}
                    recordsCount={data.households?.records_count}
                />
            </Box>
            {data.households?.records_count === 0 &&
                <Box px={2.5} pt={2}>
                    Obec neobsahuje žádné domácnosti a jejich kategorie
                </Box>
            }
        </Box>
    )
}

export default CollectionPointCategoriesList
