import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import dayjs from 'dayjs'

import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"
import CommunicationThreadDetailActions from '@/components/communication/thread-detail-actions'
import PermissionsGate from '@/components/permissions-gate'
import { UserRole } from '@/types'
import { useCommunication } from '@/hooks/api/use-communication'
import CommunicationThreadMessage from '@/components/communication/thread-message'
import CommunicationThreadMessageSend from '@/components/communication/thread-message-send'

function CommunicationThreadDetail({
    communicationId,
}: {
    communicationId: number,
}) {
    
    const { status, data, error } = useCommunication(communicationId)

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst detail komunikace ID ${communicationId}`}
        />
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            position: 'relative',
        }}>
            <Box sx={{
                display: 'flex',
                gap: 1,
            }}>
                <Box flexGrow={1}  sx={{ color: 'customGrey.tableText' }}>
                    <Typography variant="h5" component="h3">
                        ID
                    </Typography>
                    <Typography variant="body1" component="p">
                        {data.id}
                    </Typography>
                </Box>
                <Box flexGrow={1}  sx={{ color: 'customGrey.tableText' }}>
                    <Typography variant="h5" component="h3">
                        Aktualizováno
                    </Typography>
                    <Typography variant="body1" component="p">
                        {dayjs(data.updated_at).format('D. M. YYYY HH:mm')}
                    </Typography>
                </Box>
            </Box>
            <Box>
                <Typography variant="h5" component="h3"  sx={{ color: 'customGrey.tableText' }}>
                    Zprávy
                </Typography>
                <Box pt={1} pb={2}>
                    <Divider />
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1.5,
                }}>
                    <CommunicationThreadMessageSend communicationId={communicationId}/>
                    {data.messages.map(message => (
                        <CommunicationThreadMessage key={message.id} message={message} />
                    ))}  
                </Box>
            </Box>
            <PermissionsGate
                userRoles={[
                    UserRole.admin,
                    UserRole.municipalityOfficial,
                ]}
            >
                <Box sx={{
                    position: 'absolute',
                    top: -8,
                    right: -8,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: '1rem',
                }}>
                    {/* <CommunicationThreadDetailActions communicationThreadDetail={data} /> */}
                </Box>
            </PermissionsGate>
        </Box>
    )
}

export default CommunicationThreadDetail
