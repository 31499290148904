import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'

export default function FormLoadedAlert({
    handleFormReset,
}: {
    handleFormReset: () => void,
}) {

    return (
        <Alert
            severity="info"
            sx={(theme) => ({
                flexGrow: 1,
                margin: theme.spacing(1, 0),
            })}
            action={
                <Button
                    color="inherit"
                    size="small"
                    startIcon={<Icon>delete</Icon>}
                    onClick={() => handleFormReset()}
                    sx={{
                        whiteSpace: 'nowrap',
                        backgroundColor: 'errorBlueLight.main',
                        '&:hover': {
                            backgroundColor: 'errorBlueLight.dark',
                        }
                    }}
                >
                    Zrušit změny
                </Button>
            }
        >
            Byly načteny lokální neuložené změny.
        </Alert>
    )
}
