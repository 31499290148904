import React, { type ReactNode } from 'react'
import Box from '@mui/material/Box'

function FormRow({
    children,
}: {
    children: ReactNode,
}) {

    return (
        <Box sx={{
            display: 'flex',
            gap: 1.5,
        }}>
            {children}
        </Box>
    )
}

export default FormRow
