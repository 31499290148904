import { usePublicIllegalDump } from '@/hooks/api/use-illegal-dump'
import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"
import SingleDumpDetail from './single-dump-detail'

function SingleDumpPublicWrapper({
    illegalDumpIdFromProps,
    handleClose,
}: {
    illegalDumpIdFromProps: number,
    handleClose: () => void,
}) {
    
    const { status, data, error } = usePublicIllegalDump(illegalDumpIdFromProps)

    if (status === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={`Nepovedlo se načíst hlášení černé skládky s ID ${illegalDumpIdFromProps}`}
        />
    }

    return (
        <SingleDumpDetail illegalDump={data} handleClose={handleClose} />
    )
}

export default SingleDumpPublicWrapper
