import type { CollectionCompany } from '@/types'
import LoadingBox from '@/components/loading-box'
import ErrorBox from '@/components/error-box'
import { useCollectionCompanies } from '@/hooks/api/use-collection-company'

interface CollectionCompanyLoaderProps {
    children: (data: CollectionCompany) => React.ReactNode
}

const CollectionCompanyLoader: React.FC<CollectionCompanyLoaderProps> = ({
    children,
}) => {
    const { status, data, error } = useCollectionCompanies()

    if (status === 'pending') {
        return <LoadingBox />
    }

    if (error) {
        return <ErrorBox
            error={error}
            message={'Nepovedlo se načíst svozové společnosti.'}
        />
    }


    if (data.length > 0) {
        return <>{children(data[0])}</>
    }

    return <ErrorBox
        message={'Do systému je potřeba přidat svozovou společnost. Kontaktujte prosím vývojáře.'}
    />
}

export default CollectionCompanyLoader
