import { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useAtom } from 'jotai'
import { Helmet } from 'react-helmet-async'

import CustomPaper from '@/components/custom-paper'
import CollectionCompanyLoader from '@/wrappers/collection-company-loader'
import ContactDivisionListCollectionCompany from '@/components/contact/division-list-collection-company'
import ContactDivisionListMunicipality from '@/components/contact/division-list-municipality'
import PermissionsGate from '@/components/permissions-gate'
import { UserRole } from '@/types'
import EditModeToggle from '@/components/edit-mode-toggle'
import { selectedMunicipalityIdAtom } from '@/state'
import { Divider } from '@mui/material'

function ContactsView() {

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)
    
    const [editModeMunicipality, setEditModeMunicipality] = useState(false)
    const [editModeCollectionCompany, setEditModeCollectionCompany] = useState(false)

    return (
        <>
            <Helmet>
                <title>Kontakty</title>
            </Helmet>
            <CustomPaper>
                <Box position="relative">
                    <Typography variant="h2" component="h1" gutterBottom>
                        Kontakty - obec
                    </Typography>
                    <PermissionsGate
                        userRoles={[
                            UserRole.admin,
                            UserRole.municipalityOfficial,
                        ]}
                    >
                        <EditModeToggle
                            editMode={editModeMunicipality}
                            setEditMode={setEditModeMunicipality}
                        />
                    </PermissionsGate>
                </Box>
                <Box py={2}>
                    <ContactDivisionListMunicipality
                        municipalityId={municipalityId}
                        editMode={editModeMunicipality}
                    />
                </Box>
                <Divider sx={{ mt: 5, mb: 3 }}/>
                <Box position="relative">
                    <Typography variant="h2" component="h1" gutterBottom>
                        Kontakty - svozová společnost
                    </Typography>
                    <PermissionsGate
                        userRoles={[
                            UserRole.admin,
                            UserRole.municipalityOfficial,
                        ]}
                    >
                        <EditModeToggle
                            editMode={editModeCollectionCompany}
                            setEditMode={setEditModeCollectionCompany}
                        />
                    </PermissionsGate>
                </Box>
                <Box py={2}>
                    <CollectionCompanyLoader>
                        {collectionCompany => (
                            <ContactDivisionListCollectionCompany
                                collectionCompanyId={collectionCompany.id}
                                editMode={editModeCollectionCompany}
                            />
                        )}
                    </CollectionCompanyLoader>
                </Box>
            </CustomPaper>
        </>
    )
}

export default ContactsView
