import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import CategoryLabel from '@/components/category-label'
import type { MotivationModelScenario } from '@/types'

export default function ScenarioResults({
    scenario,
    isLoading,
}: {
    scenario: MotivationModelScenario,
    isLoading: boolean,
}) {

    const totalCitizensCount = scenario.categorized_households.sum_category.citizens_count

    const weightCategories = [
        {
            text: 'Kat.\xa01',
            severity: 1,
            color: 'categories.first',
            amount_weight: scenario.categorized_households.mixed_weight_categories[1].discount_per_citizen/100,
            citizens_weight: scenario.categorized_households.mixed_weight_categories[1].citizens_count,
            percentage_weight: (scenario.categorized_households.mixed_weight_categories[1].citizens_count/(totalCitizensCount/100)).toFixed(2),
            amount_volume: scenario.categorized_households.mixed_volume_categories[1].discount_per_citizen/100,
            citizens_volume: scenario.categorized_households.mixed_volume_categories[1].citizens_count,
            percentage_volume: (scenario.categorized_households.mixed_volume_categories[1].citizens_count/(totalCitizensCount/100)).toFixed(2),
        },
        {
            text: 'Kat.\xa02',
            severity: 2,
            color: 'categories.second',
            amount_weight: scenario.categorized_households.mixed_weight_categories[2].discount_per_citizen/100,
            citizens_weight: scenario.categorized_households.mixed_weight_categories[2].citizens_count,
            percentage_weight: (scenario.categorized_households.mixed_weight_categories[2].citizens_count/(totalCitizensCount/100)).toFixed(2),
            amount_volume: scenario.categorized_households.mixed_volume_categories[2].discount_per_citizen/100,
            citizens_volume: scenario.categorized_households.mixed_volume_categories[2].citizens_count,
            percentage_volume: (scenario.categorized_households.mixed_volume_categories[2].citizens_count/(totalCitizensCount/100)).toFixed(2),
        },
        {
            text: 'Kat.\xa03',
            severity: 3,
            color: 'categories.third',
            amount_weight: scenario.categorized_households.mixed_weight_categories[3].discount_per_citizen/100,
            citizens_weight: scenario.categorized_households.mixed_weight_categories[3].citizens_count,
            percentage_weight: (scenario.categorized_households.mixed_weight_categories[3].citizens_count/(totalCitizensCount/100)).toFixed(2),
            amount_volume: scenario.categorized_households.mixed_volume_categories[3].discount_per_citizen/100,
            citizens_volume: scenario.categorized_households.mixed_volume_categories[3].citizens_count,
            percentage_volume: (scenario.categorized_households.mixed_volume_categories[3].citizens_count/(totalCitizensCount/100)).toFixed(2),
        },
        {
            text: 'Kat.\xa04',
            severity: 4,
            color: 'categories.fourth',
            amount_weight: scenario.categorized_households.mixed_weight_categories[4].discount_per_citizen/100,
            citizens_weight: scenario.categorized_households.mixed_weight_categories[4].citizens_count,
            percentage_weight: (scenario.categorized_households.mixed_weight_categories[4].citizens_count/(totalCitizensCount/100)).toFixed(2),
            amount_volume: scenario.categorized_households.mixed_volume_categories[4].discount_per_citizen/100,
            citizens_volume: scenario.categorized_households.mixed_volume_categories[4].citizens_count,
            percentage_volume: (scenario.categorized_households.mixed_volume_categories[4].citizens_count/(totalCitizensCount/100)).toFixed(2),
        },
        {
            text: 'Kat.\xa05',
            severity: 5,
            color: 'categories.fifth',
            amount_weight: scenario.categorized_households.mixed_weight_categories[5].discount_per_citizen/100,
            citizens_weight: scenario.categorized_households.mixed_weight_categories[5].citizens_count,
            percentage_weight: (scenario.categorized_households.mixed_weight_categories[5].citizens_count/(totalCitizensCount/100)).toFixed(2),
            amount_volume: scenario.categorized_households.mixed_volume_categories[5].discount_per_citizen/100,
            citizens_volume: scenario.categorized_households.mixed_volume_categories[5].citizens_count,
            percentage_volume: (scenario.categorized_households.mixed_volume_categories[5].citizens_count/(totalCitizensCount/100)).toFixed(2),
        },
    ]
    
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: {
                xs: 'column',
                sm: 'row',
            },
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: 3,
            mt: 4,
            opacity: isLoading ? 0.3 : 'unset',
        }}>
            <Box>
                {/* <Typography variant="h5" component="h3" mb={2} textAlign="center">
                    Slevy
                </Typography> */}
                <Box sx={{
                    px: 3,
                    mb: 1,
                }}>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <td>
                                    <Typography variant="h5" component="span">
                                        Slevy:&nbsp;&nbsp;&nbsp;
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '1rem' }}>
                                    <Typography variant="h5" component="span">
                                        Hmotnost
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'right', paddingLeft: '1rem' }}>
                                    <Typography variant="h5" component="span">
                                        Objem
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Box>
                <Box sx={(theme) => ({
                    borderRadius: 2.5,
                    border: `1px solid ${theme.palette.divider}`,
                    px: 3,
                    py: 1.5,
                    color: theme.palette.customGrey.tableText,
                })}>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            {weightCategories.map(category => (
                                <tr key={category.text}>
                                    <td>
                                        <CategoryLabel
                                            text={category.text}
                                            severity={category.severity}
                                            color={category.color}
                                            small
                                        />
                                    </td>
                                    <td style={{ textAlign: 'right', paddingLeft: '1rem' }}>
                                        <Typography variant="h5" component="span">
                                            {category.amount_weight}&nbsp;Kč
                                        </Typography>
                                    </td>
                                    <td style={{ textAlign: 'right', paddingLeft: '1rem' }}>
                                        <Typography variant="h5" component="span">
                                            {category.amount_volume}&nbsp;Kč
                                        </Typography>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Box>
            </Box>
            <Box>
                <Typography variant="h5" component="h3" mb={2} textAlign="center">
                    Počet občanů v kat. dle hmotnosti
                </Typography>
                <Box sx={(theme) => ({
                    borderRadius: 2.5,
                    border: `1px solid ${theme.palette.divider}`,
                    px: 3,
                    py: 1.5,
                    color: theme.palette.customGrey.tableText,
                })}>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            {weightCategories.map(categoryCount => (
                                <tr key={categoryCount.text}>
                                    <td>
                                        <Typography variant="h5" component="span">
                                            {categoryCount.text}
                                        </Typography>
                                    </td>
                                    <td style={{ textAlign: 'right', paddingLeft: '2rem' }}>
                                        <Box component="span">
                                            {categoryCount.citizens_weight}
                                        </Box>
                                    </td>
                                    <td style={{ textAlign: 'right', paddingLeft: '2rem' }}>
                                        <Box component="span">
                                            {categoryCount.percentage_weight}%
                                        </Box>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Box>
            </Box>
            <Box>
                <Typography variant="h5" component="h3" mb={2} textAlign="center">
                    Počet občanů v kat. dle objemu
                </Typography>
                <Box sx={(theme) => ({
                    borderRadius: 2.5,
                    border: `1px solid ${theme.palette.divider}`,
                    px: 3,
                    py: 1.5,
                    color: theme.palette.customGrey.tableText,
                })}>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            {weightCategories.map(categoryCount => (
                                <tr key={categoryCount.text}>
                                    <td>
                                        <Typography variant="h5" component="span">
                                            {categoryCount.text}
                                        </Typography>
                                    </td>
                                    <td style={{ textAlign: 'right', paddingLeft: '2rem' }}>
                                        <Box component="span">
                                            {categoryCount.citizens_volume}
                                        </Box>
                                    </td>
                                    <td style={{ textAlign: 'right', paddingLeft: '2rem' }}>
                                        <Box component="span">
                                            {categoryCount.percentage_volume}%
                                        </Box>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Box>
            </Box>
        </Box>
    )
}
