import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'

import { CollectionPointTypes } from '@/types'
import { COLLECTION_POINT_TYPE_LABEL_MAP } from '@/constants/general'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const options = Object.values(CollectionPointTypes)

export default function TypeSelector({
    types,
    setTypes,
    callback,
    label = 'Typy',
}: {
    types,
    setTypes,
    callback?: () => void,
    label?: string,
}) {

    const handleChange = (event: SelectChangeEvent<typeof types>) => {
        const {
            target: { value },
        } = event
        setTypes(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
        if (callback) callback()
    }

    return (
        <FormControl sx={{ minWidth: 190, flexGrow: 1 }}>
            <InputLabel id="categories-select-label" size='small'>{label}</InputLabel>
            <Select
                labelId="categories-select-label"
                id="categories-select"
                multiple
                value={types}
                onChange={handleChange}
                input={<OutlinedInput label={label} />}
                renderValue={selected => selected.map(value => COLLECTION_POINT_TYPE_LABEL_MAP[value]).join(', ')}
                MenuProps={MenuProps}
                size='small'
            >
                {options.map(option => (
                    <MenuItem key={option} value={option}>
                        <Checkbox checked={types.includes(option)} />
                        <ListItemText primary={COLLECTION_POINT_TYPE_LABEL_MAP[option]} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
