import { useForm, type SubmitHandler } from "react-hook-form"
import Typography from '@mui/material/Typography'

import useFormLocalStorage from "@/hooks/use-form-local-storage"
import { APP_VERSION } from "@/constants/general"
import Form from "@/components/forms/reusables/form"
import useFormStates from "@/hooks/use-form-states"
import RHFRichText from "@/components/form-fields/rhf-rich-text"
import type {
    PayLessInstruction,
    PayLessInstructionFormInput,
} from "@/types"
import {
    useCreatePayLessInstruction,
    useUpdatePayLessInstruction,
} from "@/hooks/api/use-pay-less-instruction"

const HowToPayLessForm = ({
    payLessInstruction,
    closeCallback,
} : {
    payLessInstruction?: PayLessInstruction,
    closeCallback?: () => void,
}) => {

    const formDataId = `howToPayLessForm-${payLessInstruction?.id}-v${APP_VERSION}`

    const initialValues = {
        description: payLessInstruction ? payLessInstruction.description : '',
    }
    
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
        watch,
    } = useForm({
        defaultValues: { ...initialValues },
    })

    const [isLoadedFromLocalStorage, handleFormReset] = useFormLocalStorage({
        control,
        formDataId,
        reset,
        initialValues
    })

    const {
        resetWithVersion,
        requestInProgress,
        setRequestInProgress,
    } = useFormStates(handleFormReset)

    const mutationCreate = useCreatePayLessInstruction({
        setRequestInProgress,
        formDataId,
        onSuccessCallback: closeCallback,
    })

    const mutationUpdate = useUpdatePayLessInstruction(
        payLessInstruction?.id,
        {
            setRequestInProgress,
            formDataId,
            onSuccessCallback: closeCallback,
        },
    )

    const onSubmit: SubmitHandler<PayLessInstructionFormInput> = data => {
        setRequestInProgress(true)
        if (payLessInstruction?.id) {
            mutationUpdate.mutate(data)
        } else {
            mutationCreate.mutate(data)
        }
    }

    const description = watch('description')

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            heading={payLessInstruction ? 'Upravit obsah' : 'Vytvořit sekci'}
            closeCallback={closeCallback}
            isLoadedFromLocalStorage={isLoadedFromLocalStorage}
            isDirty={isDirty}
            resetWithVersion={resetWithVersion}
            requestInProgress={requestInProgress}
            buttonText={payLessInstruction ? 'Upravit' : 'Vytvořit sekci'}
            disableSave={!description}
        >
            <RHFRichText
                name="description"
                control={control}
                label="Obsah *"
            />
            {!payLessInstruction &&
                <Typography variant="body1" component="p" gutterBottom>
                    Soubory ke stažení budete moct přidat po vytvoření.
                </Typography>
            }
        </Form>
    )
}

export default HowToPayLessForm
