import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import 'dayjs/locale/cs'
import { Helmet } from 'react-helmet-async'

import CustomPaper from '@/components/custom-paper'
import { ROUTE_SEGMENTS, YEARS_FROM_START_TO_THIS } from '@/constants/general'
import SingleCollectedWasteStat from '@/components/collected-waste-stats/single'
import { getYearTab } from '@/helpers'

function CollectedWasteStatDetailView() {

    const { monthYear } = useParams()

    const returnTab = getYearTab(dayjs(monthYear).year(), YEARS_FROM_START_TO_THIS)

    const title = 'Detail sběru'
    
    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <CustomPaper>
                <Box pb={3} display="flex" alignItems="center" gap={1}>
                    <IconButton
                        aria-label="close"
                        size="small"
                        color="terciary"
                        component={Link}
                        to={`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipalityManagement}/${ROUTE_SEGMENTS.collectedWasteStats}?tab=${returnTab}`}
                    >
                        <Icon>arrow_back</Icon>
                    </IconButton>
                    <Typography variant="h3" component="h1">
                        {title}: {dayjs(monthYear).locale('cs').format('MMMM YYYY')}
                    </Typography>
                </Box>
                <SingleCollectedWasteStat />
            </CustomPaper>
        </>
    )
}

export default CollectedWasteStatDetailView
