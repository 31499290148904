import axios from 'axios'
import {
    useMutation,
    useQuery,
} from '@tanstack/react-query'
import { useAtom } from 'jotai'

import type {
    CategoryListExport,
    CollectionPointCategoryListFilters,
    CreateCategoryListExportResponse,
    MutationOptions,
} from '@/types'
import { selectedMunicipalityIdAtom } from '@/state'

export const useCreateCategoryListExport = (options: MutationOptions) => {

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    const {
        onSuccessCallback,
        setRequestInProgress,
    } = options

    return useMutation({
        mutationFn: (filters: CollectionPointCategoryListFilters) => {            
            const queryParams = new URLSearchParams()

            queryParams.append('municipality_id', municipalityId.toString())
            if (filters.mixedWeightCategories.length > 0) {
                queryParams.append('mixed_weight_category_level', `[${filters.mixedWeightCategories.join(',')}]`)
            }
            if (filters.mixedVolumeCategories.length > 0) {
                queryParams.append('mixed_volume_category_level', `[${filters.mixedVolumeCategories.join(',')}]`)
            }
            if (filters.types.length > 0) {
                queryParams.append('type', JSON.stringify(filters.types))
            }
            if (!Number.isNaN(Number.parseInt(filters.numberOfMembers))) {
                queryParams.append('number_of_members', filters.numberOfMembers)
            }
            
            return axios.post(`/api/category_list_export/create?${queryParams.toString()}`,
            )
        },
        onSuccess: response => {
            if (onSuccessCallback) {
                onSuccessCallback(response.data as CreateCategoryListExportResponse)
            }
        },
        onSettled: () => {
            setRequestInProgress(false)
        }
    })
}

export function useSingleCategoryListExport(exportId, municipalityId, keepRefetching) {
    return useQuery({
        queryKey: ['categoryListExport', exportId, municipalityId],
        queryFn: async () => {
            const { data } : { data: CategoryListExport } = await axios.get(
                `/api/category_list_export/single?municipality_id=${municipalityId
                    }&id=${exportId}`,
            )
            return data
        },
        refetchInterval: keepRefetching ? 2000 : undefined,
    })
}
