import { useState } from 'react'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded'
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded'

import type { Endpoints } from '@/types'
import { useDirectUpload } from '@/hooks/api/use-direct-upload'
import useFileUpload from '@/hooks/use-file-upload'

export default function FileUpload({
    endpoint,
    entityId,
    invalidateQueryKey,
}: {
    endpoint: Endpoints,
    entityId: number,
    invalidateQueryKey?: Array<string | number>,
}) {

    const {
        files: selectedFiles,
        setFiles,
        handleFileChange,
        getFileMetadata,
        getBase64,
    } = useFileUpload()

    const [loading, setLoading] = useState(false)

    const directUpload = useDirectUpload(endpoint, invalidateQueryKey)

    const handleFileUpload = async () => {
        setLoading(true)
        const files = Array.from(selectedFiles)
        for (const file of files) {
            try {
                const fileMetadata = await getFileMetadata(file)
                const base64File = await getBase64(file)
                const uploadedFile = await directUpload.mutateAsync({
                    documentable_id: entityId,
                    filename: fileMetadata.filename,
                    byte_size: fileMetadata.byteSize,
                    checksum: fileMetadata.checksum,
                    content_type: 'text/plain',
                    file_data: base64File as string,
                })
              } catch (error) {
                console.error(error)
              }
        }
        setLoading(false)
        setFiles([])
    }

    return (
        <Box>
            {loading &&
                <LinearProgress />
            }
            <Box mt={1} display="flex" justifyContent="center">
                <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<DescriptionRoundedIcon />}
                    component="label"
                >
                    Vybrat soubory
                    <input
                        type="file"
                        accept=".pdf, .docx"
                        hidden
                        multiple
                        onChange={handleFileChange}
                    />
                </Button>
            </Box>
            {(selectedFiles && selectedFiles.length !== 0) &&
                <Box mt={1} display="flex" justifyContent="center" flexDirection="column" textAlign="center">
                    <Typography variant="body1" component="div">
                        {'Vybrané soubory:'}
                    </Typography>
                    {Array.from(selectedFiles).map(file => (
                        <Typography variant="body1" component="div" key={file.name}>
                            {file.name}
                        </Typography>
                    ))}
                </Box>
            }
            <Box display="flex" justifyContent="center" mt={2}>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<FileUploadRoundedIcon />}
                    component="label"
                    onClick={handleFileUpload}
                    disabled={!selectedFiles || Boolean(loading)}
                >
                    Nahrát
                </Button>
            </Box>
        </Box>
    )
}
