import { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepConnector from '@mui/material/StepConnector'
// import LocalShippingIcon from '@mui/icons-material/LocalShipping'
// import RecyclingRoundedIcon from '@mui/icons-material/RecyclingRounded'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import { Link, useNavigate, useParams } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'

import CustomPaper from '@/components/custom-paper'
import MotivationModelParametersForm from '@/components/forms/motivation-model-collection'
import MotivationModelDiscountForm from '@/components/forms/motivation-model-discount'
// import HeadingWithIcon from '@/components/heading-with-icon'
// import TableRefuse from '@/components/motivation-model/table-refuse'
// import TableCollectionYards from '@/components/motivation-model/table-collection-yards'
import ModelSelector from '@/components/motivation-model/model-selector'
import { ROUTE_SEGMENTS, YEARS_FROM_START_TO_NEXT } from '@/constants/general'
import MotivationModelAccountingForm from '@/components/forms/motivation-model-accounting'
import { useMotivationModel } from '@/hooks/api/use-motivation-model'
import LoadingBox from '@/components/loading-box'
import ErrorBox from '@/components/error-box'
import MotivationModelNameForm from '@/components/forms/motivation-model-name'
import IncomeStatementWrapper from '@/components/motivation-model/income-statement-wrapper'
import ParametersWrapper from '@/wrappers/parameters-wrapper'
import CollectionYardsNotification from '@/components/motivation-model/collection-yards-notification'
import ActiveMotivationModelLoader from '@/wrappers/active-motivation-model-loader'
import { getYearTab } from '@/helpers'
import { Helmet } from 'react-helmet-async'

function MotivationModelDetailView() {

    const navigate = useNavigate()

    const { motivationModelId, stepNumber } = useParams()

    const [edit, setEdit] = useState<boolean>(false)

    const { status, data: motivationModel, error } = useMotivationModel(motivationModelId)

    const returnTab = getYearTab(motivationModel?.year, YEARS_FROM_START_TO_NEXT)
    const returnURL = `/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipalityManagement}/${ROUTE_SEGMENTS.motivationModel}?tab=${returnTab}`

    const title = 'Motivační model'
    
    if (status === 'pending') {
        return (
            <CustomPaper>
                <LoadingBox />
            </CustomPaper>
        )
    }

    if (error || !motivationModel) {
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <CustomPaper>
                    <Box pb={3} display="flex" alignItems="center" gap={1}>
                        <IconButton
                            aria-label="close"
                            size="small"
                            color="terciary"
                            component={Link}
                            to={returnURL}
                        >
                            <ArrowBackRoundedIcon />
                        </IconButton>
                        <Typography variant="h3" component="h1">
                            {title}: {motivationModelId}
                        </Typography>
                    </Box>
                    <ErrorBox
                        error={error}
                        message={`Nepovedlo se načíst motivační model ID ${motivationModelId}`}
                    />
                </CustomPaper>
            </>
        )
    }

    const activeStep: number = motivationModelId ? [0, 1, 2, 3, 4].includes(Number(stepNumber))
            ? Number(stepNumber)
            : 0
        :
        0

    const handleClickStep = (_event: React.MouseEvent<unknown>, step: number) => {
        navigate(
            `/${ROUTE_SEGMENTS.base
                }/${ROUTE_SEGMENTS.municipalityManagement
                }/${ROUTE_SEGMENTS.motivationModel
                }/${motivationModelId
                }/${ROUTE_SEGMENTS.step
                }/${step}`
        )
    }

    const steps = [
        'Svozy',
        'Hospodaření',
        'Slevy',
        'Poplatek',
        'Vyhláška',
    ]

    const stepsEnabled = {
        'Svozy': true,
        'Hospodaření': motivationModel.bin_dump_costs.length > 0,
        'Slevy': motivationModel.bin_dump_costs.length > 0,
        'Poplatek': motivationModel.bin_dump_costs.length > 0 &&
            motivationModel.mixed_volume_categories.length > 0 &&
            motivationModel.mixed_weight_categories.length > 0,
        'Vyhláška': !!motivationModel.selected_scenario,
    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <ParametersWrapper motivationModel={motivationModel}>
                {({parameters, setParameters}) => (     
                    <CustomPaper>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: {
                                xs: 'column',
                                lg: 'row',
                            }
                        }}>
                            <Box sx={{
                                flexBasis: {
                                    xs: '100%',
                                    lg: '60%',
                                },
                                pr: {
                                    xs: 0,
                                    lg: 3,
                                },
                            }}>
                                <Box pb={3} display="flex" alignItems="center" gap={1}>
                                    <IconButton
                                        aria-label="close"
                                        size="small"
                                        color="terciary"
                                        component={Link}
                                        to={returnURL}
                                    >
                                        <ArrowBackRoundedIcon />
                                    </IconButton>
                                    <Typography variant="h3" component="h1">
                                        {title}: {motivationModel.name}
                                        <Box component="span" color="customGrey.secondaryText"> ({motivationModel.year})</Box>
                                    </Typography>
                                    <IconButton
                                        aria-label="edit"
                                        size="small"
                                        onClick={() => setEdit(!edit)}
                                    >
                                        <EditRoundedIcon />
                                    </IconButton>
                                </Box>
                                {edit &&
                                    <Container maxWidth="sm" sx={{ mb: 3 }}>
                                        <MotivationModelNameForm
                                            motivationModel={motivationModel}
                                            closeCallback={() => setEdit(false)}
                                        />
                                    </Container>
                                }
                                <Stepper activeStep={activeStep} alternativeLabel connector={<StepConnector sx={{ top: '18px' }}/>}>
                                    {steps.map((label, index) => (
                                        <Step key={label}>
                                            <StepLabel
                                                onClick={stepsEnabled[label] ? (e) => handleClickStep(e, index) : null}
                                                sx={(theme) => ({
                                                    ...stepsEnabled[label] ?
                                                        {'&:hover': {
                                                            backgroundColor: 'rgba(0,0,0,0.03)',
                                                            cursor: 'pointer',
                                                            borderRadius: 2.5,
                                                        }}
                                                        : null,
                                                    paddingY: 1,
                                                    [theme.breakpoints.down('sm')]: {
                                                        '& .MuiStepLabel-label': {
                                                            maxWidth: 50,
                                                            textOverflow: 'ellipsis',
                                                            overflow: 'hidden',
                                                        },
                                                    },
                                                })}
                                            >
                                                {label}
                                            </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                                <Box pt={3} />
                                {activeStep === 0 &&
                                    <Container maxWidth="sm" sx={{ px: 0 }}>
                                        <MotivationModelParametersForm motivationModel={motivationModel} />
                                    </Container>
                                }
                                {activeStep === 1 &&
                                    <Container maxWidth="sm" sx={{ px: 0 }}>
                                        <MotivationModelAccountingForm motivationModel={motivationModel} />
                                    </Container>
                                }
                                {activeStep === 2 &&
                                    <MotivationModelDiscountForm motivationModel={motivationModel} />
                                }
                                {activeStep === 3 &&
                                    <Box sx={{
                                        minHeight: {
                                            xs: 830,
                                            lg: 'auto',
                                        }
                                    }}>
                                        <ModelSelector
                                            motivationModel={motivationModel}
                                            parameters={parameters}
                                            setParameters={setParameters}
                                        />
                                    </Box>
                                }
                                {activeStep === 4 &&
                                    <Box sx={{
                                        color: 'customGrey.secondaryText',
                                        p: 3,
                                        textAlign: 'center',
                                    }}>
                                        Tento krok bude pravděpodobně obsahovat vzory vyhlášky ke stažení.
                                    </Box>
                                }
                                <Box display="flex" justifyContent="space-between" mt={3} mb={5}>
                                    <Button
                                        variant="text"
                                        color="terciary"
                                        startIcon={<ChevronLeftRoundedIcon/>}
                                        disabled={activeStep < 1}
                                        component={Link}
                                        to={`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipalityManagement}/${ROUTE_SEGMENTS.motivationModel}/${motivationModelId}/${ROUTE_SEGMENTS.step}/${activeStep-1}`}
                                        sx={{ visibility: activeStep < 1 ? 'hidden' : 'unset'}}
                                    >
                                        Zpět
                                    </Button>
                                    <Button
                                        variant="text"
                                        color="terciary"
                                        endIcon={<ChevronRightRoundedIcon/>}
                                        disabled={
                                            (activeStep === 0 || activeStep === 1) &&
                                            motivationModel.bin_dump_costs.length === 0 ||
                                            (activeStep === 2 &&
                                                motivationModel.mixed_weight_categories.length === 0 &&
                                                motivationModel.mixed_volume_categories.length === 0
                                            )
                                        }
                                        component={Link}
                                        to={`/${ROUTE_SEGMENTS.base}/${ROUTE_SEGMENTS.municipalityManagement}/${ROUTE_SEGMENTS.motivationModel}/${motivationModelId}/${ROUTE_SEGMENTS.step}/${activeStep+1}`}
                                        sx={{ visibility: activeStep > 3 ? 'hidden' : 'unset'}}
                                    >
                                        Další
                                    </Button>
                                </Box>
                            </Box>
                            <Box sx={(theme) => ({
                                flexBasis: {
                                    xs: '100%',
                                    lg: '40%',
                                },
                                pl: {
                                    xs: 0,
                                    lg: 3,
                                },
                                pt: {
                                    xs: 3,
                                    lg: 0,
                                },
                                borderLeft: {
                                    xs: '0px',
                                    lg: `1px solid ${theme.palette.customGrey.divider}`,
                                },
                                borderTop: {
                                    xs: `1px solid ${theme.palette.customGrey.divider}`,
                                    lg: '0px',
                                },
                                overflowX: 'auto',
                            })}>
                                <CollectionYardsNotification />
                                {motivationModel.selected_scenario &&
                                    <ActiveMotivationModelLoader year={motivationModel.year-1}>
                                        {previousYearMotivationModel => (
                                            <ActiveMotivationModelLoader year={motivationModel.year-2}>
                                                {twoYearsAgoMotivationModel => (
                                                    <IncomeStatementWrapper
                                                        motivationModel={motivationModel}
                                                        previousYearMotivationModel={previousYearMotivationModel}
                                                        twoYearsAgoMotivationModel={twoYearsAgoMotivationModel}
                                                        parameters={parameters}
                                                    />
                                                )}
                                            </ActiveMotivationModelLoader>
                                        )}
                                    </ActiveMotivationModelLoader>
                                    
                                }
                                {/* <HeadingWithIcon
                                    text="Statistika výsypů Vaší obce za předchozí(ch)"
                                    icon={<LocalShippingIcon />}
                                />
                                <Box sx={{ overflowX: 'auto' }}>
                                    <TableRefuse />
                                </Box>
                                <Box pt={6} />
                                <HeadingWithIcon
                                    text="Statistika sběr. dvorů a ostatního sběru za předchozí(ch)"
                                    icon={<RecyclingRoundedIcon />}
                                />
                                <Box sx={{ overflowX: 'auto' }}>
                                    <TableCollectionYards />
                                </Box> */}
                            </Box>
                        </Box>
                    </CustomPaper>
                )}
            </ParametersWrapper>
        </>
    )
}

export default MotivationModelDetailView
