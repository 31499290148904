import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import EmailIcon from '@mui/icons-material/Email'
import dayjs from 'dayjs'

import type { CommunicationThread } from '@/types'
import { Typography } from '@mui/material'
import CommunicationThreadStatus from './thread-status'
import CommunicationThreadDetail from './thread-detail'

export default function CommunicationThreadAccordion({
    communicationThread,
}: {
    communicationThread: CommunicationThread,
}) {
    return (
        <Accordion
            elevation={0}
            sx={{
                border: '1px solid #DEDEDE',
                borderRadius: '10px',
                '&:first-of-type, :last-of-type': {
                    borderRadius: '10px',
                },
                '&:before': {
                    display: 'none', // remove divider
                },
                '&.Mui-expanded': {
                    my: 1, // make expansion smaller
                },
            }}
            slotProps={{ transition: { unmountOnExit: true } }}
        >
            <AccordionSummary
                expandIcon={<KeyboardArrowDownRoundedIcon />}
                aria-controls={`communication-thread-${communicationThread.id}-content`}
                id={`communication-thread-${communicationThread.id}-header`}
            >
                <Box sx={{
                    flexGrow: 1,
                    display: 'flex',
                    gap: 1,
                    pr: 2,
                    flexWrap: {
                        xs: 'wrap',
                        sm: 'nowrap',
                        lg: 'wrap',
                        xl: 'nowrap',
                    }
                }}>
                    <Box sx={{
                        display: 'flex',
                        pr: 1,
                    }}>
                        <EmailIcon sx={{ color: '#BCBCBC', alignSelf: 'center' }} />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 0.5,
                        flexGrow: 1,
                        flexBasis: {
                            xs: '100%',
                            sm: 'auto',
                            lg: '100%',
                            xl: 'auto',
                        },
                        order: {
                            xs: 3,
                            sm: 0,
                            lg: 3,
                            xl: 0,
                        }
                    }}>
                        <Typography variant="h5" component="h5">
                            {communicationThread.communication_type.name}
                        </Typography>
                        <Typography variant="body1" component="div" sx={{ color: 'customGrey.tableText' }}>
                            Odesláno: {dayjs(communicationThread.created_at).format('D. M. YYYY HH:mm')}
                        </Typography>
                    </Box>
                    <CommunicationThreadStatus communicationThread={communicationThread} />
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <CommunicationThreadDetail communicationId={communicationThread.id} />
            </AccordionDetails>
        </Accordion>
    )
}
