import axios from 'axios'
import {
    useQuery,
} from '@tanstack/react-query'
import type {
    CollectionPointDetail,
    CollectionPointListItem,
    ISODateString,
    CollectionPointWeightingsRespose,
} from '@/types'

export function useCollectionPoints(
    municipalityId: number,
) {
    return useQuery({
        queryKey: ['collectionPoints', municipalityId],
        queryFn: async () => {
            const { data } : { data: Array<CollectionPointListItem> } = await axios.get(
                `/api/eoko/household/list?municipality_id=${municipalityId}`,
            )
            return data
        },
        enabled: !!municipalityId,
    })
}

export function useCollectionPoint(collectionPointId) {
    return useQuery({
        queryKey: ['collectionPoint', collectionPointId],
        queryFn: async () => {
            const { data } : { data: CollectionPointDetail } = await axios.get(
                '/api/eoko/household/single?id=' + collectionPointId,
            )
            return data
        },
    })
}

export function useCollectionPointWeightings(
    collectionPointId: number,
    beginsAt: ISODateString,
    endsAt: ISODateString,
) {
    return useQuery({
        queryKey: ['collectionPointWeightings', collectionPointId, 'beginsAt-'+beginsAt, 'endsAt-'+endsAt],
        queryFn: async () => {
            const { data } : { data: CollectionPointWeightingsRespose } = await axios.get(
                `/api/eoko/household/bin_weightings?id=${collectionPointId
                    }&begins_at=${beginsAt
                    }&ends_at=${endsAt}`,
            )
            return data
        },
    })
}
