import { useAtom } from 'jotai'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useNavigate } from "react-router-dom"
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import IconButton from '@mui/material/IconButton'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import dayjs from 'dayjs'
import 'dayjs/locale/cs'

import { useCollectedWasteStats } from '@/hooks/api/use-collected-waste-stat'
import { selectedMunicipalityIdAtom } from "@/state"
import LoadingBox from "@/components/loading-box"
import ErrorBox from "@/components/error-box"
import { CollectedWasteRecordType, type MonthYear } from '@/types'
import { useMunicipalityStats } from '@/hooks/api/use-municipality'
import { calculateMonthlyAndTotalMunicipalityStats } from '@/helpers'
import { NumericFormat } from 'react-number-format'

type MonthMap = {
    [month: number]: {
        monthYear: MonthYear,
        year: number,
        month: number,
        collection: number | null,
        collectionYards: number | null,
        other: number | null,
    }
}

const generateMonthMap = (year: number): MonthMap => {
    const monthMap: MonthMap = {}
    // Iterate over each month (1 to 12)
    for (let month = 1; month <= 12; month++) {
        // Initialize the month object
        monthMap[month] = {
            monthYear: `${year}-${String(month).padStart(2, '0')}` as MonthYear,
            year,
            month,
            collection: null,
            collectionYards: null,
            other: null,
        }
    }
    return monthMap
}

function CollectedWasteStatList({
    year,
}: {
    year: number,
}) {

    const [municipalityId] = useAtom(selectedMunicipalityIdAtom)

    const navigate = useNavigate()

    const handleClick = (_event: React.MouseEvent<unknown>, monthYear: MonthYear) => {
        navigate(`${monthYear}`) 
    }

    
    const { status, data, error } = useCollectedWasteStats(
        municipalityId,
        year+'-01' as MonthYear,
        year+'-12' as MonthYear,
    )

    const {
        status: collectionStatus,
        data: collectionData,
        error: collectionError,
    } = useMunicipalityStats(
        municipalityId,
        year+'-01' as MonthYear,
        year+'-12' as MonthYear,
    )

    if (status === 'pending' || collectionStatus === 'pending') {
        return (
            <LoadingBox />
        )
    }

    if (error || collectionError) {
        return <ErrorBox
            error={error || collectionError}
            message={`Nepovedlo se načíst údaje o produkci odpadu pro obec s ID ${municipalityId}`}
        />
    }

    const monthMap = generateMonthMap(year)

    for (const entry of data) {
        const date = dayjs(entry.year_month)
        const month = date.month()+1

        const isCollectionYard = entry.record_type === CollectedWasteRecordType.collectionYard

        const sumOfWeights = Object.keys(entry)
            .filter(key => key.endsWith('_weight'))
            .reduce((sum, key) => sum + entry[key], 0)
        
        if (isCollectionYard) {
            monthMap[month].collectionYards = sumOfWeights
        } else {
            monthMap[month].other = sumOfWeights
        }
    }

    const { monthlyData: monthlyCollectionData } = calculateMonthlyAndTotalMunicipalityStats(collectionData)

    for (const entry of monthlyCollectionData) {
        const month = entry.month
        monthMap[month].collection = entry.all_weight
    }

    const currentYear = dayjs().year()
    const currentMonth = dayjs().month() + 1

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'auto'
        }}>
            <Table
                aria-label="Roční statistika produkce odpadu"
                sx={{
                    mt: 1,
                    minWidth: 600,
                }}
                size='small'
            >
                <TableHead>
                    <TableRow>
                        <TableCell>Měsíc</TableCell>
                        <TableCell>Svozy</TableCell>
                        <TableCell>Sběrné dvory</TableCell>
                        <TableCell>Ostatní</TableCell>
                        <TableCell>Celkem</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.values(monthMap).map(month => (
                        <TableRow
                            key={month.monthYear}
                            sx={{
                                cursor: 'pointer',
                                '&:last-child td, &:last-child th': { border: 0 },
                            }}
                            hover
                            onClick={(event) => handleClick(event, month.monthYear)}
                        >
                            <TableCell component="th" scope="row">
                                {dayjs(month.monthYear).locale('cs').format('MMMM YYYY')}
                            </TableCell>
                            <TableCell>
                                {month.collection != null ? 
                                    <Box>
                                        <NumericFormat
                                            displayType="text"
                                            value={month.collection}
                                            thousandSeparator={'\xa0'}
                                        />
                                        &nbsp;kg
                                    </Box> :
                                    <Box sx={{
                                        display: 'flex',
                                        gap: 1,
                                        alignItems: 'center',
                                    }}>
                                        <ErrorOutlineRoundedIcon />
                                        Nejsou data
                                    </Box>
                                }
                            </TableCell>
                            <TableCell>
                                {month.collectionYards != null ? 
                                    <Box>
                                        <NumericFormat
                                            displayType="text"
                                            value={month.collectionYards}
                                            thousandSeparator={'\xa0'}
                                        />
                                        &nbsp;kg
                                    </Box> :
                                    <Box sx={{
                                        display: 'flex',
                                        gap: 1,
                                        alignItems: 'center',
                                        color: (month.year < currentYear || (month.year === currentYear && month.month < currentMonth)) ? 'error.main' : 'inherit',
                                    }}>
                                        <ErrorOutlineRoundedIcon />
                                        Nevyplněno
                                    </Box>
                                }
                            </TableCell>
                            <TableCell>
                                {month.other != null ? 
                                    <Box>
                                        <NumericFormat
                                            displayType="text"
                                            value={month.other}
                                            thousandSeparator={'\xa0'}
                                        />
                                        &nbsp;kg
                                    </Box> :
                                    <Box sx={{
                                        display: 'flex',
                                        gap: 1,
                                        alignItems: 'center',
                                        color: (month.year < currentYear || (month.year === currentYear && month.month < currentMonth)) ? 'error.main' : 'inherit',
                                    }}>
                                        <ErrorOutlineRoundedIcon />
                                        Nevyplněno
                                    </Box>
                                }
                            </TableCell>
                            <TableCell>
                                <NumericFormat
                                    displayType="text"
                                    value={(month.collection ?? 0)+(month.collectionYards ?? 0)+(month.other ?? 0)}
                                    thousandSeparator={'\xa0'}
                                />
                                &nbsp;kg
                            </TableCell>
                            <TableCell>
                                <Tooltip title="Detail a úpravy">
                                    <IconButton>
                                        <VisibilityRoundedIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    )
}

export default CollectedWasteStatList
